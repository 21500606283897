import React, { useEffect, useCallback } from "react";
import { Card, Form, Row, Col, Button, Input, Spin, Skeleton } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { selectors as NODE_SELECTORS } from "../../../../ducks/node";
import { actions as LOADING_ACTIONS } from "../../../../ducks/loadingData";
import { selectors as CLOUD_CONNECTION_SELECTORS } from "../../../../ducks/cloudConnection";

import WebsocketService from "../../../../services/websocket";
import CloudService from "../../../../services/cloud";

import { required, az09space, max32characters } from "../../../../lib/utils/formValidation";
import { successNotification, errorNotification } from "../../../../lib/utils/notification";
import { LOADING_DATA_TYPE, WEBSOCKET_STATUS } from "../../../../lib/utils/constants";

import store from "../../../../store";

const { Item } = Form;

const EditNodeName = ({ cnn, cwid, connectionStatus, websocketConnection, fingerprint }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  useEffect(() => {
    setFieldsValue({ hostname: cnn });
  }, [cnn, setFieldsValue]);
  useEffect(() => {
    const isConnection = websocketConnection && connectionStatus === WEBSOCKET_STATUS.connected;

    if (isConnection) {
      WebsocketService.sendMessage(JSON.stringify({ cloudMsgType: "sendMessage", command: "getHostname", to: cwid }));
      WebsocketService.sendMessage(
        JSON.stringify({ cloudMsgType: "sendMessage", command: "getFingerprint", to: cwid })
      );
      store.dispatch(LOADING_ACTIONS.SET_LOADING(LOADING_DATA_TYPE.getHostname));
    }
  }, [cwid, connectionStatus, websocketConnection]);

  const handleFinish = useCallback(
    ({ hostname }) => {
      CloudService.editNodeHostname(
        { cnn: hostname, fingerprint, cwid },
        {
          errorNotification: errorNotification(formatMessage),
          successNotification: successNotification(formatMessage),
        }
      );
    },
    [fingerprint, formatMessage, cwid]
  );

  if (!cnn) {
    return (
      <Spin tip={<FormattedMessage id="EditNodeName.loadingCnn" defaultMessage="Loading node hostname" />}>
        <Skeleton active />
        <Form form={form} />
      </Spin>
    );
  }

  return (
    <Card title={<FormattedMessage id="EditNodeName.title" defaultMessage="Edit node hostname" />}>
      <Form onFinish={handleFinish} form={form} initialValues={{ hostname: cnn }}>
        <Row gutter={24}>
          <Col span={12}>
            <Item
              name="hostname"
              label={<FormattedMessage id="EditNodeName.editNodeName" defaultMessage="Node hostname" />}
              rules={[required, az09space, max32characters]}
            >
              <Input />
            </Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="global.save" defaultMessage="Save" />
        </Button>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  cnn: NODE_SELECTORS.getCnn(state),
  connectionStatus: CLOUD_CONNECTION_SELECTORS.getStatus(state),
  cwid: NODE_SELECTORS.getNodeCwid(state),
  fingerprint: NODE_SELECTORS.getFingerprint(state),
  websocketConnection: CLOUD_CONNECTION_SELECTORS.getWebsocketConnection(state),
});

EditNodeName.propTypes = {
  cnn: PropTypes.string,
  connectionStatus: PropTypes.string,
  cwid: PropTypes.string,
  fingerprint: PropTypes.string,
  websocketConnection: PropTypes.bool,
};

EditNodeName.defaultProps = {
  cnn: null,
  connectionStatus: null,
  cwid: null,
  fingerprint: null,
  websocketConnection: null,
};

export default connect(mapStateToProps, null)(EditNodeName);
