import React, { useEffect } from "react";
import { Layout } from "antd";
import { useIntl } from "react-intl";
import styled from "styled-components";

import { AuthAPI } from "../../lib/api";
import { errorNotification } from "../../lib/utils/notification";

import Routes from "../../routes";

const { Content } = Layout;

const AppLayout = () => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    const token = window.localStorage.getItem("authToken");

    if (token) {
      AuthAPI.rotateToken({ errorNotification: errorNotification(formatMessage) });
    }
  }, [formatMessage]);

  return (
    <FullHeightLayout>
      <StyledContent>
        <Routes />
      </StyledContent>
    </FullHeightLayout>
  );
};

const FullHeightLayout = styled(Layout)`
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled(Content)`
  display: flex;
`;

export default AppLayout;
