import React from "react";
import { PageHeader } from "antd";
import { withRouter } from "react-router-dom";
import { history } from "../../store";

const PageHeaderContainer = () => {
  const title = " ";

  const goBack = () => {
    history.goBack();
  };

  return title && <PageHeader onBack={goBack} title={title} />;
};

export default withRouter(PageHeaderContainer);
