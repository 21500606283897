import React from "react";
import { Select } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

const { Option } = Select;

const SelectEngineOption = ({ channels, channelId, channelIds, handleSelectChange }) => {
  const noData = channelIds.length === 0;

  const selectChannelName = (id) => {
    switch (id) {
      case "-1":
        return <FormattedMessage id="Logs.all" defaultMessage="All" />;
      case "0":
        return <FormattedMessage id="Logs.node" defaultMessage="Node" />;
      default:
        return channels.get(`${id}`).config?.name;
    }
  };

  return (
    <Select
      style={{ width: 300 }}
      onChange={handleSelectChange}
      value={(noData && "No Data") || channelId}
      dropdownStyle={{ minWidth: "30%" }}
      disabled={noData}
    >
      {channelIds.map((id) => {
        return (
          <Option value={id} key={id}>
            {selectChannelName(id)}
          </Option>
        );
      })}
    </Select>
  );
};

SelectEngineOption.propTypes = {
  channels: ImmutablePropTypes.map.isRequired,
  channelId: PropTypes.string.isRequired,
  channelIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectChange: PropTypes.func.isRequired,
};

export default SelectEngineOption;
