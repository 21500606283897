import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Divider, Switch, Popconfirm, Tooltip } from "antd";
import * as PropTypes from "prop-types";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { QuestionCircleFilled } from "@ant-design/icons";

import AccountService from "../../services/account";
import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";

import EditPasswordForm from "../../components/EditPasswordForm";
// import AccountEditForm from "../../components/AccountEditForm";
import DeleteAccount from "../../components/DeleteAccount";

import globalTranslation from "../../lib/translations";
import { errorNotification, successNotification } from "../../lib/utils/notification";
import themeColor from "../../lib/style/theme";

const translations = defineMessages({
  profileData: {
    id: "account.profileData",
    defaultMessage: "Profile data",
  },
  editPassword: {
    id: "account.editPassword",
    defaultMessage: "Edit password",
  },
  deleteAccount: {
    id: "account.deleteAccount",
    defaultMessage: "Delete account",
  },
});

const Account = ({ username, emailOptIn, email }) => {
  const { formatMessage } = useIntl();

  const [emailOptInState, setEmailOptInState] = useState(emailOptIn);

  // const [editMode, setEditMode] = useState(false);
  // const editAccount = () => {
  //   setEditMode(!editMode);
  // };

  const onConfirmChangeEmailOptIn = async () => {
    const optInChangeSuccess = await AccountService.editEmailOptIn(
      { emailOptIn: !emailOptInState },
      {
        errorNotification: errorNotification(formatMessage),
        successNotification: successNotification(formatMessage),
      }
    );
    if (optInChangeSuccess) {
      setEmailOptInState(!emailOptInState);
    }
  };

  return (
    <>
      <Row gutter={16}>
        <StyledCol md={24} lg={12}>
          <Card title={formatMessage(translations.profileData)} bordered>
            <Row>
              <Col span={24}>
                <StyledBold>
                  <FormattedMessage id="account.username" defaultMessage="Username" />:
                </StyledBold>{" "}
                {username}
              </Col>
              <Divider />
              <Col span={24}>
                <StyledBold>
                  <FormattedMessage id="account.emial" defaultMessage="Email" />:
                </StyledBold>{" "}
                {email}
              </Col>
              <Divider />
              <StyledCenterCol span={24}>
                <StyledBold>
                  <FormattedMessage id="account.emailOptIn" defaultMessage="Email opt-in" />:{" "}
                </StyledBold>
                <Popconfirm
                  placement="top"
                  title={
                    <FormattedMessage
                      id="account.emailOptInChange"
                      defaultMessage="Are you sure you want to change email opt-in status?"
                    />
                  }
                  onConfirm={onConfirmChangeEmailOptIn}
                  okText={formatMessage(globalTranslation.yes)}
                  cancelText={formatMessage(globalTranslation.no)}
                >
                  <Switch
                    checkedChildren={<FormattedMessage id="general.on" defaultMessage="On" />}
                    unCheckedChildren={<FormattedMessage id="general.off" defaultMessage="Off" />}
                    checked={emailOptInState}
                  />{" "}
                </Popconfirm>
                <StyledTooltip
                  placement="top"
                  title={
                    <>
                      <FormattedMessage
                        id="account.emailOptInDescription"
                        defaultMessage="We will send you only important information related to Quickstream. We promise to respect your inbox and send"
                      />
                      <StyledText>
                        <FormattedMessage id="EmailOptIn.max1Email" defaultMessage="maximum 1 email per month" />
                      </StyledText>
                      .
                    </>
                  }
                >
                  <QuestionCircleFilled style={{ fontSize: "18px" }} />
                </StyledTooltip>
              </StyledCenterCol>
            </Row>
            {/* {!editMode && (
              <>
                <b>
                  <FormattedMessage id="account.username" defaultMessage="Username" />:
                </b>{" "}
                {username}
              </>
            )}
            {editMode && <AccountEditForm username={username} />} */}
          </Card>
        </StyledCol>
        <StyledCol md={24} lg={12}>
          <Card title={formatMessage(translations.editPassword)} bordered>
            <EditPasswordForm />
          </Card>
        </StyledCol>
        <StyledCol md={24} lg={12}>
          <Card title={formatMessage(translations.deleteAccount)} bordered>
            <DeleteAccount />
          </Card>
        </StyledCol>
      </Row>
    </>
  );
};

const StyledTooltip = styled(Tooltip)`
  margin: 0 5px;
`;

const StyledBold = styled.b`
  min-width: 100px;
  display: inline-block;
`;

const StyledCenterCol = styled(Col)`
  margin: 5px 0px;
  display: flex;
  align-items: center;
`;

const StyledCol = styled(Col)`
  margin: 0px 0px 10px 0px;
`;

const StyledText = styled.span`
  margin: 0 0 0 5px;
  color: ${themeColor.orange};
`;

const mapStateToProps = (state) => ({
  username: ACCOUNT_SELECTORS.getUser(state),
  emailOptIn: ACCOUNT_SELECTORS.getEmailOptIn(state),
  email: ACCOUNT_SELECTORS.getUserEmail(state),
});

Account.propTypes = {
  username: PropTypes.string.isRequired,
  emailOptIn: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(Account);
