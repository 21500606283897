import React, { useState, useEffect } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Row, Col, Card, Collapse, Divider, Form, Switch } from "antd";
import styled from "styled-components";

import InputStreamUdpSection from "./InputStreamUdpSection";
import InputStreamSrtSection from "./InputStreamSrtSection";
import InputStreamQSSection from "./InputStreamQSSection";
import InputStreamSectionBackup from "../InputStreamSectionBackup";
import { CONNECTION_METHOD, QUICKSTREAM_METHOD, DEMO_CONNECTION_METHOD } from "../../../lib/utils/constants";
import ConnectionMethod from "../../../components/Fields/ConnectionMethod";
import CloudChannelList from "../../../components/Fields/CloudChannelList";
import InputTextFiled from "../../../components/Fields/InputTextField";
import DemoConnectionMethod from "../../../components/DemoConnectionMethod";

import themeColor from "../../../lib/style/theme";
import globalTranslations from "../../../lib/translations";

const { Panel } = Collapse;
const { Item } = Form;

const SRT_METHODS = [
  CONNECTION_METHOD.srtCaller.value,
  CONNECTION_METHOD.srtRendezvous.value,
  CONNECTION_METHOD.srtListener.value,
];
const UDP_METHODS = [CONNECTION_METHOD.udpMulticast.value, CONNECTION_METHOD.udpUnicast.value];

const translations = defineMessages({
  cloudIdLabel: {
    id: "InputStreamSection.cloudIdLabel",
    defaultMessage: "Cloud ID",
  },
  backupInput: {
    id: "InputStreamSection.backupInput",
    defaultMessage: "Backup Input",
  },
});

const InputStreamSection = ({
  channelList,
  getFieldValue,
  initialValue,
  loggedToCloud,
  setFieldsValue,
  setDisableCondition,
}) => {
  const [connectionMethod, setConnectionMethod] = useState(
    initialValue?.connectionType || CONNECTION_METHOD.udpUnicast.value
  );
  const [switchInputBackupState, setSwitchInputBackupState] = useState(initialValue?.switchInputBackup);

  const DEMO_CONNECTION_METHOD_VALUE_ARRAY = DEMO_CONNECTION_METHOD.map((method) => method.value);
  const isUDPMethod = UDP_METHODS.includes(connectionMethod);
  const isSRTMethod = SRT_METHODS.includes(connectionMethod);
  const isQuickstreamMethod = connectionMethod === QUICKSTREAM_METHOD.value;
  const isDemoConnectionMethod = DEMO_CONNECTION_METHOD_VALUE_ARRAY.includes(connectionMethod);
  const selectedConnectionMethodLabel = DEMO_CONNECTION_METHOD.find((method) => method.value === connectionMethod);
  const freeChannelList = channelList.filter((channel) => !channel.cwid);

  const { formatMessage } = useIntl();

  const handleChangeBackup = (value) => {
    setSwitchInputBackupState(value);
  };

  useEffect(() => {
    setDisableCondition(isDemoConnectionMethod);
  }, [setDisableCondition, isDemoConnectionMethod]);

  return (
    <Card title={<FormattedMessage id="InputStreamSection.inputStream" defaultMessage="Input Stream" />}>
      <Row type="flex" justify="start" gutter={24}>
        <Col span={12}>
          <ConnectionMethod
            name={["input", "connectionType"]}
            initialValue={initialValue?.connectionType}
            disabled={!loggedToCloud && isQuickstreamMethod}
            loggedToCloud={loggedToCloud}
            setConnectionMethod={setConnectionMethod}
          />
        </Col>
        <StyledHiddenCol span={4}>
          <InputTextFiled name={["input", "type"]} />
        </StyledHiddenCol>
        <Divider />
        {isDemoConnectionMethod && <DemoConnectionMethod label={selectedConnectionMethodLabel.label} />}
        {isQuickstreamMethod && (
          <>
            <Col span={12}>
              <CloudChannelList
                channelList={freeChannelList}
                disabled={!loggedToCloud}
                getFieldValue={getFieldValue}
                initialValue={
                  initialValue?.cloudId && initialValue?.cloudName
                    ? {
                        cloudId: initialValue?.cloudId,
                        channelName: initialValue?.cloudName,
                        permissionId: initialValue?.permissionId,
                        description: initialValue?.description,
                      }
                    : null
                }
                name={["input"]}
                label={formatMessage(translations.cloudIdLabel)}
                sameAsValidation="output"
                setFieldsValue={setFieldsValue}
              />
            </Col>
            <StyledHiddenCol span={4}>
              <InputTextFiled name={["input", "cloudName"]} />
            </StyledHiddenCol>
            <StyledHiddenCol span={4}>
              <InputTextFiled name={["input", "permissionId"]} />
            </StyledHiddenCol>
            <StyledHiddenCol span={4}>
              <InputTextFiled name={["input", "description"]} />
            </StyledHiddenCol>
          </>
        )}
        {isUDPMethod && (
          <InputStreamUdpSection
            prefix={["input"]}
            getFieldValue={getFieldValue}
            connectionMethod={connectionMethod}
            initialValue={initialValue}
          />
        )}
        {isSRTMethod && (
          <InputStreamSrtSection prefix={["input"]} connectionMethod={connectionMethod} initialValue={initialValue} />
        )}
        {isQuickstreamMethod && (
          <StyledCollapse>
            <Panel
              header={<FormattedMessage id="general.advancedSettings" defaultMessage="Advanced settings" />}
              key="advancedSettings"
              forceRender
            >
              <InputStreamQSSection prefix={["input"]} initialValue={initialValue} setFieldsValue={setFieldsValue} />
            </Panel>
          </StyledCollapse>
        )}
      </Row>
      {!isQuickstreamMethod && !isDemoConnectionMethod && (
        <>
          <Item
            name={["input", "switchInputBackup"]}
            valuePropName="checked"
            label={formatMessage(translations.backupInput)}
          >
            <Switch
              defaultChecked={switchInputBackupState}
              checkedChildren={formatMessage(globalTranslations.enabled)}
              unCheckedChildren={formatMessage(globalTranslations.disabled)}
              onChange={handleChangeBackup}
            />
          </Item>
          {switchInputBackupState && (
            <InputStreamSectionBackup initialValue={initialValue?.backup} getFieldValue={getFieldValue} />
          )}
        </>
      )}
    </Card>
  );
};

const StyledHiddenCol = styled(Col)`
  visibility: hidden;
  display: none;
`;

const StyledCollapse = styled(Collapse)`
  background-color: ${themeColor.orange} !important;
  width: 100%;

  .ant-collapse-header {
    color: #fff !important;
  }
`;

InputStreamSection.propTypes = {
  channelList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cloudId: PropTypes.number.isRequired,
        channelName: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        isEncrypted: PropTypes.bool.isRequired,
        isPublic: PropTypes.bool.isRequired,
        userId: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
  initialValue: PropTypes.shape({
    backup: PropTypes.shape({
      backupConnection: PropTypes.object.isRequired,
      triggerBitrateEnabled: PropTypes.bool.isRequired,
      triggerBitrateLevel: PropTypes.number,
      triggerBitrateTimeSecs: PropTypes.number,
      triggerDisconnectEnabled: PropTypes.bool.isRequired,
    }),
    connectionType: PropTypes.string,
    cloudId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    permissionId: PropTypes.number,
    description: PropTypes.string,
    cloudName: PropTypes.string,
    connectionMethod: PropTypes.string,
    dstIp: PropTypes.string,
    dstPort: PropTypes.number,
    ffmpegStreamProperties: PropTypes.shape({
      connectTimeout: PropTypes.number,
      mode: PropTypes.string,
      oheadbw: PropTypes.number,
      passphrase: PropTypes.string,
      rcvlatency: PropTypes.number,
    }),
    interfaceIp: PropTypes.string,
    switchInputBackup: PropTypes.bool,
    type: PropTypes.string,
  }),
  getFieldValue: PropTypes.func.isRequired,
  loggedToCloud: PropTypes.bool.isRequired,
  setDisableCondition: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
};

InputStreamSection.defaultProps = {
  initialValue: null,
};

export default InputStreamSection;
