import React, { useState, useCallback, useEffect } from "react";
import { useMountEffect } from "@reonomy/reactive-hooks";
import { DeleteOutlined, QuestionCircleOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { Card, Table, Row, Col, Popconfirm, Button, Form, Empty, Affix, Tooltip } from "antd";
import { connect } from "react-redux";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import CloudChannelsServices from "../../services/cloudChannels";
import AccountServices from "../../services/account";

import { errorNotification, successNotification } from "../../lib/utils/notification";

import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../ducks/cloudChannels";
import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";

import PermissionDynamicForm from "./PermissionDynamicForm";
import PermissionInvitationDynamicForm from "./PermissionInvitationDynamicForm";
import ChannelDetailViewStatus from "./ChannelDetailViewStatus";
import ChannelDetailViewCardTitle from "./ChannelDetailViewCardTitle";

import { CLOUD_CHANNEL_TYPE } from "../../lib/utils/constants";
import themeColor from "../../lib/style/theme";

const translations = defineMessages({
  channel: {
    id: "ChannelDetailView.channel",
    defaultMessage: "Channel:",
  },
  actions: {
    id: "general.actions",
    defaultMessage: "Actions",
  },
  shareRequired: {
    id: "ChannelDetailView.shareRequired",
    defaultMessage: "Field required to share channel",
  },
});

const ChannelDetailView = ({
  allChannelsList,
  match: {
    params: { id, type },
  },
  ownChannelsList,
  username,
}) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { resetFields, setFieldsValue, getFieldValue } = form;
  const [loading, setLoading] = useState(false);
  const [loadingInvitations, setLoadingInvitations] = useState(false);

  useEffect(() => {
    if (type === CLOUD_CHANNEL_TYPE.all && allChannelsList.length === 0) {
      CloudChannelsServices.getAllChannels({ errorNotification: errorNotification(formatMessage) });
    }

    if (type === CLOUD_CHANNEL_TYPE.own && ownChannelsList.length === 0) {
      CloudChannelsServices.getOwnChannels({ errorNotification: errorNotification(formatMessage) });
    }
  }, [type, allChannelsList.length, ownChannelsList.length, formatMessage]);

  const [permissions, setPermissions] = useState([]);

  const fetchChannels = useCallback(async () => {
    const channelPermissions = await CloudChannelsServices.getChannelPermissions(id, {
      errorNotification: errorNotification(formatMessage),
    });

    setPermissions(channelPermissions);
  }, [formatMessage, id]);

  const removePermission = async (permissionId) => {
    setLoading(true);
    const successResponse = await CloudChannelsServices.removeChannelPermission(permissionId, {
      errorNotification: errorNotification(formatMessage),
      successNotification: successNotification(formatMessage),
    });
    if (successResponse) {
      const updatedPermissions = permissions.filter((permission) => permission.permissionId !== permissionId);
      setPermissions(updatedPermissions);
    }

    setLoading(false);
  };

  const cloudChannels = allChannelsList.length > 0 ? allChannelsList : ownChannelsList;

  const channelData = cloudChannels && cloudChannels.find((channel) => channel.id === +id);

  const isChannelOwner = channelData && channelData.userId === username;

  const handleFinish = useCallback(
    async ({ users, invitations }) => {
      if (users && users.length > 0) {
        setLoading(true);
        await CloudChannelsServices.addChannelPermission(
          { users, cloudId: channelData.id },
          {
            errorNotification: errorNotification(formatMessage),
            successNotification: successNotification(formatMessage),
            setLoading,
          }
        );
        resetFields(["users"]);
        fetchChannels();
      }

      if (invitations) {
        setLoadingInvitations(true);
        await CloudChannelsServices.invitationsPermission(
          { invitations, cloudId: channelData.id },
          {
            errorNotification: errorNotification(formatMessage),
            setLoadingInvitations,
          }
        );
        await AccountServices.invitationsAccount(
          { invitations, cloudId: channelData.id },
          {
            errorNotification: errorNotification(formatMessage),
            successNotification: successNotification(formatMessage),
            setLoadingInvitations,
          }
        );
        resetFields(["invitations"]);
        fetchChannels();
      }
    },
    [channelData, fetchChannels, formatMessage, resetFields]
  );

  useMountEffect(() => {
    fetchChannels();
  });

  const adminColumns = [
    {
      title: <FormattedMessage id="ChannelDetailView.username" defaultMessage="Username" />,
      dataIndex: "username",
      key: "username",
    },
  ];

  const columns = [
    {
      title: <FormattedMessage id="ChannelDetailView.username" defaultMessage="Username" />,
      dataIndex: "username",
      key: "username",
    },
    {
      title: <FormattedMessage id="ChannelDetailView.permissionName" defaultMessage="Share name" />,
      dataIndex: "description",
      key: "description",
    },
    {
      title: <FormattedMessage id="ChannelDetailView.status" defaultMessage="Status" />,
      dataIndex: "status",
      key: "status",
      render: (status) => <ChannelDetailViewStatus status={status} />,
    },
    {
      key: "actions",
      width: 100,
      title: formatMessage(translations.actions),
      render: (text, { permissionId }) => (
        <Popconfirm
          title={
            <FormattedMessage
              id="ChannelDetailView.confirmRemove"
              defaultMessage="Are you sure, you want to remove permission for this user?"
            />
          }
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          onConfirm={() => removePermission(permissionId)}
          okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
          cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
        >
          <Button type="danger" icon={<DeleteOutlined />} disabled={loading} loading={loading}>
            <span>
              <FormattedMessage id="general.remove" defaultMessage="Remove" />
            </span>
          </Button>
        </Popconfirm>
      ),
    },
  ];

  if (!channelData) {
    return (
      <Empty
        description={
          <FormattedMessage id="ChannelDetailView.waitingForStatistics" defaultMessage="Missing channel data" />
        }
      />
    );
  }

  return (
    <Card title={<ChannelDetailViewCardTitle channelData={channelData} />}>
      <Form onFinish={handleFinish} form={form}>
        <StyledCard title={<FormattedMessage id="ChannelDetailView.sharedWith" defaultMessage="Shared with" />}>
          <StyledTable
            columns={isChannelOwner ? columns : adminColumns}
            dataSource={permissions}
            pagination={false}
            bordered
            role="table"
            rowKey={(record) => record.permissionId}
            rowClassName={(record) => (record.username ? "active" : "pending")}
          />
        </StyledCard>
        {isChannelOwner && (
          <Affix offsetBottom={0}>
            <Card title={<FormattedMessage id="ChannelDetailView.addNewShares" defaultMessage="Add new shares" />}>
              <Row type="flex" justify="space-between" gutter={12}>
                <Col md={24} lg={12}>
                  <Card
                    title={
                      <FormattedMessage id="ChannelDetailView.shareWith" defaultMessage="Share with existing user" />
                    }
                  >
                    <PermissionDynamicForm
                      channelName={channelData?.channelName}
                      setFieldsValue={setFieldsValue}
                      getFieldValue={getFieldValue}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  </Card>
                </Col>
                <Col md={24} lg={12}>
                  <Card
                    title={
                      <Tooltip
                        title={
                          <FormattedMessage
                            id="ChannelDetailView.senInvitationTooltip"
                            // eslint-disable-next-line max-len
                            defaultMessage="Send an email with invitation to Quickstream Cloud. Your channel will be shared after user registers."
                          />
                        }
                      >
                        <StyledMarginRight>
                          <FormattedMessage id="ChannelDetailView.inviteNewUser" defaultMessage="Invite new user" />
                        </StyledMarginRight>
                        <QuestionCircleFilled />
                      </Tooltip>
                    }
                  >
                    <PermissionInvitationDynamicForm
                      channelName={channelData?.channelName}
                      setFieldsValue={setFieldsValue}
                      getFieldValue={getFieldValue}
                    />
                  </Card>
                </Col>
                <Col span={24}>
                  <StyledShareButton type="primary" htmlType="submit" disabled={loading || loadingInvitations}>
                    <span>
                      <FormattedMessage id="ChannelDetailView.submit" defaultMessage="Submit new shares" />
                    </span>
                  </StyledShareButton>
                </Col>
              </Row>
            </Card>
          </Affix>
        )}
      </Form>
    </Card>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 15px;
`;

const StyledShareButton = styled(Button)`
  width: 100%;
  margin: 15px 0;
`;

const StyledTable = styled(Table)`
  .pending {
    background: ${themeColor.orangeBackground};
  }
`;

const StyledMarginRight = styled.span`
  margin-right: 5px;
`;

const mapStateToProps = (state) => ({
  username: ACCOUNT_SELECTORS.getUser(state),
  ownChannelsList: CLOUD_CHANNELS_SELECTORS.getOwnChannels(state),
  allChannelsList: CLOUD_CHANNELS_SELECTORS.getAllChannels(state),
});

ChannelDetailView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  ownChannelsList: PropTypes.arrayOf(
    PropTypes.shape({
      cloudId: PropTypes.number.isRequired,
      channelName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      isEncrypted: PropTypes.number,
      isPublic: PropTypes.number.isRequired,
      userId: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  allChannelsList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cloudId: PropTypes.number.isRequired,
        channelName: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        userId: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
  username: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(ChannelDetailView);
