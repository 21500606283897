import React, { useState } from "react";
import { Select, Spin, Form, Button, Input } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";

import { errorNotification } from "../../../lib/utils/notification";
import { az09space, max32characters } from "../../../lib/utils/formValidation";
import { PlatformAPI } from "../../../lib/api";

const { Option } = Select;
const { Item, List } = Form;

const translations = defineMessages({
  username: {
    id: "PermissionDynamicForm.username",
    defaultMessage: "Username",
  },
  description: {
    id: "PermissionDynamicForm.permissionName",
    defaultMessage: "Share name",
  },
});

const PermissionDynamicForm = ({ getFieldValue, setFieldsValue, channelName, loading, setLoading }) => {
  const { formatMessage } = useIntl();
  const [user, setUser] = useState([]);

  const setInitialFieldValue = () => {
    setLoading(true);
    const fieldValue = getFieldValue("users");
    const initialUsersData = fieldValue.map((value) => {
      if (value?.description) {
        setLoading(false);

        return value;
      }
      setLoading(false);

      return { ...value, description: channelName };
    });

    setFieldsValue({ users: initialUsersData });
  };

  const handleSearch = debounce(async (value) => {
    if (value) {
      try {
        setLoading(true);
        setUser([]);
        const result = await PlatformAPI.checkUser(
          { username: value },
          { errorNotification: errorNotification(formatMessage) }
        );
        setLoading(false);
        setUser(result);
      } catch (error) {
        setLoading(false);
      }
    }
  }, 500);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  return (
    <List name="users">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => (
              <Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? <FormattedMessage id="PermissionDynamicForm.users" defaultMessage="Users" /> : ""}
                required={false}
                key={field.key}
                fieldKey={field.fieldKey}
              >
                <Item
                  name={[field.name, "username"]}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: (
                        <FormattedMessage
                          id="PermissionDynamicForm.usernameRequired"
                          defaultMessage="Please input username or delete this field."
                        />
                      ),
                    },
                  ]}
                  noStyle
                >
                  <Select
                    showSearch
                    autoClearSearchValue
                    style={{ width: "45%", marginRight: 8 }}
                    placeholder={<FormattedMessage id="ChannelDetailView.username" defaultMessage="Username" />}
                    onSearch={handleSearch}
                    notFoundContent={loading && <Spin size="small" />}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                  >
                    {user.length > 0 && user.map((userData) => <Option key={userData}>{userData}</Option>)}
                  </Select>
                </Item>
                <Item
                  name={[field.name, "description"]}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: (
                        <FormattedMessage
                          id="PermissionDynamicForm.descriptionRequired"
                          defaultMessage="Please input share name or delete this field."
                        />
                      ),
                    },
                    az09space,
                    max32characters,
                  ]}
                  noStyle
                >
                  <Input
                    placeholder={formatMessage(translations.description)}
                    style={{ width: "45%", marginRight: 8 }}
                  />
                </Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                ) : null}
              </Item>
            ))}
            <Item>
              <StyledButton>
                <Button
                  type="dashed"
                  style={{ width: "50%" }}
                  disabled={loading}
                  onClick={() => {
                    add();
                    setUser([]);
                    setInitialFieldValue();
                  }}
                >
                  <PlusOutlined /> <FormattedMessage id="PermissionDynamicForm.addUser" defaultMessage="Add user" />
                </Button>
              </StyledButton>
            </Item>
          </>
        );
      }}
    </List>
  );
};

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
`;

PermissionDynamicForm.propTypes = {
  channelName: PropTypes.string,
  getFieldValue: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

PermissionDynamicForm.defaultProps = {
  channelName: null,
};

export default PermissionDynamicForm;
