import React from "react";
import styled from "styled-components";
import { Form, Card, Input, Button, Divider, Row, Alert } from "antd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import logoUrl from "../../lib/images/logo-cloud-black.png";
import themeColor from "../../lib/style/theme";
import { required } from "../../lib/utils/formValidation";

import AccountService from "../../services/account";
import { errorNotification, successNotification } from "../../lib/utils/notification";

const { Item } = Form;

const translations = defineMessages({
  notValidEmail: {
    id: "general.notValidEmail",
    defaultMessage: "Invalid email format",
  },
  email: {
    id: "Login2FA.email",
    defaultMessage: "Please input your email",
  },
});

const Login2FA = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const handleFinish = (formData) => {
    AccountService.auth2fa(formData, {
      errorNotification: errorNotification(formatMessage),
      successNotification: successNotification(formatMessage),
    });
  };

  return (
    <StyledFormContainer>
      <Card
        title={<FormattedMessage id="Login2FA.twoFactorAuth" defaultMessage="Two-factor authorization" />}
        extra={<img src={logoUrl} alt="quickstream logo" width="200" />}
      >
        <StyledForm onFinish={handleFinish} form={form}>
          <Alert
            showIcon
            message={
              <FormattedMessage
                id="Login2FA.recaptchaErrorInfo"
                defaultMessage="We were unable to verify your data, please provide an email to your Quickstream account."
              />
            }
            description={
              <FormattedMessage
                id="Login2FA.recaptchaErrorInfo2"
                defaultMessage=" An email will be sent to you with a link to login to Quickstream Cloud."
              />
            }
            type="warning"
          />
          <StyledInput
            name="email"
            rules={[required, { type: "email", message: formatMessage(translations.notValidEmail) }]}
          >
            <Input
              prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={formatMessage(translations.email)}
            />
          </StyledInput>
          <StyledButton type="primary" htmlType="submit">
            <FormattedMessage id="Login2FA.loginButton" defaultMessage="Send two-factor authorization email" />
          </StyledButton>
        </StyledForm>
        <Divider />
        <Row type="flex" justify="center" align="middle">
          <Link to="/login">
            <StyledOrangeURL>
              <FormattedMessage id="Login2FA.backToLogin" defaultMessage="Back to Login" />
            </StyledOrangeURL>
          </Link>
        </Row>
      </Card>
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledOrangeURL = styled.span`
  color: ${themeColor.orange};
  transition: color 0.2s ease;

  &:hover {
    color: ${themeColor.orangeHover};
  }
`;

const StyledInput = styled(Item)`
  margin: 15px;
`;

const StyledForm = styled(Form)`
  min-width: 400px;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
  margin: 20px 0 0 0;
`;

export default Login2FA;
