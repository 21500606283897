import * as React from "react";
import { useIntl, defineMessages } from "react-intl";
import { Col } from "antd";
import PropTypes from "prop-types";

import { IPField, PortField } from "../../../components/Fields/Fields";
import NetworkField from "../../../components/Fields/NetworkField";

import NetworkFieldLabel from "../Labels/NetworkFieldLabel";

import { CONNECTION_METHOD } from "../../../lib/utils/constants";

const translations = defineMessages({
  ipAddressLabel: {
    id: "IPField.label",
    defaultMessage: "IP Address",
  },
});

const InputStreamUdpSection = ({ prefix, connectionMethod }) => {
  const { formatMessage } = useIntl();
  const isMulticastType = connectionMethod === CONNECTION_METHOD.udpMulticast.value;

  return (
    <>
      {isMulticastType && (
        <Col span={12}>
          <IPField name={[...prefix, "dstIp"]} label={formatMessage(translations.ipAddressLabel)} />
        </Col>
      )}
      <Col span={12}>
        <PortField name={[...prefix, "dstPort"]} />
      </Col>
      <Col span={12}>
        <NetworkField name={[...prefix, "interfaceIp"]} label={NetworkFieldLabel("input")} />
      </Col>
    </>
  );
};

InputStreamUdpSection.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  connectionMethod: PropTypes.string.isRequired,
};

export default InputStreamUdpSection;
