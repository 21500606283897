import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import { Row, Spin, Card } from "antd";
import styled from "styled-components";

import AccountService from "../../services/account";
import { errorNotification, successNotification } from "../../lib/utils/notification";

const AccountActivation = ({
  match: {
    params: { activationToken },
  },
}) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    async function activateAccount() {
      await AccountService.accountActivation(
        { activationToken },
        {
          errorNotification: errorNotification(formatMessage),
          successNotification: successNotification(formatMessage),
        }
      );
    }

    activateAccount();
  }, [activationToken, formatMessage]);

  return (
    <StyledRow justify="center" align="middle">
      <Card>
        <Spin
          tip={<FormattedMessage id="AccountActivation.accountActivation" defaultMessage="Account activation..." />}
        />
      </Card>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  min-width: 100%;
`;

AccountActivation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      activationToken: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AccountActivation;
