import moment from "moment";
import { camelizeKeys } from "humps";
import parseJwt from "./parseJwt";

export default (token) => {
  const parsedToken = camelizeKeys(parseJwt(token));
  const { username, role, emailOptIn, email } = parsedToken;
  const loginData = moment.unix(parseJwt(token).iat).utc().format("YYYY-MM-DD HH:mm:ss");

  return { email, username, role, loginData, emailOptIn: emailOptIn === "1" };
};
