import React, { useState, useEffect, useRef } from "react";
import { Select, Button, Row, Col, Card } from "antd";
import { Line, Chart } from "react-chartjs-2";
import moment from "moment";
import zoom from "chartjs-plugin-zoom";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import translations from "../../../lib/translations/nodeTranslations";

const { Option } = Select;

const StatisticsGraph = ({ data }) => {
  const statRef = useRef(null);
  const { formatMessage } = useIntl();
  useEffect(() => {
    Chart.plugins.register(zoom);
  }, []);

  const handleResetZoom = () => {
    statRef.current.chartInstance.resetZoom();
  };

  const parseStatisticName = (statisticName) => {
    const translationExist = statisticName in translations;

    return translationExist ? formatMessage(translations[statisticName]) : statisticName;
  };

  const forDeletion = [
    "channelId",
    "cloudId",
    "engineId",
    "time",
    "msTimestamp",
    "channelName",
    "permissionId",
    "username",
  ];
  const dataKeysArray = Object.keys(Object.values(data)[0]);
  const filteredDataKeysArray = dataKeysArray.filter((item) => !forDeletion.includes(item));

  const [selectedStat, setSelectedStat] = useState(filteredDataKeysArray[0]);

  const handleChange = (selectedValue) => {
    setSelectedStat(selectedValue);
    handleResetZoom();
  };

  const options = {
    pan: {
      enabled: true,
      mode: "yx",
    },
    zoom: {
      enabled: true,
      mode: "x",
    },
  };

  const parsedData = {
    labels: data.map((statistic) => moment.unix(statistic.time).format("HH:mm:ss DD-MM-YYYY")),
    datasets: [
      {
        label: parseStatisticName(selectedStat),
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: data.map((statistic) => statistic[selectedStat]),
      },
    ],
  };

  return (
    <StyledCard title={<FormattedMessage id="StatisticsGraph.title" defaultMessage="Statistics chart" />}>
      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="StatisticsGraph.selectStatistic" defaultMessage="Select statistic:" />{" "}
          <Select onChange={handleChange} style={{ width: 250 }} defaultValue={selectedStat}>
            {filteredDataKeysArray.map((statisticName) => (
              <Option key={statisticName}>{parseStatisticName(statisticName)}</Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Button type="primary" onClick={handleResetZoom}>
            <FormattedMessage id="StatisticsGraph.resetZoom" defaultMessage="Reset zoom" />
          </Button>
        </Col>
      </Row>
      <Line data={parsedData} options={options} ref={statRef} />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 10px;
`;

StatisticsGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      ccErrors: PropTypes.string,
      ccErrorsPerHour: PropTypes.string,
      ccErrorsTotal: PropTypes.string,
      channelId: PropTypes.string,
      channelName: PropTypes.string,
      cloudId: PropTypes.string,
      mbpsBandwidth: PropTypes.string,
      mbpsRecvRate: PropTypes.string,
      mbpsSendRate: PropTypes.string,
      msRtt: PropTypes.string,
      msTimestamp: PropTypes.string,
      permissionId: PropTypes.string,
      pktRcvBelated: PropTypes.string,
      pktRcvDrop: PropTypes.string,
      pktRcvLoss: PropTypes.string,
      pktRcvRetrans: PropTypes.string,
      pktRecv: PropTypes.string,
      pktRetrans: PropTypes.string,
      pktSent: PropTypes.string,
      pktSndDrop: PropTypes.string,
      pktSndLoss: PropTypes.string,
      time: PropTypes.string,
      username: PropTypes.string,
    })
  ).isRequired,
};

export default StatisticsGraph;
