import * as React from "react";
import { Col } from "antd";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { CONNECTION_METHOD } from "../../../lib/utils/constants";
import globalTranslations from "../../../lib/translations";

import { IPField, PortField } from "../../../components/Fields/Fields";
import InputNumberField from "../../../components/Fields/InputNumberField";
import NetworkField from "../../../components/Fields/NetworkField";
import PassphraseField from "../../../components/Fields/PassphraseField";

import { required } from "../../../lib/utils/formValidation";

import OverheadBandwidthLabel from "../Labels/OverheadBandwidthLabel";
import LatencyLabel from "../Labels/LatencyLabel";

const InputStreamSrtSection = ({ prefix, connectionMethod, initialValue }) => {
  const { formatMessage } = useIntl();
  const isListenerMode = connectionMethod === CONNECTION_METHOD.srtListener.value;
  const isCallerMode = connectionMethod === CONNECTION_METHOD.srtCaller.value;
  const isRendezvousMode = connectionMethod === CONNECTION_METHOD.srtRendezvous.value;

  return (
    <>
      {!isListenerMode && (
        <Col md={24} lg={12}>
          <IPField label={formatMessage(globalTranslations.adressIp)} name={[...prefix, "dstIp"]} />
        </Col>
      )}
      <Col md={24} lg={12}>
        <PortField name={[...prefix, "dstPort"]} />
      </Col>
      {!isCallerMode && (
        <Col md={24} lg={12}>
          <NetworkField name={[...prefix, "interfaceIp"]} requiredField={!isRendezvousMode} />
        </Col>
      )}
      <Col md={24} lg={12}>
        <InputNumberField
          name={[...prefix, "ffmpegStreamProperties", "oheadbw"]}
          label={OverheadBandwidthLabel()}
          fieldValidation={required}
        />
      </Col>
      <Col md={24} lg={12}>
        <InputNumberField
          name={[...prefix, "ffmpegStreamProperties", "rcvlatency"]}
          label={LatencyLabel("input")}
          fieldValidation={required}
        />
      </Col>
      <Col md={24} lg={24}>
        <PassphraseField
          prefix={[...prefix, "ffmpegStreamProperties"]}
          initialValue={initialValue?.ffmpegStreamProperties?.passphrase}
        />
      </Col>
    </>
  );
};

InputStreamSrtSection.propTypes = {
  initialValue: PropTypes.shape({
    connectionMethod: PropTypes.string,
    dstPort: PropTypes.number,
    ffmpegStreamProperties: PropTypes.shape({
      connectTimeout: PropTypes.number,
      mode: PropTypes.string,
      oheadbw: PropTypes.number,
      rcvlatency: PropTypes.number,
    }),
    interfaceIp: PropTypes.string,
  }),
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
  connectionMethod: PropTypes.string.isRequired,
};

InputStreamSrtSection.defaultProps = {
  initialValue: null,
};

export default InputStreamSrtSection;
