import { defineMessages } from "react-intl";

export default defineMessages({
  // warning definitions
  "warning-0": {
    id: "nodeWarning.0",
    defaultMessage: "Unknown warning",
  },
  "warning-1": {
    id: "nodeWarning.1",
    defaultMessage: "License is in 7 days expiration period",
  },
  "warning-2": {
    id: "nodeWarning.2",
    defaultMessage: "Buffer overrun occurred",
  },
  "warning-3": {
    id: "nodeWarning.3",
    defaultMessage: "Interface ip address is invalid and has been ignored",
  },

  // 1 -  999 system errors (ex. disc, processor)
  0: {
    id: "nodeError.0",
    defaultMessage: "Status OK",
  },
  1: {
    id: "nodeError.1",
    defaultMessage: "Cannot create folder for configuration files",
  },
  2: {
    id: "nodeError.2",
    defaultMessage: "Cannot create folder for log files",
  },
  3: {
    id: "nodeError.3",
    defaultMessage: "Configuration file is not writable",
  },
  4: {
    id: "nodeError.4",
    defaultMessage: "Cannot open websocket",
  },
  5: {
    id: "nodeError.5",
    defaultMessage: "Cannot create REST API server",
  },
  6: {
    id: "nodeError.6",
    defaultMessage: "Cannot generate crypto keys",
  },
  7: {
    id: "nodeError.7",
    defaultMessage: "Cannot create folder for pid file",
  },
  8: {
    id: "nodeError.8",
    defaultMessage: "Cannot open/create pid file",
  },
  9: {
    id: "nodeError.9",
    defaultMessage: "Cannot start qs-node service, service is already running",
  },
  10: {
    id: "nodeError.10",
    defaultMessage: "Invalid environment",
  },
  11: {
    id: "nodeError.11",
    defaultMessage: "Cannot open system file needed to generate stats",
  },
  12: {
    id: "nodeError.12",
    defaultMessage: "Wrong values in system file needed to generate stats",
  },

  // 900 - 999 windows errors
  900: {
    id: "nodeError.900",
    defaultMessage: "WMI error",
  },

  // 1000 - 1999 application errors (ex. database errors)
  1000: {
    id: "nodeError.1000",
    defaultMessage: "Unknown database exception",
  },
  1001: {
    id: "nodeError.1001",
    defaultMessage: "Database error while inserting ",
  },
  1002: {
    id: "nodeError.1002",
    defaultMessage: "Database error while updating",
  },
  1003: {
    id: "nodeError.1003",
    defaultMessage: "Database error while querying ",
  },
  1004: {
    id: "nodeError.1004",
    defaultMessage: "Database error while deleting",
  },
  1005: {
    id: "nodeError.1005",
    defaultMessage: "Inserting duplicate value failed (Unique constraint failed)",
  },
  1006: {
    id: "nodeError.1006",
    defaultMessage: "Cannot start transaction in database",
  },
  1007: {
    id: "nodeError.1007",
    defaultMessage: "Cannot commit transaction in database",
  },
  1008: {
    id: "nodeError.1008",
    defaultMessage: "Cannot rollback transaction in database",
  },
  1009: {
    id: "nodeError.1009",
    defaultMessage: "Cannot create table in database",
  },
  1010: {
    id: "nodeError.1010",
    defaultMessage: "Cannot create database",
  },
  1011: {
    id: "nodeError.1011",
    defaultMessage: "Database was created for other environment than currently running node",
  },

  // 2000 - 2999 licese errors
  2000: {
    id: "nodeError.2000",
    defaultMessage: "License ID is empty",
  },
  2001: {
    id: "nodeError.2001",
    defaultMessage: "LicenseId not found",
  },
  2002: {
    id: "nodeError.2002",
    defaultMessage: "License has expired",
  },
  2003: {
    id: "nodeError.2003",
    defaultMessage: "Unable to add channel, Maximum number of channels for this license is reached",
  },
  2004: {
    id: "nodeError.2004",
    defaultMessage: "Cannot remove license (license probably in use)",
  },
  2005: {
    id: "nodeError.2005",
    defaultMessage: "Cannot decode license",
  },
  2006: {
    id: "nodeError.2006",
    defaultMessage: "License token cannot be empty",
  },
  2007: {
    id: "nodeError.2007",
    defaultMessage: "License has invalid signature",
  },
  2008: {
    id: "nodeError.2008",
    defaultMessage: "Invalid license",
  },
  2009: {
    id: "nodeError.2009",
    defaultMessage: "License server connection error",
  },
  2010: {
    id: "nodeError.2010",
    defaultMessage: "License is invalid",
  },
  2011: {
    id: "nodeError.2011",
    defaultMessage: "License has not been decoded properly",
  },
  2012: {
    id: "nodeError.2012",
    defaultMessage: "Incomplete reply from license server",
  },

  // 3000 - 3999 chennales configuration errors
  3000: {
    id: "nodeError.3000",
    defaultMessage: "Channel name cannot be empty",
  },
  3001: {
    id: "nodeError.3001",
    defaultMessage: "Invalid version of Quickstream Client: Empty version string",
  },
  3002: {
    id: "nodeError.3002",
    defaultMessage: "Channel license ID cannot be empty",
  },
  3003: {
    id: "nodeError.3003",
    defaultMessage: "Incorrect channel ID",
  },
  3004: {
    id: "nodeError.3004",
    defaultMessage: "Invalid version of Quickstream Client: Client is newer than Node",
  },
  3005: {
    id: "nodeError.3005",
    defaultMessage: "Invalid version of Quicksteam Client: Invalid version string",
  },

  3101: {
    id: "nodeError.3101",
    defaultMessage: "Missing input configuration",
  },
  3102: {
    id: "nodeError.3102",
    defaultMessage: "Missing output configuration",
  },
  3103: {
    id: "nodeError.3103",
    defaultMessage: "Missing required option for input",
  },
  3104: {
    id: "nodeError.3104",
    defaultMessage: "Missing required option for output",
  },
  3105: {
    id: "nodeError.3105",
    defaultMessage: "Unknown protocol for input",
  },
  3106: {
    id: "nodeError.3106",
    defaultMessage: "Unknown protocol for output",
  },
  3107: {
    id: "nodeError.3107",
    defaultMessage: "Invalid configuration for input",
  },
  3108: {
    id: "nodeError.3108",
    defaultMessage: "Invalid configuration for output",
  },
  3109: {
    id: "nodeError.3109",
    defaultMessage: "Incorrect IP for input",
  },
  3110: {
    id: "nodeError.3110",
    defaultMessage: "Incorrect IP for output",
  },
  3111: {
    id: "nodeError.3111",
    defaultMessage: "Missing required SRT option in input",
  },
  3112: {
    id: "nodeError.3112",
    defaultMessage: "Missing required SRT option in output",
  },
  3113: {
    id: "nodeError.3113",
    defaultMessage: "Invalid port for SRT input",
  },
  3114: {
    id: "nodeError.3114",
    defaultMessage: "Invalid port for SRT output",
  },
  3115: {
    id: "nodeError.3115",
    defaultMessage: "Missing inferface IP for rendezvous SRT input",
  },
  3116: {
    id: "nodeError.3116",
    defaultMessage: "Missing inferface IP for rendezvous SRT output",
  },
  3117: {
    id: "nodeError.3117",
    defaultMessage: "Invalid IP class for UDP input",
  },
  3118: {
    id: "nodeError.3118",
    defaultMessage: "Invalid IP class for UDP output",
  },
  3119: {
    id: "nodeError.3119",
    defaultMessage: "Invalid port for UDP input",
  },
  3120: {
    id: "nodeError.3120",
    defaultMessage: "Invalid port for UDP output",
  },

  // QS method errors
  3201: {
    id: "nodeError.3201",
    defaultMessage: "Invalid PermissionId for QS input",
  },
  3202: {
    id: "nodeError.3202",
    defaultMessage: "Invalid CloudId for QS input",
  },
  3203: {
    id: "nodeError.3203",
    defaultMessage: "Invalid CloudId for QS output",
  },
  3204: {
    id: "nodeError.3204",
    defaultMessage: "Channel reached connection limit!",
  },
  3205: {
    id: "nodeError.3205",
    defaultMessage: "Quickstream channel config missing stoken value",
  },
  3206: {
    id: "nodeError.3206",
    defaultMessage: "Config version mismatch: QS Stream in 1.0 config",
  },
  3207: {
    id: "nodeError.3207",
    defaultMessage: "Channel not ready, server not connected yet",
  },
  3208: {
    id: "nodeError.3208",
    defaultMessage: "Channel not ready, not completely configured yet - no port range",
  },
  3209: {
    id: "nodeError.3209",
    defaultMessage: "Channel not ready, not completely configured yet - missing external IP",
  },
  3210: {
    id: "nodeError.3210",
    defaultMessage: "No free ports are available to connect!",
  },
  3211: {
    id: "nodeError.3211",
    defaultMessage: "Cannot connect to server",
  },

  // 4000 - 4999 Video errors
  4001: {
    id: "nodeError.4001",
    defaultMessage: "Input video error: Cannot open stream",
  },
  4002: {
    id: "nodeError.4002",
    defaultMessage: "Output video error: Cannot open stream",
  },
  4003: {
    id: "nodeError.4003",
    defaultMessage: "Input video error: Cannot read stream",
  },
  4004: {
    id: "nodeError.4004",
    defaultMessage: "Output video error: Cannot write stream",
  },
  4005: {
    id: "nodeError.4005",
    defaultMessage: "Input video error: Out of memory",
  },
  4006: {
    id: "nodeError.4006",
    defaultMessage: "Output video error: Out of memory",
  },
  4007: {
    id: "nodeError.4007",
    defaultMessage: "Input video error: Not implemented",
  },
  4008: {
    id: "nodeError.4008",
    defaultMessage: "Output video error: Not implemented",
  },
  4009: {
    id: "nodeError.4009",
    defaultMessage: "Input video error",
  },
  4010: {
    id: "nodeError.4010",
    defaultMessage: "Output video error",
  },
  4011: {
    id: "nodeError.4011",
    defaultMessage: "Input video error: incorrect configuration",
  },
  4012: {
    id: "nodeError.4012",
    defaultMessage: "Output video error: incorrect configuration",
  },
  4013: {
    id: "nodeError.4013",
    defaultMessage: "Input video error: missing configuration option",
  },
  4014: {
    id: "nodeError.4014",
    defaultMessage: "Output video error: missing configuration option",
  },
  4015: {
    id: "nodeError.4015",
    defaultMessage: "Input video error: protocol not found",
  },
  4016: {
    id: "nodeError.4016",
    defaultMessage: "Output video error: protocol not found",
  },
  4017: {
    id: "nodeError.4017",
    defaultMessage: "Input video error: out of memory",
  },
  4018: {
    id: "nodeError.4018",
    defaultMessage: "Output video error: out of memory",
  },
  4019: {
    id: "nodeError.4019",
    defaultMessage: "Input video error: timeout",
  },
  4020: {
    id: "nodeError.4020",
    defaultMessage: "Output video error: timeout",
  },

  // SRT errors
  4200: {
    id: "nodeError.4200",
    defaultMessage: "Input SRT connection error",
  },
  4201: {
    id: "nodeError.4201",
    defaultMessage: "Output SRT connection error",
  },
  4202: {
    id: "nodeError.4202",
    defaultMessage: "Input SRT connection error: Cannot initialize resources",
  },
  4203: {
    id: "nodeError.4203",
    defaultMessage: "Output SRT connection error: Cannot initialize resources",
  },
  4204: {
    id: "nodeError.4204",
    defaultMessage: "Input SRT connection error: Cannot configure socket",
  },
  4205: {
    id: "nodeError.4205",
    defaultMessage: "Output SRT connection error: Cannot configure socket",
  },
  4206: {
    id: "nodeError.4206",
    defaultMessage: "Input SRT connection error: Security error",
  },
  4207: {
    id: "nodeError.4207",
    defaultMessage: "Output SRT connection error: Security error",
  },
  4208: {
    id: "nodeError.4208",
    defaultMessage: "Input SRT connection error: Connection failure",
  },
  4209: {
    id: "nodeError.4209",
    defaultMessage: "Output SRT connection error: Connection failure",
  },
  4210: {
    id: "nodeError.4210",
    defaultMessage: "Input SRT connection error: Resource allocation error",
  },
  4211: {
    id: "nodeError.4211",
    defaultMessage: "Output SRT connection error: Resource allocation error",
  },
  4212: {
    id: "nodeError.4212",
    defaultMessage: "Input SRT connection error: Threading error",
  },
  4213: {
    id: "nodeError.4213",
    defaultMessage: "Output SRT connection error: Threading error",
  },
  4214: {
    id: "nodeError.4214",
    defaultMessage: "Input SRT connection error: Not enough memory error",
  },
  4215: {
    id: "nodeError.4215",
    defaultMessage: "Output SRT connection error: Not enough memory error",
  },
  4216: {
    id: "nodeError.4216",
    defaultMessage: "Input SRT connection error: File operation error",
  },
  4217: {
    id: "nodeError.4217",
    defaultMessage: "Output SRT connection error: File operation error",
  },
  4218: {
    id: "nodeError.4218",
    defaultMessage: "Input SRT connection error: Error reading file - invalid offset",
  },
  4219: {
    id: "nodeError.4219",
    defaultMessage: "Output SRT connection error: Error reading file - invalid offset",
  },
  4220: {
    id: "nodeError.4220",
    defaultMessage: "Input SRT connection error: Error reading file (permissions?)",
  },
  4221: {
    id: "nodeError.4221",
    defaultMessage: "Output SRT connection error: Error reading file (permissions?)",
  },
  4222: {
    id: "nodeError.4222",
    defaultMessage: "Input SRT connection error: Error writing file - invalid offset",
  },
  4223: {
    id: "nodeError.4223",
    defaultMessage: "Output SRT connection error: Error writing file - invalid offset",
  },
  4224: {
    id: "nodeError.4224",
    defaultMessage: "Input SRT connection error: Error opening file for writing (permissions?)",
  },
  4225: {
    id: "nodeError.4225",
    defaultMessage: "Output SRT connection error: Error opening file for writing (permissions?)",
  },
  4226: {
    id: "nodeError.4226",
    defaultMessage: "Input SRT connection error: Internal error - invalid operation",
  },
  4227: {
    id: "nodeError.4227",
    defaultMessage: "Output SRT connection error: Internal error - invalid operation",
  },
  4228: {
    id: "nodeError.4228",
    defaultMessage: "Input SRT connection error: Socket is already bound",
  },
  4229: {
    id: "nodeError.4229",
    defaultMessage: "Output SRT connection error: Socket is already bound",
  },
  4230: {
    id: "nodeError.4230",
    defaultMessage: "Input SRT connection error: Socket is already connected",
  },
  4231: {
    id: "nodeError.4231",
    defaultMessage: "Output SRT connection error: Socket is already connected",
  },
  4232: {
    id: "nodeError.4232",
    defaultMessage: "Input SRT connection error: Invalid param",
  },
  4233: {
    id: "nodeError.4233",
    defaultMessage: "Output SRT connection error: Invalid param",
  },
  4234: {
    id: "nodeError.4234",
    defaultMessage: "Input SRT connection error: Invalid socket used",
  },
  4235: {
    id: "nodeError.4235",
    defaultMessage: "Output SRT connection error: Invalid socket used",
  },
  4236: {
    id: "nodeError.4236",
    defaultMessage: "Input SRT connection error: Socket not a listener",
  },
  4237: {
    id: "nodeError.4237",
    defaultMessage: "Output SRT connection error: Socket not a listener",
  },
  4238: {
    id: "nodeError.4238",
    defaultMessage: "Input SRT connection error: Socket cannot be set as rendezvous",
  },
  4239: {
    id: "nodeError.4239",
    defaultMessage: "Output SRT connection error: Socket cannot be set as rendezvous",
  },
  4240: {
    id: "nodeError.4240",
    defaultMessage: "Input SRT connection error: Socket set to rendezvous before binding",
  },
  4241: {
    id: "nodeError.4241",
    defaultMessage: "Output SRT connection error: Socket set to rendezvous before binding",
  },
  4242: {
    id: "nodeError.4242",
    defaultMessage: "Input SRT connection error: Incorrect API usage in message mode",
  },
  4243: {
    id: "nodeError.4243",
    defaultMessage: "Output SRT connection error: Incorrect API usage in message mode",
  },
  4244: {
    id: "nodeError.4244",
    defaultMessage: "Input SRT connection error: Incorrect API usage in stream mode",
  },
  4245: {
    id: "nodeError.4245",
    defaultMessage: "Output SRT connection error: Incorrect API usage in stream mode",
  },
  4246: {
    id: "nodeError.4246",
    defaultMessage: "Input SRT connection error: Socket already used as listener",
  },
  4247: {
    id: "nodeError.4247",
    defaultMessage: "Output SRT connection error: Socket already used as listener",
  },
  4248: {
    id: "nodeError.4248",
    defaultMessage: "Input SRT connection error: Message too large to fit in sending buffer",
  },
  4249: {
    id: "nodeError.4249",
    defaultMessage: "Output SRT connection error: Message too large to fit in sending buffer",
  },
  4250: {
    id: "nodeError.4250",
    defaultMessage: "Input SRT connection error: Invalid epoll id",
  },
  4251: {
    id: "nodeError.4251",
    defaultMessage: "Output SRT connection error: Invalid epoll id",
  },
  4252: {
    id: "nodeError.4252",
    defaultMessage: "Input SRT connection error: SRT has opened too many file descriptors, engine restart necessary",
  },
  4253: {
    id: "nodeError.4253",
    defaultMessage: "Output SRT connection error: SRT has opened too many file descriptors, engine restart necessary",
  },
  4254: {
    id: "nodeError.4254",
    defaultMessage: "Input SRT connection error: connection timeout",
  },
  4255: {
    id: "nodeError.4255",
    defaultMessage: "Output SRT connection error: connection timeout",
  },
  4256: {
    id: "nodeError.4256",
    defaultMessage: "Input SRT connection error: connection lost",
  },
  4257: {
    id: "nodeError.4257",
    defaultMessage: "Output SRT connection error: connection lost",
  },
  4258: {
    id: "nodeError.4258",
    defaultMessage: "Input SRT connection error: socket not connected",
  },
  4259: {
    id: "nodeError.4259",
    defaultMessage: "Output SRT connection error: socket not connected",
  },
  4260: {
    id: "nodeError.4260",
    defaultMessage: "Input SRT connection error: socket not bound",
  },
  4261: {
    id: "nodeError.4261",
    defaultMessage: "Output SRT connection error: socket not bound",
  },
  4262: {
    id: "nodeError.4262",
    defaultMessage: "Input SRT connection error: sync fail",
  },
  4263: {
    id: "nodeError.4263",
    defaultMessage: "Output SRT connection error: sync fail",
  },
  4264: {
    id: "nodeError.4264",
    defaultMessage: "Input SRT connection error: send buffer full",
  },
  4265: {
    id: "nodeError.4265",
    defaultMessage: "Output SRT connection error: send buffer full",
  },
  4266: {
    id: "nodeError.4266",
    defaultMessage: "Input SRT connection error: no data received yet",
  },
  4267: {
    id: "nodeError.4267",
    defaultMessage: "Output SRT connection error: no data received yet",
  },
  4268: {
    id: "nodeError.4268",
    defaultMessage: "Input SRT connection error: timeout",
  },
  4269: {
    id: "nodeError.4269",
    defaultMessage: "Output SRT connection error: timeout",
  },
  4270: {
    id: "nodeError.4270",
    defaultMessage: "Input SRT connection error: congestion error",
  },
  4271: {
    id: "nodeError.4271",
    defaultMessage: "Output SRT connection error: congestion error",
  },
  4272: {
    id: "nodeError.4272",
    defaultMessage: "Input SRT connection error: peer signals error",
  },
  4273: {
    id: "nodeError.4273",
    defaultMessage: "Output SRT connection error: peer signals error",
  },

  4800: {
    id: "nodeError.4800",
    defaultMessage: "Input SRT connection rejected",
  },
  4801: {
    id: "nodeError.4801",
    defaultMessage: "Output SRT connection rejected",
  },
  4802: {
    id: "nodeError.4802",
    defaultMessage: "Input SRT connection rejected: Broken due to system function error",
  },
  4803: {
    id: "nodeError.4803",
    defaultMessage: "Output SRT connection rejected: Broken due to system function error",
  },
  4804: {
    id: "nodeError.4804",
    defaultMessage: "Input SRT connection rejected: Connection was rejected by peer",
  },
  4805: {
    id: "nodeError.4805",
    defaultMessage: "Output SRT connection rejected: Connection was rejected by peer",
  },
  4806: {
    id: "nodeError.4806",
    defaultMessage: "Input SRT connection rejected: Internal problem with resource allocation",
  },
  4807: {
    id: "nodeError.4807",
    defaultMessage: "Output SRT connection rejected: Internal problem with resource allocation",
  },
  4808: {
    id: "nodeError.4808",
    defaultMessage: "Input SRT connection rejected: Incorrect data in handshake messages",
  },
  4809: {
    id: "nodeError.4809",
    defaultMessage: "Output SRT connection rejected: Incorrect data in handshake messages",
  },
  4810: {
    id: "nodeError.4810",
    defaultMessage: "Input SRT connection rejected: Listener's backlog exceeded",
  },
  4811: {
    id: "nodeError.4811",
    defaultMessage: "Output SRT connection rejected: Listener's backlog exceeded",
  },
  4812: {
    id: "nodeError.4812",
    defaultMessage: "Input SRT connection rejected: Internal program error",
  },
  4813: {
    id: "nodeError.4813",
    defaultMessage: "Output SRT connection rejected: Internal program error",
  },
  4814: {
    id: "nodeError.4814",
    defaultMessage: "Input SRT connection rejected: Socket is closing",
  },
  4815: {
    id: "nodeError.4815",
    defaultMessage: "Output SRT connection rejected: Socket is closing",
  },
  4816: {
    id: "nodeError.4816",
    defaultMessage: "Input SRT connection rejected: Peer is older version than agent's minimum set",
  },
  4817: {
    id: "nodeError.4817",
    defaultMessage: "Output SRT connection rejected: Peer is older version than agent's minimum set",
  },
  4818: {
    id: "nodeError.4818",
    defaultMessage: "Input SRT connection rejected: Rendezvous cookie collision",
  },
  4819: {
    id: "nodeError.4819",
    defaultMessage: "Output SRT connection rejected: Rendezvous cookie collision",
  },
  4820: {
    id: "nodeError.4820",
    defaultMessage: "Input SRT connection rejected: Wrong password",
  },
  4821: {
    id: "nodeError.4821",
    defaultMessage: "Output SRT connection rejected: Wrong password",
  },
  4822: {
    id: "nodeError.4822",
    defaultMessage: "Input SRT connection rejected: Password required or unexpected",
  },
  4823: {
    id: "nodeError.4823",
    defaultMessage: "Output SRT connection rejected: Password required or unexpected",
  },
  4824: {
    id: "nodeError.4824",
    defaultMessage: "Input SRT connection rejected: Streamapi/messageapi collision",
  },
  4825: {
    id: "nodeError.4825",
    defaultMessage: "Output SRT connection rejected: Streamapi/messageapi collision",
  },
  4826: {
    id: "nodeError.4826",
    defaultMessage: "Input SRT connection rejected: Incompatible congestion-controller type",
  },
  4827: {
    id: "nodeError.4827",
    defaultMessage: "Output SRT connection rejected: Incompatible congestion-controller type",
  },
  4828: {
    id: "nodeError.4828",
    defaultMessage: "Input SRT connection rejected: Incompatible packet filter",
  },
  4829: {
    id: "nodeError.4829",
    defaultMessage: "Output SRT connection rejected: Incompatible packet filter",
  },

  4900: {
    id: "nodeError.4900",
    defaultMessage: "Unknown error in stream",
  },
  4901: {
    id: "nodeError.4901",
    defaultMessage: "Unknown error in output stream",
  },
  4902: {
    id: "nodeError.4902",
    defaultMessage: "Unknown error in engine",
  },

  // 5000 - 5999 Critical errors
  5000: {
    id: "nodeError.5000",
    defaultMessage: "Cannot encrypt fingerprint",
  },
  5001: {
    id: "nodeError.5001",
    defaultMessage: "Quickstream Node won't run in a container",
  },
  5002: {
    id: "nodeError.5002",
    defaultMessage: "Detected unknown virtualization or container",
  },
  5003: {
    id: "nodeError.5003",
    defaultMessage: "Generated hardware id is empty",
  },
  5004: {
    id: "nodeError.5004",
    defaultMessage: "Couldn't get hardware info",
  },

  // 6000 - 6999 node-client, node-engine erros
  6000: {
    id: "nodeError.6000",
    defaultMessage: "Engine rejected the last command",
  },
  6001: {
    id: "nodeError.6001",
    defaultMessage: "No value for debug level",
  },
  6002: {
    id: "nodeError.6002",
    defaultMessage: "Invalid value for debug level",
  },
  6003: {
    id: "nodeError.6003",
    defaultMessage: "Client sent an incomplete command",
  },
  6004: {
    id: "nodeError.6004",
    defaultMessage: "Engine sent an incomplete command",
  },
  6005: {
    id: "nodeError.6005",
    defaultMessage: "Client sent an unknown command",
  },
  6006: {
    id: "nodeError.6006",
    defaultMessage: "Engine sent an unknown command",
  },
  6007: {
    id: "nodeError.6007",
    defaultMessage: "Client called not-existing endpoint (404)",
  },
  6008: {
    id: "nodeError.6008",
    defaultMessage: "Client called not allowed method on endpoint (405)",
  },
  6009: {
    id: "nodeError.6009",
    defaultMessage: "Unauthorized: invalid authorization data",
  },
  6010: {
    id: "nodeError.6010",
    defaultMessage: "Invalid new password when changing user/password",
  },
  6011: {
    id: "nodeError.6011",
    defaultMessage: "Cannot change username or password",
  },
  6012: {
    id: "nodeError.6012",
    defaultMessage: "Quickstream Node rejected last command due to closing",
  },
  6013: {
    id: "nodeError.6013",
    defaultMessage: "Requested log file does not exist",
  },
  6014: {
    id: "nodeError.6014",
    defaultMessage: "Requested stats file does not exist",
  },
  6015: {
    id: "nodeError.6015",
    defaultMessage: "Requested file with empty filename",
  },
  6016: {
    id: "nodeError.6016",
    defaultMessage: "Requested file with incorrect filename",
  },
  6017: {
    id: "nodeError.6017",
    defaultMessage: "Could not remove some files",
  },
  6018: {
    id: "nodeError.6018",
    defaultMessage: "Version of the client application is too low",
  },
  6019: {
    id: "nodeError.6019",
    defaultMessage: "Too many invalid login attempts from the client, disconnecting",
  },
  6020: {
    id: "nodeError.6020",
    defaultMessage: "Node sent an incomplete command to the engine",
  },
  6021: {
    id: "nodeError.6021",
    defaultMessage: "Port ranges from engine are invalid!",
  },
  6022: {
    id: "nodeError.6022",
    defaultMessage: "Port ranges overlap! Shouldn't happen, restart the whole service!",
  },
  6023: {
    id: "nodeError.6023",
    defaultMessage: "No free port ranges!",
  },
  6024: {
    id: "nodeError.6024",
    defaultMessage: "Invalid hostname!",
  },
  6025: {
    id: "nodeError.6025",
    defaultMessage: "Cloud sent an incomplete command",
  },
  6026: {
    id: "nodeError.6026",
    defaultMessage: "Cloud sent an unknown command",
  },
  6027: {
    id: "nodeError.6027",
    defaultMessage: "Cloud sent command for another node",
  },
  6028: {
    id: "nodeError.6028",
    defaultMessage: "Cloud sent command with invalid sender name",
  },
  6029: {
    id: "nodeError.6029",
    defaultMessage: "Cloud sent command from unauthorized user",
  },
  6030: {
    id: "nodeError.6030",
    defaultMessage: "Setup sent an incomplete command",
  },
  6031: {
    id: "nodeError.6031",
    defaultMessage: "Setup sent an unknown command",
  },
  6032: {
    id: "nodeError.6032",
    defaultMessage: "Cloud sent command restricted for setups",
  },
  6033: {
    id: "nodeError.6033",
    defaultMessage: "Client sent command restricted for setups",
  },
  6034: {
    id: "nodeError.6034",
    defaultMessage: "Setup sent command restricted for clients",
  },
  6035: {
    id: "nodeError.6035",
    defaultMessage: "Version of client application is too high",
  },

  // Stats errors
  6100: {
    id: "nodeError.6100",
    defaultMessage: "Client asked for incorrect column in stats!",
  },
  6101: {
    id: "nodeError.6101",
    defaultMessage: "Client asked for incorrect scale for stats!",
  },
  6102: {
    id: "nodeError.6102",
    defaultMessage: "Client asked for future stats!",
  },
  6103: {
    id: "nodeError.6103",
    defaultMessage: "Client asked for incorrect channel!",
  },
  6104: {
    id: "nodeError.6104",
    defaultMessage: "Client asked for stats outside range!",
  },

  // 7000 - 7999 unknow errors
  7000: {
    id: "nodeError.7000",
    defaultMessage: "Unknown error",
  },
  7001: {
    id: "nodeError.7001",
    defaultMessage: "Caught unhandled std::exception!",
  },
  7002: {
    id: "nodeError.7002",
    defaultMessage: "Caught unknown exception!",
  },

  // 9000 - 9999 qs-client errors
  9001: {
    id: "nodeError.9001",
    defaultMessage: "Unable to establish connection to node - timeout",
  },
  9000: {
    id: "nodeError.9000",
    defaultMessage: "Websocket disconnected",
  },

  // translations
  websocketAuth: {
    id: "nodeMsg.websocketAuth",
    defaultMessage: "Connection to Quickstream Node established",
  },
  changenodePasswordSuccess: {
    id: "nodeMsg.changenodePasswordSuccess",
    defaultMessage: "Password was successfully updated",
  },
  byteAvailRcvBuf: {
    id: "StatisticsView.byteAvailRcvBuf",
    defaultMessage: "Space available in receiver buffer",
  },
  byteAvailSndBuf: {
    id: "StatisticsView.byteAvailSndBuf",
    defaultMessage: "Space available in sender buffer",
  },
  mbpsBandwidth: {
    id: "StatisticsView.mbpsBandwidth",
    defaultMessage: "Link bandwidth",
  },
  mbpsRecvRate: {
    id: "StatisticsView.mbpsRecvRate",
    defaultMessage: "Receive rate",
  },
  mbpsSendRate: {
    id: "StatisticsView.mbpsSendRate",
    defaultMessage: "Send rate",
  },
  msRtt: {
    id: "StatisticsView.msRtt",
    defaultMessage: "RTT [Ping]",
  },
  pktCongestionWindow: {
    id: "StatisticsView.pktCongestionWindow",
    defaultMessage: "Congestation window size",
  },
  pktFlightSize: {
    id: "StatisticsView.pktFlightSize",
    defaultMessage: "Flight size",
  },
  pktFlowWindow: {
    id: "StatisticsView.pktFlowWindow",
    defaultMessage: "Flow window",
  },
  pktRcvBelated: {
    id: "StatisticsView.pktRcvBelated",
    defaultMessage: "Packets received too late",
  },
  pktRcvDrop: {
    id: "StatisticsView.pktRcvDrop",
    defaultMessage: "Dropped received packets",
  },
  pktRcvLoss: {
    id: "StatisticsView.pktRcvLoss",
    defaultMessage: "Received packets loss",
  },
  pktRcvRetrans: {
    id: "StatisticsView.pktRcvRetrans",
    defaultMessage: "Retransmited received packets",
  },
  pktRecv: {
    id: "StatisticsView.pktRecv",
    defaultMessage: "Packets received",
  },
  pktRetrans: {
    id: "StatisticsView.pktRetrans",
    defaultMessage: "Packets retransmited",
  },
  pktSent: {
    id: "StatisticsView.pktSent",
    defaultMessage: "Packets sent",
  },
  pktSndDrop: {
    id: "StatisticsView.pktSndDrop",
    defaultMessage: "Dropped sent packets",
  },
  pktSndLoss: {
    id: "StatisticsView.pktSndLoss",
    defaultMessage: "Sent packets loss",
  },
  time: {
    id: "StatisticsView.time",
    defaultMessage: "Time",
  },
  mbpsMaxbw: {
    id: "StatisticsView.mbpsMaxbw",
    defaultMessage: "Max bandwidth",
  },
  msTimestamp: {
    id: "StatisticsView.msTimestamp",
    defaultMessage: "SRT Connection Alive Time",
  },
  ccErrors: {
    id: "StatisticsView.ccErrors",
    defaultMessage: "CC Errors",
  },
  ccErrorsPerHour: {
    id: "StatisticsView.ccErrorsPerHour",
    defaultMessage: "CC Errors in last hour",
  },
  ccErrorsTotal: {
    id: "StatisticsView.ccErrorsTotal",
    defaultMessage: "CC Errors since startup",
  },
  nodeDisconnected: {
    id: "nodeDisconnected",
    defaultMessage: "Node has been disconnected from Quickstream Cloud",
  }
});
