import React, { useCallback } from "react";
import { Row, Col, Button, Divider, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { CloudSyncOutlined, FieldTimeOutlined } from "@ant-design/icons";

import WebsocketManager from "../../../services/websocket";

import NodeLicenseFingerprint from "./NodeLicenseFingerprint";

const NodeLicenseListCardTitle = ({ fingerprint, cwid }) => {
  const getTrialLicense = useCallback(() => {
    WebsocketManager.sendMessage(JSON.stringify({ cloudMsgType: "sendMessage", to: cwid, command: "getTrialLicense" }));
  }, [cwid]);

  const getLicensesFromCloud = useCallback(() => {
    WebsocketManager.sendMessage(
      JSON.stringify({ cloudMsgType: "sendMessage", to: cwid, command: "forceDownloadLicenses" })
    );
  }, [cwid]);

  return (
    <Row type="flex" justify="space-between" align="middle">
      <Col>
        <FormattedMessage id="NodeLicenseListCardTitle.fingerprint" defaultMessage="Hardware ID" />
        <NodeLicenseFingerprint fingerprint={fingerprint} />
      </Col>
      <Col>
        <Tooltip
          title={<FormattedMessage id="NodeLicenseListCardTitle.trialLicense" defaultMessage="Get trial license" />}
        >
          <Button type="primary" onClick={getTrialLicense} icon={<FieldTimeOutlined />}>
            <span>
              <FormattedMessage id="NodeLicenseListCardTitle.trial" defaultMessage="Trial" />
            </span>
          </Button>
        </Tooltip>
        <Divider type="vertical" />
        <Tooltip
          title={
            <FormattedMessage id="NodeLicenseListCardTitle.cloudLicenses" defaultMessage="Get licenses from Cloud" />
          }
        >
          <Button type="primary" onClick={getLicensesFromCloud} icon={<CloudSyncOutlined />}>
            <span>
              <FormattedMessage id="NodeLicenseListCardTitle.cloud" defaultMessage="Cloud" />
            </span>
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};

NodeLicenseListCardTitle.propTypes = {
  cwid: PropTypes.string.isRequired,
  fingerprint: PropTypes.string.isRequired,
};

export default NodeLicenseListCardTitle;
