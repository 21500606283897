import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const actions = {
  SET_NODES_CHANNELS: createAction("CHANNELS/SET"),
  CHANGE_NODES_CHANNELS_STATUS: createAction("CHANNELS/CHANGE/STATUS"),
  CLEAR_NODES_CHANNELS: createAction("CHANNELS/CLEAR"),
};

const defaultState = new Map({});

const reducer = handleActions(
  {
    [actions.SET_NODES_CHANNELS]: (state, { payload }) => {
      const parsedPayload = payload
        .map((channel) => ({
          [channel.channelId]: channel,
        }))
        .reduce((prev, next) => ({ ...prev, ...next }), {});

      return new Map({ ...parsedPayload });
    },
    [actions.CHANGE_NODES_CHANNELS_STATUS]: (state, { payload, payload: { channelId } }) => {
      const channelExist = state.get(`${channelId}`);
      if (!channelExist) {
        return state;
      }

      return state.update(`${channelId}`, (value) => {
        return {
          ...value,
          status: payload,
        };
      });
    },
    [actions.CLEAR_NODES_CHANNELS]: () => defaultState,
  },
  defaultState
);

export const selectors = {
  getChannels: (state) => state.get("nodeChannels"),
};

export default reducer;
