import { bindActionCreators } from "redux";
import { defineMessages } from "react-intl";

import store, { history } from "../store";
import { actions as LICENSE_ACTIONS } from "../ducks/license";

import { LicenseAPI } from "../lib/api";
import getMessageFromErrorCode from "../lib/utils/getMessageFromErrorCode";

const translations = defineMessages({
  createSuccess: {
    id: "license.createSuccess",
    defaultMessage: "License added",
  },
  deleteSuccess: {
    id: "license.deleteSuccess",
    defaultMessage: "License successfully removed",
  },
  extendLicenseSuccess: {
    id: "license.extendLicenseSuccess",
    defaultMessage: "License succesfully extended",
  },
});

const actions = bindActionCreators(
  {
    createNewLicense: (data, { successNotification, errorNotification }) => async () => {
      try {
        await LicenseAPI.createLicense({ ...data }, { errorNotification });
        successNotification(translations.createSuccess);
        history.goBack();
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
        // eslint-disable-next-line no-console
        console.error("error", error);
      }
    },
    extendLicense: (data, { successNotification, errorNotification }) => async () => {
      try {
        await LicenseAPI.extendLicense({ ...data }, { errorNotification });
        successNotification(translations.extendLicenseSuccess);
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
        // eslint-disable-next-line no-console
        console.error("error", error);
      }
    },

    getLicenseList: ({ errorNotification }) => async () => {
      try {
        const licenseList = await LicenseAPI.getLicense({ errorNotification });
        store.dispatch(LICENSE_ACTIONS.SAVE_LICENSE(licenseList));
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
        // eslint-disable-next-line no-console
        console.error("error", error);
      }
    },
    uniqLicense: (licenseId, { errorNotification }) => async () => {
      try {
        return await LicenseAPI.uniqLicense(licenseId, { errorNotification });
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
        // eslint-disable-next-line no-console
        console.error("error", error);

        return { uniqLicense: false };
      }
    },
    deleteLicense: (licenseId, { errorNotification, successNotification }) => {
      return async () => {
        try {
          await LicenseAPI.deleteLicense(licenseId, { errorNotification });
          successNotification(translations.deleteSuccess);
        } catch (error) {
          const errorMessage = getMessageFromErrorCode(error.status);
          errorNotification(errorMessage);
          // eslint-disable-next-line no-console
          console.error("error", error);
        }
      };
    },
  },

  store.dispatch
);

export default actions;
