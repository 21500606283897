import React, { useState } from "react";
import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";
import { Input, Switch, Row, Select, Form, Col } from "antd";
import styled from "styled-components";

import { required, min10characters, max79characters } from "../../../lib/utils/formValidation";
import globalTranslations from "../../../lib/translations";

const { Item } = Form;
const { Option } = Select;
const { Password } = Input;

const translation = defineMessages({
  passphrase: {
    id: "PassphraseField.passphrase",
    defaultMessage: "Channel encryption",
  },
  keyLengthLabel: {
    id: "PassphraseField.keyLengthLabel",
    defaultMessage: "Key length",
  },
  keyLabel: {
    id: "PassphraseField.keyLabel",
    defaultMessage: "Key",
  },
});

const PassphraseField = ({ prefix, fieldValidation, initialValue }) => {
  const [isEncrypted, setIsEncrypted] = useState(!!initialValue);
  const { formatMessage } = useIntl();

  const fieldRules = isEncrypted ? [required, min10characters, max79characters] : [fieldValidation];

  return (
    <Row justify="space-between" gutter={24}>
      <Col md={24} lg={24}>
        <Item
          name={[...prefix, "channelEncryptionSwitch"]}
          label={formatMessage(translation.passphrase)}
          valuePropName="checked"
        >
          <Switch
            onChange={() => setIsEncrypted(!isEncrypted)}
            defaultChecked={!!initialValue}
            checkedChildren={formatMessage(globalTranslations.enabled)}
            unCheckedChildren={formatMessage(globalTranslations.disabled)}
          />
        </Item>
      </Col>
      {isEncrypted && (
        <>
          <Col md={12} lg={12}>
            <Item name={[...prefix, "passphrase"]} label={formatMessage(translation.keyLabel)} rules={fieldRules}>
              <StyledInput type="password" />
            </Item>
          </Col>
          <Col md={12} lg={12}>
            <Item name={[...prefix, "pbkeylen"]} label={formatMessage(translation.keyLengthLabel)}>
              <Select>
                <Option key={16}>{16}</Option>
                <Option key={24}>{24}</Option>
                <Option key={32}>{32}</Option>
              </Select>
            </Item>
          </Col>
        </>
      )}
    </Row>
  );
};

PassphraseField.propTypes = {
  fieldValidation: PropTypes.object,
  initialValue: PropTypes.string,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

PassphraseField.defaultProps = {
  fieldValidation: {},
  initialValue: null,
};

const StyledInput = styled(Password)`
  &.ant-input {
    width: 70%;
  }
  margin-left: 16px;
`;

export default PassphraseField;
