import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Form, Checkbox } from "antd";
import PropTypes from "prop-types";

import themeColor from "../../lib/style/theme";
import { required } from "../../lib/utils/formValidation";

const { Item } = Form;

const TermsOfUseAndPrivacyPolicy = ({ setTermOfUseAcceptance, termOfUseAcceptance }) => {
  return (
    <>
      <StyledTitle>
        <FormattedMessage
          id="TermsOfUseAndPrivacyPolicy.termOfUse"
          defaultMessage="Terms and Conditions, Privacy Policy"
        />
      </StyledTitle>
      <StyledItem name="termsOfUse" valuePropName="checked" rules={[required]}>
        <Checkbox onChange={() => setTermOfUseAcceptance(!termOfUseAcceptance)}>
          <FormattedMessage id="TermsOfUseAndPrivacyPolicy.iHerebyConsent" defaultMessage="I hereby consent to the" />
          <StyledLink type="link" target="_blank" href="https://quickstream.tech/cloud-terms">
            <FormattedMessage
              id="TermsOfUseAndPrivacyPolicy.termsAndConditions"
              defaultMessage="Terms and Conditions"
            />
          </StyledLink>{" "}
          <FormattedMessage id="general.and" defaultMessage="and" />
          <StyledLink type="link" target="_blank" href="https://quickstream.tech/privacypolicy">
            <FormattedMessage id="TermsOfUseAndPrivacyPolicy.privacyPolicy" defaultMessage="Privacy Policy" />
          </StyledLink>
          {" *."}
        </Checkbox>
      </StyledItem>
      <StyledTips>
        {"* "}
        <FormattedMessage
          id="TermsOfUseAndPrivacyPolicy.obligatory"
          // eslint-disable-next-line max-len
          defaultMessage="Obligatory consent, necessary if you want to use Quickstream Products"
        />
        .
      </StyledTips>
    </>
  );
};

const StyledTips = styled.span`
  font-size: 10px;
  color: ${themeColor.greyTips};
`;

const StyledItem = styled(Item)`
  margin-bottom: 0px;
`;
const StyledTitle = styled.h3`
  color: ${themeColor.greyTitle};
`;

const StyledLink = styled.a`
  margin: 0 0 0 3px;
  color: ${themeColor.orange};

  &:hover {
    color: ${themeColor.orangeHover};
  }
`;

TermsOfUseAndPrivacyPolicy.propTypes = {
  setTermOfUseAcceptance: PropTypes.func.isRequired,
  termOfUseAcceptance: PropTypes.bool.isRequired,
};

export default TermsOfUseAndPrivacyPolicy;
