import React, { useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { InputNumber, Form } from "antd";

const { Item } = Form;

const InputNumberField = ({ name, label, fieldValidation, min }) => {
  const inputEl = useRef(null);

  useLayoutEffect(() => {
    function handleMouseScroll() {
      inputEl.current.blur();
    }
    window.addEventListener("mousewheel", handleMouseScroll);

    return () => window.removeEventListener("mousewheel", handleMouseScroll);
  }, []);

  return (
    <Item name={name} label={label} rules={[...(fieldValidation ? [{ ...fieldValidation }] : [])]}>
      <InputNumber type="number" ref={inputEl} min={min} />
    </Item>
  );
};

InputNumberField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]).isRequired,
  min: PropTypes.number,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  fieldValidation: PropTypes.object,
};

InputNumberField.defaultProps = {
  min: 0,
  fieldValidation: null,
};

export default InputNumberField;
