import React from "react";
import { DeploymentUnitOutlined, RadarChartOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Card, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";

import OwnChannelList from "./OwnChannelList";
import SharedChannelList from "./SharedChannelList";
import CreateChannelModal from "./ChannelModal/CreateChannelModal";
import AllChannelsList from "./AllChannelsList";

const { TabPane } = Tabs;

const ChannelsListContainer = ({ role }) => {
  const isAdmin = role === "admin";

  return (
    <Card
      title={<FormattedMessage id="ChannelsListContainer.channels" defaultMessage="Channels" />}
      extra={<CreateChannelModal />}
    >
      <Tabs>
        <TabPane
          tab={
            <span>
              <DeploymentUnitOutlined />
              <FormattedMessage id="ChannelsListContainer.own" defaultMessage="My shares" />
            </span>
          }
          key="own"
        >
          <OwnChannelList />
        </TabPane>
        <TabPane
          tab={
            <span>
              <ShareAltOutlined />
              <FormattedMessage id="ChannelsListContainer.shared" defaultMessage="Shared with me" />
            </span>
          }
          key="shared"
        >
          <SharedChannelList />
        </TabPane>
        {isAdmin && (
          <TabPane
            tab={
              <span>
                <RadarChartOutlined />
                <FormattedMessage id="ChannelsListContainer.allChannels" defaultMessage="All shares" />
              </span>
            }
            key="all"
          >
            <AllChannelsList />
          </TabPane>
        )}
      </Tabs>
    </Card>
  );
};

ChannelsListContainer.propTypes = {
  role: PropTypes.string,
};

ChannelsListContainer.defaultProps = {
  role: "user",
};

const mapStateToProps = (state) => ({
  role: ACCOUNT_SELECTORS.getRole(state),
});

export default connect(mapStateToProps, null)(ChannelsListContainer);
