import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

import getUserFromJWToken from "../lib/utils/getUserFromJWToken";

export const actions = {
  INITIALIZE: createAction("ACCOUNT/INITIALIZE"),
  SAVE_ACCOUNT_DATA: createAction("ACCOUNT/SAVE_ACCOUNT_DATA"),
  CLEAR_ACCOUNT_DATA: createAction("ACCOUNT/DELETE"),
};

const initialState = new Map({
  username: null,
  token: null,
  loginData: null,
});

const reducer = handleActions(
  {
    [actions.SAVE_ACCOUNT_DATA]: (state, { payload }) => state.merge({ ...payload }),
    [actions.INITIALIZE]: (state, { payload: { token } }) =>
      token ? state.merge({ ...getUserFromJWToken(token) }) : state,
    [actions.CLEAR_ACCOUNT_DATA]: () => initialState,
  },
  initialState
);

export const selectors = {
  getUser: (state) => state.getIn(["account", "username"]),
  getRole: (state) => state.getIn(["account", "role"]),
  getUserEmail: (state) => state.getIn(["account", "email"]),
  getEmailOptIn: (state) => state.getIn(["account", "emailOptIn"]),
};

export default reducer;
