import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Typography } from "antd";

import parseCloudId from "../../../lib/utils/parseCloudId";

const { Text } = Typography;

const ChannelDetailViewCardTitle = ({ channelData }) => (
  <StyledCloudId>
    <FormattedMessage id="ChannelDetailView.channel" defaultMessage="Channel" /> {channelData.channelName}{" "}
    <Text type="warning">({parseCloudId(channelData.cloudId)})</Text>
  </StyledCloudId>
);

const StyledCloudId = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

ChannelDetailViewCardTitle.propTypes = {
  channelData: PropTypes.shape({
    channelName: PropTypes.string.isRequired,
    cloudId: PropTypes.number.isRequired,
  }).isRequired,
};

export default ChannelDetailViewCardTitle;
