import React, { useCallback, useState, useEffect } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Input, Button, Checkbox, Card, Form, Divider, Row, Spin } from "antd";
import styled from "styled-components";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Cookies from "universal-cookie";

import AccountService from "../../services/account";
import WebsocketService from "../../services/websocket";
import { errorNotification } from "../../lib/utils/notification";
import themeColor from "../../lib/style/theme";

import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";

import LoadingReCaptcha from "../LoadingReCaptcha";
import CookieConsent from "../CookieConsent";

import logoUrl from "../../lib/images/logo-cloud-black.png";

const { REACT_APP_RECAPTCHA_V3_PUBLIC_KEY } = process.env;
const { Item } = Form;
const { Password } = Input;
const cookies = new Cookies();

const translations = defineMessages({
  username: {
    id: "login.username",
    defaultMessage: "Username or email address",
  },
  usernameNoInput: {
    id: "login.validationNoInput",
    defaultMessage: "Please input username",
  },
  password: {
    id: "login.password",
    defaultMessage: "Password",
  },
  passwordNoInput: {
    id: "login.validationNotIdentical",
    defaultMessage: "Please input password",
  },
  cookieApprovalNeeded: {
    id: "login.cookieApprovalNeeded",
    defaultMessage: "Please accept cookies, to be able to login to Quickstream Cloud",
  },
});

const LoginForm = ({ loggedUsername }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const [cookieAccepted, setCookieAccepted] = useState(cookies.get("QuickstreamCloudCookieApproval"));

  const checkReCaptcha = useCallback(async () => {
    const reCaptchaResponse = await executeRecaptcha("loginForm");
    setReCaptchaToken(reCaptchaResponse);
  }, [executeRecaptcha]);

  const handleFinish = useCallback(
    async (formData) => {
      const cookieApproved = cookies.get("QuickstreamCloudCookieApproval");
      if (!cookieApproved) {
        errorNotification(formatMessage)(translations.cookieApprovalNeeded);

        return;
      }

      await AccountService.loginUser(
        { ...formData, reCaptchaToken },
        {
          errorNotification: errorNotification(formatMessage),
        }
      );
      WebsocketService.initialize();
      checkReCaptcha();
    },
    [formatMessage, checkReCaptcha, reCaptchaToken]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkReCaptcha();
    }, 200);

    const interval = setInterval(async () => {
      checkReCaptcha();
    }, 120000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [checkReCaptcha]);

  if (loggedUsername) {
    return <Redirect to="/shares" />;
  }

  return (
    <StyledFormContainer>
      <CookieConsent setCookieAccepted={setCookieAccepted} />
      <Spin
        spinning={!reCaptchaToken || !cookieAccepted}
        tip={
          !reCaptchaToken ? (
            <LoadingReCaptcha checkReCaptcha={checkReCaptcha} />
          ) : (
            <FormattedMessage
              id="LoginForm.pleaseAcceptCookies"
              defaultMessage="Please accept cookies privacy policy below"
            />
          )
        }
      >
        <Card
          title={<FormattedMessage id="login.login" defaultMessage="Login" />}
          extra={<img src={logoUrl} alt="quickstream logo" width="200" />}
        >
          <StyledForm onFinish={handleFinish} className="login-form" form={form} initialValues={{ remember: true }}>
            <Item name="username" rules={[{ required: true, message: formatMessage(translations.usernameNoInput) }]}>
              <Input
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder={formatMessage(translations.username)}
              />
            </Item>
            <Item name="password" rules={[{ required: true, message: formatMessage(translations.passwordNoInput) }]}>
              <Password
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder={formatMessage(translations.password)}
              />
            </Item>
            <Item name="remember" valuePropName="checked">
              <>
                <Checkbox>
                  <FormattedMessage id="login.rememberMe" defaultMessage="Remember me" />
                </Checkbox>
                <StyledButton type="primary" htmlType="submit" className="login-form-button">
                  <FormattedMessage id="login.loginButton" defaultMessage="Log in" />
                </StyledButton>
                <Row type="flex" justify="center" align="middle">
                  <StyledLink to="/password/forgot">
                    <StyledOrangeURL>
                      <FormattedMessage id="login.forgotPassword" defaultMessage="Forgot password?" />
                    </StyledOrangeURL>
                  </StyledLink>
                </Row>
                <Divider />
                <Row type="flex" justify="center" align="middle">
                  <h4>
                    <FormattedMessage id="login.dontHaveAccount" defaultMessage="Don't have an account?" />
                  </h4>
                </Row>
                <Row type="flex" justify="center" align="middle">
                  <Link to="/register">
                    <StyledOrangeURL>
                      <FormattedMessage id="login.registerNow" defaultMessage="Register now!" />
                    </StyledOrangeURL>
                  </Link>
                </Row>
              </>
            </Item>
          </StyledForm>
        </Card>
      </Spin>
    </StyledFormContainer>
  );
};

const StyledLink = styled(Link)`
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
  margin: 20px 0 0 0;
`;

const StyledForm = styled(Form)`
  min-width: 400px;
`;

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const StyledOrangeURL = styled.span`
  color: ${themeColor.orange};
  transition: color 0.2s ease;

  &:hover {
    color: ${themeColor.orangeHover};
  }
`;

LoginForm.propTypes = {
  loggedUsername: PropTypes.string,
};

LoginForm.defaultProps = {
  loggedUsername: null,
};

const mapStateToProps = (state) => ({
  loggedUsername: ACCOUNT_SELECTORS.getUser(state),
});

const LoginFormContainer = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_V3_PUBLIC_KEY}>
      <LoginForm />
    </GoogleReCaptchaProvider>
  );
};

export default connect(mapStateToProps, null)(LoginFormContainer);
