import { combineReducers } from "redux-immutable";
import account from "./account";
import admin from "./admin";
import cloudConnection from "./cloudConnection";
import cloudChannels from "./cloudChannels";
import lang from "./lang";
import license from "./license";
import navigation from "./navigation";
import nodeChannels from "./nodeChannels";
import loadingData from "./loadingData";
import node from "./node";
import logs from "./logs";
import nodeStatistics from "./nodeStatistics";
import nodeSystemStatistics from "./nodeSystemStatistics";
import nodeLicense from "./nodeLicense";

const rootReducer = combineReducers({
  account,
  admin,
  cloudChannels,
  cloudConnection,
  lang,
  license,
  loadingData,
  logs,
  navigation,
  node,
  nodeChannels,
  nodeLicense,
  nodeStatistics,
  nodeSystemStatistics,
});

export default rootReducer;
