import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Menu } from "antd";
import { FormattedMessage } from "react-intl";
import { ForkOutlined } from "@ant-design/icons";
import styled from "styled-components";

import store, { history } from "../../store";

import WebsocketService from "../../services/websocket";
import { selectors as CLOUD_CONNECTION_SELECTORS } from "../../ducks/cloudConnection";
import { actions as LOADING_ACTIONS } from "../../ducks/loadingData";
import { actions as NODE_CHANNELS_ACTIONS } from "../../ducks/nodeChannels";
import { selectors as NODE_SELECTORS } from "../../ducks/node";
import { CLEAR_HIST_STATISTIC } from "../../ducks/nodeStatistics";
import { actions as NODE_LICENSE_ACTIONS } from "../../ducks/nodeLicense";
import { DELETE_LOGS } from "../../ducks/logs";
import { actions as NAVIGATION_ACTIONS, selectors as NAVIGATION_SELECTORS } from "../../ducks/navigation";

import { WEBSOCKET_STATUS, DEFAULT_CLIENT_VERSION } from "../../lib/utils/constants";
import checkClientVersion from "../../lib/utils/checkClientVersion";

import NodesListTitle from "./NodesListTitle";

const { SubMenu, Item } = Menu;

const NodesList = ({
  activeKey,
  nodes,
  websocketStatus,
  websocketConnection,
  connectedNodeCnn,
  connectedNodeCwid,
  ...rest
}) => {
  const isConnection = websocketConnection && websocketStatus === WEBSOCKET_STATUS.connected;
  const { computedMatch, staticContext, dispatch, ...filteredRestProps } = rest;

  const fetchNodes = () => {
    WebsocketService.sendMessage(JSON.stringify({ cloudMsgType: "getNodes" }));
    store.dispatch(LOADING_ACTIONS.SET_LOADING("getNodes"));
  };

  useEffect(() => {
    if (isConnection) {
      fetchNodes();
    }
  }, [isConnection]);

  const handleChannels = ({ cnn, cwid, navElement, nodeVersion }) => {
    checkClientVersion({ nodeVersion, url: `/cloudNodes/${cwid}/${cnn}/channels` });

    if (!activeKey.includes(navElement.key)) {
      store.dispatch(NAVIGATION_ACTIONS.SET_CHANNELS_ACTIVE_TAB({ channelsActiveTab: "channelList" }));
      store.dispatch(NAVIGATION_ACTIONS.SET_ACTIVE_KEY({ activeKey: [navElement.key] }));
      store.dispatch(DELETE_LOGS());
      store.dispatch(CLEAR_HIST_STATISTIC());
      store.dispatch(NODE_CHANNELS_ACTIONS.CLEAR_NODES_CHANNELS());
      history.push(`/cloudNodes/${cwid}/${cnn}/channels`);
    }
  };

  const handleLicenses = ({ cnn, cwid, navElement, nodeVersion }) => {
    checkClientVersion({ nodeVersion, url: `/cloudNodes/${cwid}/${cnn}/licenses` });

    store.dispatch(NAVIGATION_ACTIONS.SET_ACTIVE_KEY({ activeKey: [navElement.key] }));
    store.dispatch(NODE_LICENSE_ACTIONS.CLEAR_NODE_LICENSE_LIST());
    history.push(`/cloudNodes/${cwid}/${cnn}/licenses`);
  };

  const handleSystem = ({ navElement, cnn, cwid, nodeVersion }) => {
    checkClientVersion({ nodeVersion, url: `/cloudNodes/${cwid}/${cnn}/system` });

    store.dispatch(NAVIGATION_ACTIONS.SET_ACTIVE_KEY({ activeKey: [navElement.key] }));
    history.push(`/cloudNodes/${cwid}/${cnn}/system`);
  };

  return (
    <SubMenu
      title={
        <>
          <ForkOutlined />
          <span>
            <FormattedMessage id="NodesList.cloudNodes" defaultMessage="Nodes" />
          </span>
        </>
      }
      {...filteredRestProps}
    >
      {nodes.map(({ cnn, cwid, status, nodeVersion }) => {
        const parsedNodeVersion = (nodeVersion && nodeVersion.split(".")[0].replace("v", "")) || DEFAULT_CLIENT_VERSION;

        const changeClientVersion = checkClientVersion({ nodeVersion: parsedNodeVersion });

        return (
          <StyledSubMenu
            key={`/cloudNodes/${cwid}`}
            disabled={status !== WEBSOCKET_STATUS.connected || changeClientVersion}
            title={<NodesListTitle status={status} cwid={cwid} cnn={cnn} nodeVersion={parsedNodeVersion} />}
            {...filteredRestProps}
          >
            <Item
              key={`/cloudNodes/${cwid}/${cnn}/channels`}
              title={<FormattedMessage id="NodesList.channels" defaultMessage="Channels" />}
              onClick={(navElement) => handleChannels({ cnn, cwid, navElement, nodeVersion: parsedNodeVersion })}
            >
              <FormattedMessage id="NodesList.channels" defaultMessage="Channels" />
            </Item>
            <Item
              key={`/cloudNodes/${cwid}/${cnn}/licenses`}
              title={<FormattedMessage id="NodesList.licenses" defaultMessage="Licenses" />}
              onClick={(navElement) => handleLicenses({ cnn, cwid, navElement, nodeVersion: parsedNodeVersion })}
            >
              <FormattedMessage id="NodesList.Licenses" defaultMessage="Licenses" />
            </Item>
            <Item
              key={`/cloudNodes/${cwid}/${cnn}/system`}
              title={<FormattedMessage id="NodesList.system" defaultMessage="System" />}
              onClick={(navElement) => handleSystem({ cnn, cwid, navElement, nodeVersion: parsedNodeVersion })}
            >
              <FormattedMessage id="NodesList.system" defaultMessage="System" />
            </Item>
          </StyledSubMenu>
        );
      })}
    </SubMenu>
  );
};

const StyledSubMenu = styled(SubMenu)`
  .ant-menu-submenu-title {
    padding-left: 40px !important;
  }
`;

NodesList.propTypes = {
  activeKey: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  connectedNodeCwid: PropTypes.string,
  connectedNodeCnn: PropTypes.string,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      cnn: PropTypes.string.isRequired,
      cwid: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
  websocketConnection: PropTypes.bool,
  websocketStatus: PropTypes.string,
};

NodesList.defaultProps = {
  activeKey: null,
  connectedNodeCwid: null,
  connectedNodeCnn: null,
  websocketStatus: null,
  websocketConnection: null,
};

const mapStateToProps = (state) => ({
  activeKey: NAVIGATION_SELECTORS.getActiveNavKey(state),
  nodes: CLOUD_CONNECTION_SELECTORS.getNodes(state),
  websocketStatus: CLOUD_CONNECTION_SELECTORS.getStatus(state),
  websocketConnection: CLOUD_CONNECTION_SELECTORS.getWebsocketConnection(state),
  connectedNodeCnn: NODE_SELECTORS.getCnn(state),
  connectedNodeCwid: NODE_SELECTORS.getNodeCwid(state),
});

export default connect(mapStateToProps, null)(NodesList);
