import React, { useEffect } from "react";
import { Spin } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import AccountService from "../../services/account";
import { errorNotification } from "../../lib/utils/notification";

const Auth2FA = ({
  match: {
    params: { token },
  },
}) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    AccountService.auth2faLogin({ token2fa: token }, { errorNotification: errorNotification(formatMessage) });
  }, [token, formatMessage]);

  return (
    <StyledSpin
      tip={<FormattedMessage id="Auth2FA.pleaseWait" defaultMessage="Please wait for login to Quickstream Cloud..." />}
      type="info"
    />
  );
};

const StyledSpin = styled(Spin)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

Auth2FA.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Auth2FA;
