import React from "react";
import { LockOutlined } from "@ant-design/icons";
import { Input, Button, Form } from "antd";
import styled from "styled-components";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import AccountService from "../../services/account";
import { errorNotification, successNotification } from "../../lib/utils/notification";

const { Item } = Form;
const { Password } = Input;

const translations = defineMessages({
  confirmPassword: {
    id: "editPasswordForm.confirmPassword",
    defaultMessage: "Please input confirmation password",
  },
  confirmSameAsPassword: {
    id: "editPasswordForm.confirmSameAsPassword",
    defaultMessage: "Confirmation password need to be the same as password!",
  },
  requiredOldPassword: {
    id: "editPasswordForm.requiredOldPassword",
    defaultMessage: "Please input your old password!",
  },
  requiredPassword: {
    id: "editPasswordForm.requiredPassword",
    defaultMessage: "Please input your password!",
  },
  passwordMinLength: {
    id: "editPasswordForm.passwordMinLength",
    defaultMessage: "Password must be at least 8 characters long",
  },
  confirmPasswordPlaceholder: {
    id: "editPasswordForm.confirmPasswordPlaceholder",
    defaultMessage: "Confirm new password",
  },
  newPasswordPlaceholder: {
    id: "editPasswordForm.newPasswordPlaceholder",
    defaultMessage: "New password",
  },
  oldPasswordPlaceholder: {
    id: "editPasswordForm.oldPasswordPlaceholder",
    defaultMessage: "Old password",
  },
});

const EditPasswordForm = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { getFieldValue, resetFields } = form;

  const handleFinish = (formData) => {
    AccountService.editPassword(formData, {
      errorNotification: errorNotification(formatMessage),
      successNotification: successNotification(formatMessage),
    });
    resetFields();
  };

  const validatePassword = (rule, value) => {
    const passwordValue = getFieldValue("newPassword");
    if (!value) {
      return Promise.reject(formatMessage(translations.confirmPassword));
    }
    if (value !== passwordValue) {
      return Promise.reject(formatMessage(translations.confirmSameAsPassword));
    }

    return Promise.resolve();
  };

  return (
    <Form onFinish={handleFinish} form={form}>
      <Item
        name="oldPassword"
        rules={[
          { required: true, message: formatMessage(translations.requiredOldPassword) },
          { min: 8, message: formatMessage(translations.passwordMinLength) },
        ]}
      >
        <Password
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder={formatMessage(translations.oldPasswordPlaceholder)}
        />
      </Item>
      <Item
        name="newPassword"
        rules={[
          { required: true, message: formatMessage(translations.requiredPassword) },
          { min: 8, message: formatMessage(translations.passwordMinLength) },
        ]}
      >
        <Password
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder={formatMessage(translations.newPasswordPlaceholder)}
        />
      </Item>
      <Item name="confirmPassword" rules={[{ validator: validatePassword }]}>
        <Password
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder={formatMessage(translations.confirmPasswordPlaceholder)}
        />
      </Item>
      <StyledButton>
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="editPasswordForm.save" defaultMessage="Save" />
        </Button>
      </StyledButton>
    </Form>
  );
};

const StyledButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default EditPasswordForm;
