import * as React from "react";
import { Col } from "antd";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import globalTranslations from "../../../lib/translations";
import { CONNECTION_METHOD } from "../../../lib/utils/constants";

import { IPField, PortField } from "../../../components/Fields/Fields";
import InputNumberField from "../../../components/Fields/InputNumberField";
import NetworkField from "../../../components/Fields/NetworkField";
import PassphraseField from "../../../components/Fields/PassphraseField";

import OverheadBandwidthLabel from "../Labels/OverheadBandwidthLabel";
import LatencyLabel from "../Labels/LatencyLabel";

import { required } from "../../../lib/utils/formValidation";

const OutputStreamSrtSection = ({ prefix, connectionMethod, initialValue }) => {
  const { formatMessage } = useIntl();

  const isListenerMode = connectionMethod === CONNECTION_METHOD.srtListener.value;
  const isCallerMode = connectionMethod === CONNECTION_METHOD.srtCaller.value;
  const isRendezvousMode = connectionMethod === CONNECTION_METHOD.srtRendezvous.value;

  return (
    <>
      {!isListenerMode && (
        <Col md={24} lg={8}>
          <IPField label={formatMessage(globalTranslations.adressIp)} name={[...prefix, "dstIp"]} />
        </Col>
      )}
      <Col md={24} lg={isListenerMode ? 12 : 4}>
        <PortField name={[...prefix, "dstPort"]} />
      </Col>
      {!isCallerMode && (
        <Col md={24} lg={12}>
          <NetworkField name={[...prefix, "interfaceIp"]} requiredField={!isRendezvousMode} />
        </Col>
      )}
      <Col md={24} lg={12}>
        <InputNumberField
          name={[...prefix, "ffmpegStreamProperties", "oheadbw"]}
          label={OverheadBandwidthLabel()}
          fieldValidation={required}
        />
      </Col>
      <Col md={24} lg={12}>
        <InputNumberField
          name={[...prefix, "ffmpegStreamProperties", "peerlatency"]}
          label={LatencyLabel("output")}
          fieldValidation={required}
        />
      </Col>
      <Col md={24} lg={24}>
        <PassphraseField
          prefix={[...prefix, "ffmpegStreamProperties"]}
          initialValue={initialValue?.ffmpegStreamProperties?.passphrase}
        />
      </Col>
    </>
  );
};

OutputStreamSrtSection.propTypes = {
  initialValue: PropTypes.shape({
    connectionMethod: PropTypes.string,
    dstPort: PropTypes.number,
    ffmpegStreamProperties: PropTypes.shape({
      connectTimeout: PropTypes.number,
      mode: PropTypes.string,
      oheadbw: PropTypes.number,
      peerlatency: PropTypes.number,
    }),
    interfaceIp: PropTypes.string,
  }),
  connectionMethod: PropTypes.string.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

OutputStreamSrtSection.defaultProps = {
  initialValue: null,
};

export default OutputStreamSrtSection;
