import { blue, green, orange, red, yellow, cyan, grey } from "@ant-design/colors";

export default {
  defaultBlueColor: blue[6],
  activeGreen: green[0],
  activeBlue: blue[0],
  linkBlue: blue[4],
  orange: orange[5],
  orangeHover: orange[6],
  green: green[6],
  greenHover: green[5],
  logCritical: red[0],
  darkRed: red[6],
  logError: orange[0],
  logWarning: yellow[0],
  yellowLink: "#FA8C16",
  yellowLinkHover: orange[6],
  logDebug: cyan[0],
  logInfo: blue[0],
  greyTitle: grey[6],
  greyTips: grey[0],
  orangeBackground: orange[0],
};
