import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tabs, Empty } from "antd";
import { FormattedMessage } from "react-intl";
import ImmutablePropTypes from "react-immutable-proptypes";

import WebsocketManager from "../../../services/websocket";
import store from "../../../store";

import { selectors as STATISTIC_SELECTORS } from "../../../ducks/nodeStatistics";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../../ducks/cloudChannels";
import { actions as LOADING_ACTIONS } from "../../../ducks/loadingData";
import { selectors as CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";
import { selectors as NODE_SELECTORS } from "../../../ducks/node";

import StatisticHistoricalGraph from "./StatisticHistoricalGraph";

import { CHANNEL_SOURCE } from "../../../lib/utils/constants";
import parseConnectionMethodName from "../../../lib/utils/parseConnectionMethodName";

const { TabPane } = Tabs;

const StatisticHistoricalOutput = ({
  channels,
  channelId,
  cloudId,
  permissionIds,
  permissions,
  scale,
  setScale,
  cwid,
}) => {
  const [activeKey, setActiveKey] = useState(permissionIds && permissionIds[0]);
  const dataAvailable = permissionIds.length !== 0;

  useEffect(() => {
    if (permissionIds) {
      setActiveKey(permissionIds[0]);
    }
  }, [permissionIds]);

  useEffect(() => {
    const message = {
      channelId,
      cloudId,
      scale,
    };

    if (dataAvailable) {
      WebsocketManager.sendMessage(
        JSON.stringify({
          ...message,
          cloudMsgType: "sendMessage",
          command: "getStatsOutMin",
          permissionId: activeKey,
          to: cwid,
        })
      );
      store.dispatch(LOADING_ACTIONS.SET_LOADING("getStatsOutMin"));
    }
  }, [channelId, activeKey, cloudId, cwid, dataAvailable, scale]);

  const permissionsData = permissions[channelId];
  const permissionName = (id) => {
    if (id === +CHANNEL_SOURCE.wrapperChannel) {
      const outputConnectionMethod = channels.getIn([channelId, "config", "nonMuxedOutputs", 0, "connectionType"]);

      return `${parseConnectionMethodName(outputConnectionMethod)} Output`;
    }

    const permissionData = permissionsData && permissionsData.find((permission) => permission.id === id);
    if (permissionData) {
      return `${permissionData.description} (${permissionData.username})`;
    }

    return `Share removed id: ${id}`;
  };

  return (
    <>
      {!dataAvailable && (
        <Empty
          description={
            <FormattedMessage
              id="StatisticHistoricalOutput.noData"
              defaultMessage="There is no historical data available"
            />
          }
        />
      )}
      {dataAvailable && (
        <Tabs onChange={(id) => setActiveKey(+id)}>
          {permissionIds.map((permissionId) => {
            return (
              <TabPane tab={permissionName(permissionId)} key={permissionId}>
                {permissionId === activeKey && (
                  <StatisticHistoricalGraph
                    channelId={channelId}
                    cloudId={cloudId}
                    scale={scale}
                    setScale={setScale}
                    output
                    permissionId={permissionId}
                  />
                )}
              </TabPane>
            );
          })}
        </Tabs>
      )}
    </>
  );
};

StatisticHistoricalOutput.propTypes = {
  channels: ImmutablePropTypes.map.isRequired,
  channelId: PropTypes.string.isRequired,
  cloudId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  cwid: PropTypes.string,
  permissionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  permissions: PropTypes.object,
  scale: PropTypes.number.isRequired,
  setScale: PropTypes.func.isRequired,
};

StatisticHistoricalOutput.defaultProps = {
  cwid: null,
  permissions: {},
};

const mapStateToProps = (state) => ({
  cwid: NODE_SELECTORS.getNodeCwid(state),
  channels: CHANNEL_SELECTORS.getChannels(state),
  permissionIds: STATISTIC_SELECTORS.getStatisticPermissionIds(state),
  permissions: CLOUD_CHANNELS_SELECTORS.getChannelPermissions(state),
});

export default connect(mapStateToProps, null)(StatisticHistoricalOutput);
