import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Divider, Empty, Tabs, Card, Spin, Skeleton, Row, Col, Button, Popconfirm } from "antd";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import ImmutablePropTypes from "react-immutable-proptypes";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import CloudChannelsServices from "../../../services/cloudChannels";
import NODE_CHANNELS_SERVICES from "../../../services/node";
import WebsocketManager from "../../../services/websocket";
import { selectors as STATISTICS_SELECTORS } from "../../../ducks/nodeStatistics";
import { selectors as CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";
import { selectors as ACCOUNT_SELECTORS } from "../../../ducks/account";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../../ducks/cloudChannels";

import SelectEngine from "../../../components/SelectEngine";
import StatisticsView from "./StatisticsView";
import StatisticsGraph from "./StatisticsGraph";

import StatisticOutput from "./StatisticOutput";

import { errorNotification } from "../../../lib/utils/notification";
import { CHANNEL_SOURCE } from "../../../lib/utils/constants";
import globalTranslation from "../../../lib/translations";
import parseConnectionMethodName from "../../../lib/utils/parseConnectionMethodName";

const { TabPane } = Tabs;

const Statistics = ({ channels, channelId, loggedToCloud, permissions, statistics, setChannelId, cwid }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (loggedToCloud) {
      CloudChannelsServices.getOwnChannels({ errorNotification: errorNotification(formatMessage) });
      CloudChannelsServices.getSharedChannels({ errorNotification: errorNotification(formatMessage) });
    }
  }, [loggedToCloud, formatMessage]);

  const [...channelIds] = channels && channels.keys();
  const sortedChannelsIds = channelIds.sort((a, b) => a - b);
  const statisticExist = statistics && channelId in statistics;
  const statisticsData = statisticExist && statistics[channelId];

  const statisticsInput = statisticExist && statisticsData.map((stat) => stat.input);
  const newestChannelInStats = statisticsData && statisticsData[statisticsData.length - 1].input;
  const outputStatExist = statisticsData && statisticsData[statisticsData.length - 1].output;

  const statisticsOutputArray = statisticExist && outputStatExist && statisticsData.map((stat) => stat.output);

  const clearLiveStats = () => {
    NODE_CHANNELS_SERVICES.clearStatistic({ channelId, from: cwid });
    WebsocketManager.sendMessage(
      JSON.stringify({ cloudMsgType: "sendMessage", command: "resetLiveStats", channelId, to: cwid })
    );
  };

  const parsedStatOutput =
    statisticsOutputArray &&
    statisticsOutputArray.map((statOutputData) => {
      const parsedStatOutputData = statOutputData.map((statData) => {
        if (statData.permissionId === CHANNEL_SOURCE.wrapperChannel) {
          const channelName = channels.getIn([channelId, "config", "name"]);
          const outputConnectionMethod = channels.getIn([channelId, "config", "nonMuxedOutputs", 0, "connectionType"]);

          return { ...statData, channelName, username: `${parseConnectionMethodName(outputConnectionMethod)} Output` };
        }
        const permissionDataExist = !!permissions[statData.channelId];

        if (permissionDataExist) {
          const permissionDescriptionArray = permissions[statData.channelId];
          const descriptionData = permissionDescriptionArray.find(
            (permission) => +permission.id === +statData.permissionId
          );

          return { ...statData, channelName: descriptionData?.description, username: descriptionData?.username };
        }

        return { ...statData, channelName: `permissionId: ${statData.permissionId}` };
      });

      return parsedStatOutputData;
    });

  const handleSelectChange = (selectedEngineId) => {
    setChannelId(selectedEngineId);
  };

  const isReady = !!permissions;

  if (!isReady) {
    return (
      <Spin tip={<FormattedMessage id="general.loading" defaultMessage="Loading..." />}>
        <Skeleton active />
      </Spin>
    );
  }

  return (
    <Card>
      <Row justify="space-between">
        <Col>
          <StyledRightMargin>
            <FormattedMessage id="Statistics.channel" defaultMessage="Channel" />
            {": "}
          </StyledRightMargin>
          <SelectEngine
            channels={channels}
            channelId={channelId}
            channelIds={sortedChannelsIds}
            handleSelectChange={handleSelectChange}
          />
        </Col>
        <Col>
          <Popconfirm
            placement="top"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            title={
              <FormattedMessage
                id="Statistics.removeQuestion"
                defaultMessage="Are you sure that you want to clear all live data for this channel?"
              />
            }
            onConfirm={clearLiveStats}
            okText={formatMessage(globalTranslation.yes)}
            cancelText={formatMessage(globalTranslation.no)}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              <span>
                <FormattedMessage id="Statistics.clearLiveStats" defaultMessage="Clear live data" />
              </span>
            </Button>
          </Popconfirm>
        </Col>
      </Row>
      <Divider />
      <Tabs defaultActiveKey="input">
        <TabPane tab={<FormattedMessage id="Statistics.input" defaultMessage="Input" />} key="input">
          {newestChannelInStats && <StatisticsGraph data={statisticsInput} />}
          {newestChannelInStats && <StatisticsView data={newestChannelInStats} />}
          {!newestChannelInStats && (
            <Empty
              description={
                <FormattedMessage id="Statistics.waitingForStatistics" defaultMessage="Waiting for statistics..." />
              }
            />
          )}
        </TabPane>
        <TabPane tab={<FormattedMessage id="Statistics.output" defaultMessage="Output" />} key="output">
          {outputStatExist && <StatisticOutput statistics={parsedStatOutput} loggedToCloud={!!loggedToCloud} />}
          {!outputStatExist && (
            <Empty
              description={
                <FormattedMessage id="Statistics.waitingForStatistics" defaultMessage="Waiting for statistics..." />
              }
            />
          )}
        </TabPane>
      </Tabs>
    </Card>
  );
};

const StyledRightMargin = styled.span`
  margin-right: 5px;
`;

Statistics.propTypes = {
  cwid: PropTypes.string.isRequired,
  channels: ImmutablePropTypes.map.isRequired,
  channelId: PropTypes.string.isRequired,
  loggedToCloud: PropTypes.string,
  permissions: PropTypes.object,
  statistics: PropTypes.oneOfType([
    PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          ccErrors: PropTypes.string.isRequired,
          ccErrorsPerHour: PropTypes.string.isRequired,
          ccErrorsTotal: PropTypes.string.isRequired,
          byteAvailRcvBuf: PropTypes.string.isRequired,
          byteAvailSndBuf: PropTypes.string.isRequired,
          channelId: PropTypes.number.isRequired,
          mbpsBandwidth: PropTypes.string.isRequired,
          mbpsRecvRate: PropTypes.string.isRequired,
          mbpsSendRate: PropTypes.string.isRequired,
          msRtt: PropTypes.string.isRequired,
          pktCongestionWindow: PropTypes.string.isRequired,
          pktFlightSize: PropTypes.string.isRequired,
          pktFlowWindow: PropTypes.string.isRequired,
          pktRcvAvgBelatedTime: PropTypes.string.isRequired,
          pktRcvBelated: PropTypes.string.isRequired,
          pktRcvDrop: PropTypes.string.isRequired,
          pktRcvLoss: PropTypes.string.isRequired,
          pktRcvRetrans: PropTypes.string.isRequired,
          pktRecv: PropTypes.string.isRequired,
          pktReorderDistance: PropTypes.string.isRequired,
          pktRetrans: PropTypes.string.isRequired,
          pktSent: PropTypes.string.isRequired,
          pktSndDrop: PropTypes.string.isRequired,
          pktSndLoss: PropTypes.string.isRequired,
          time: PropTypes.string.isRequired,
        })
      )
    ),
    PropTypes.object,
  ]),
  setChannelId: PropTypes.func.isRequired,
};

Statistics.defaultProps = {
  loggedToCloud: null,
  permissions: {},
  statistics: {},
};

const mapStateToProps = (state, ownProps) => ({
  channels: CHANNEL_SELECTORS.getChannels(state),
  loggedToCloud: ACCOUNT_SELECTORS.getUser(state),
  statistics: STATISTICS_SELECTORS.getStatistics({ state, ownProps }),
  ownChannelsList: CLOUD_CHANNELS_SELECTORS.getOwnChannels(state),
  permissions: CLOUD_CHANNELS_SELECTORS.getChannelPermissions(state),
});

export default connect(mapStateToProps, null)(Statistics);
