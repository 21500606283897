import React from "react";
import { useMountEffect } from "@reonomy/reactive-hooks";
import { Card, Table } from "antd";
import { useIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import CloudChannelsServices from "../../services/cloudChannels";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../ducks/cloudChannels";

import { errorNotification } from "../../lib/utils/notification";
import parseCloudId from "../../lib/utils/parseCloudId";

const translations = defineMessages({
  id: {
    id: "SharedChannelList.id",
    defaultMessage: "ID",
  },
  owner: {
    id: "SharedChannelList.owner",
    defaultMessage: "Owner",
  },
  sharedAs: {
    id: "SharedChannelList.sharedAs",
    defaultMessage: "Shared as",
  },
});

const SharedChannelList = ({ sharedChannelsList }) => {
  const { formatMessage } = useIntl();

  async function fetchChannels() {
    CloudChannelsServices.getSharedChannels({ errorNotification: errorNotification(formatMessage) });
  }

  useMountEffect(() => {
    fetchChannels();
  });

  const columns = [
    {
      key: "description",
      dataIndex: "description",
      title: formatMessage(translations.sharedAs),
      sorter: (currentValue, nextValue) => currentValue.channelName.localeCompare(nextValue.channelName),
    },
    {
      key: "cloudId",
      dataIndex: "cloudId",
      title: formatMessage(translations.id),
      sorter: (currentValue, nextValue) => currentValue.cloudId - nextValue.cloudId,
      render: (cloudId) => {
        return parseCloudId(cloudId);
      },
    },
    {
      key: "id",
      dataIndex: "userId",
      title: formatMessage(translations.owner),
      sorter: (currentValue, nextValue) => currentValue.userId.localeCompare(nextValue.userId),
    },
  ];

  return (
    <Card>
      <Table columns={columns} dataSource={sharedChannelsList} rowKey={(record) => record.id} />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  sharedChannelsList: CLOUD_CHANNELS_SELECTORS.getSharedChannels(state),
});

SharedChannelList.propTypes = {
  sharedChannelsList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cloudId: PropTypes.number.isRequired,
        channelName: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        isEncrypted: PropTypes.bool.isRequired,
        isPublic: PropTypes.bool.isRequired,
        userId: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
};

export default connect(mapStateToProps, null)(SharedChannelList);
