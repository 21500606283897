export const MESSAGE_TYPE = {
  CHANNEL_STATUS: "channelStatus",
  CLIENT_AUTHORIZATION: "clientAuth",
  ERROR: "error",
  FINGERPRINT: "getFingerprint",
  GET_HOST_NAME: "getHostname",
  GET_LOGS: "getLogs",
  GET_NODES: "getNodes",
  LOG_LEVEL: "logLevel",
  NETWORK_INTERFACES: "getInterfaces",
  NEW_NODE_CHANNEL_LOG: "log",
  NODE_CHANNEL_STATISTIC: "statistic",
  NODE_LICENSE: "updateLicenses",
  NODE_STATS: "nodeStats",
  NODE_UPDATE_STATUS: "nodeUpdateStatus",
  SET_HIST_STATS_IN_MIN: "statsInMin",
  SET_HIST_STATS_IN: "statsIn",
  SET_HIST_STATS_OUT_INIT: "statsOutInit",
  SET_HIST_STATS_OUT_MIN: "statsOutMin",
  SET_HIST_STATS_OUT: "statsOut",
  SET_HOSTNAME: "setHostname",
  SHORT_FINGERPRINT: "getShortFingerprint",
  STUND_ADDRESS: "getStunAddr",
  SUCCESS: "success",
  UPDATE_CHANNELS: "updateChannels",
  UPDATE_LOGS: "updateLogs",
  WARNING: "warning",
};

export const PERMANENT_LICENSE = "never";
export const NODE = "Node";
export const NODES_URL = "cloudNodes";
export const RECAPTCHA_ERROR = 406;

export const NODE_STATS_TYPE = {
  incoming: "incoming",
  outgoing: "outgoing",
};

export const NODE_CHANEL_TABS = {
  channelList: "channelList",
  logs: "logs",
  statistics: "statistics",
};

export const WEBSOCKET_STATUS = {
  connecting: "connecting",
  connected: "connected",
  disconnected: "disconnected",
};

export const QUICKSTREAM = "quickstream";

export const QUICKSTREAM_METHOD = {
  value: "quickstream",
  label: "Quickstream",
};

export const LOADING_DATA_TYPE = {
  wrapperLicense: "wrapperLicenseList",
  networkInterfaces: "getInterfaces",
  logFileList: "getLogFilesList",
  channelList: "wrapperChannelList",
  logLevel: "getLogLevel",
  wrapperLicenseList: "wrapperLicenseList",
  getHostname: "getHostname",
};

export const NODE_CHANNEL_STATE = {
  CONNECTED: "CONNECTED",
  CONNECTING: "CONNECTING",
  ERROR: "ERROR",
  EMPTY: "EMPTY",
  STOPPED: "STOPPED",
  RECONNECTING: "RECONNECTING",
  PAUSED: "PAUSED",
  PROCESS_DIED: "PROCESS_DIED",
  UNCONFIGURED: "UNCONFIGURED",
};

export const CHANNEL_STATE = {
  CONNECTED: "CONNECTED",
  CONNECTING: "CONNECTING",
  ERROR: "ERROR",
  EMPTY: "EMPTY",
  STOPPED: "STOPPED",
  RECONNECTING: "RECONNECTING",
  PAUSED: "PAUSED",
  PROCESS_DIED: "PROCESS_DIED",
  UNCONFIGURED: "UNCONFIGURED",
};

export const AUTO_IP = "automatic";

export const DEFAULT_CLIENT_VERSION = "2";

export const CONNECTION_METHOD = {
  srtListener: {
    value: "srtListener",
    label: "SRT Listener",
  },
  srtCaller: {
    value: "srtCaller",
    label: "SRT Caller",
  },
  srtRendezvous: {
    value: "srtRendezvous",
    label: "SRT Rendezvous",
  },
  udpUnicast: {
    value: "udpUnicast",
    label: "UDP Unicast",
  },
  udpMulticast: {
    value: "udpMulticast",
    label: "UDP Multicast",
  },
};

export const UDP_OUTPUT_METHOD = {
  value: "udpUnicast",
  label: "UDP",
};

export const DEMO_CONNECTION_METHOD = [
  {
    value: "sdi",
    label: "SDI",
  },
  {
    value: "usbCamera",
    label: "USB Camera",
  },
];

export const CHANNEL_SOURCE = {
  wrapperChannel: "-2",
};

export const LOGS_TYPE = {
  all: "-1",
};

export const DEFAULT_VALUES = {
  PORT: 1234,
  IP: "0.0.0.0",
  LATENCY: 125,
  OVERHEAD: 25,
  TTL: 8,
  TRIGGER_INIT: false,
  TRIGGER_BITRATE_LEVEL: 1024,
  TRIGGER_BITRATE_SECS: 1,
  TRIGGER_BITRATE_TIME_SECS: 10,
};

export const SOURCE_TYPE = [
  {
    value: "multicast",
    label: "Multicast",
  },
  {
    value: "unicast",
    label: "Unicast",
  },
];

export const SRT_MODES = [
  {
    value: "listener",
    label: "Listener",
  },
  {
    value: "caller",
    label: "Caller",
  },
  {
    value: "rendezvous",
    label: "Rendezvous",
  },
];

export const CLOUD_CHANNEL_TYPE = {
  all: "all",
  own: "own",
};

export const SKIP_QS_METHOD_ERROR = 467;

export const STATISTIC_OUTPUT_TYPE = [
  "mbpsBandwidth",
  "msRtt",
  "ccErrors",
  "ccErrorsPerHour",
  "ccErrorsTotal",
  "pktSent",
  "pktSndLoss",
  "pktSndDrop",
  "pktRetrans",
  "mbpsSendRate",
];

export const STATISTIC_INPUT_TYPE = [
  "mbpsBandwidth",
  "msRtt",
  "ccErrors",
  "ccErrorsPerHour",
  "ccErrorsTotal",
  "pktRecv",
  "pktRcvLoss",
  "pktRcvDrop",
  "pktRcvRetrans",
  "pktRcvBelated",
  "mbpsRecvRate",
];

export const HIST_STATS_TYPE = {
  input: "input",
  output: "output",
};

export const DEFAULT_INPUT_TYPE = "inputUrl";
export const FORM_VERSION = "2.0";
export const INPUT_BACKUP_TYPE = "backup";
export const OUTPUT_TYPE = "nonMuxedOutputs";
