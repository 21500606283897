import { Menu } from "antd";
import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { actions as NAVIGATION_ACTIONS } from "../../ducks/navigation";

import store, { history } from "../../store";
import { selectors as ACCOUNT_SELECTORS, actions as ACCOUNT_ACTIONS } from "../../ducks/account";
import { actions as ADMIN_ACTIONS } from "../../ducks/admin";

import WebsocketService from "../../services/websocket";

import TutorialsModal from "../TutorialsModal";

const { SubMenu } = Menu;
const MenuItem = Menu.Item;

const UserMenu = ({ username, role }) => {
  const isAdmin = role === "admin";
  const [showTutorialsModal, setShowTutorialsModal] = useState(false);

  const onLogout = () => {
    window.localStorage.removeItem("authToken");
    store.dispatch(ACCOUNT_ACTIONS.CLEAR_ACCOUNT_DATA());
    store.dispatch(NAVIGATION_ACTIONS.CLEAR_ACTIVE_KEY());
    if (isAdmin) store.dispatch(ADMIN_ACTIONS.CLEAR_ACCOUNTS_LIST());
    WebsocketService.disconnect();
    history.push("/login");
  };

  const onAccount = () => {
    store.dispatch(NAVIGATION_ACTIONS.CLEAR_ACTIVE_KEY());
    history.push("/account");
  };

  const handleShowTutorialsModal = useCallback(() => {
    setShowTutorialsModal(!showTutorialsModal);
  }, [showTutorialsModal]);

  return (
    <StyledUserName mode="horizontal" theme="light">
      <SubMenu key="/account" title={username}>
        <MenuItem key="/account" onClick={onAccount}>
          <FormattedMessage id="userMenu.account" defaultMessage="Account" />
        </MenuItem>
        <MenuItem key="/tutorials" onClick={handleShowTutorialsModal}>
          <FormattedMessage id="userMenu.tutorials" defaultMessage="Tutorials" />
        </MenuItem>
        <MenuItem key="/logout" onClick={onLogout}>
          <FormattedMessage id="userMenu.logout" defaultMessage="Logout" />
        </MenuItem>
      </SubMenu>
      <TutorialsModal handleShowTutorialsModal={handleShowTutorialsModal} showTutorialsModal={showTutorialsModal} />
    </StyledUserName>
  );
};

UserMenu.propTypes = {
  username: PropTypes.string.isRequired,
  role: PropTypes.string,
};

UserMenu.defaultProps = {
  role: undefined,
};

const StyledUserName = styled(Menu)`
  display: flex;
  align-items: center;
  background-color: #fff;

  .ant-menu-submenu {
    min-height: 100%;
  }

  .ant-menu-submenu-title {
    padding: 10px 30px;
  }
`;

const mapStateToProps = (state) => ({
  username: ACCOUNT_SELECTORS.getUser(state),
  role: ACCOUNT_SELECTORS.getRole(state),
});

export default connect(mapStateToProps, null)(UserMenu);
