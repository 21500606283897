import React, { useState } from "react";
import { Col, Switch, Row, Form } from "antd";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

import { IPField } from "../../../components/Fields/Fields";
import InputNumberField from "../../../components/Fields/InputNumberField";
import NetworkField from "../../../components/Fields/NetworkField";
import PassphraseField from "../../../components/Fields/PassphraseField";

import { selectors as CONNECTION_SELECTORS } from "../../../ducks/node";

import { required } from "../../../lib/utils/formValidation";
import globalTranslations from "../../../lib/translations";
import { AUTO_IP, DEFAULT_VALUES } from "../../../lib/utils/constants";

import OverheadBandwidthLabel from "../Labels/OverheadBandwidthLabel";
import LatencyLabel from "../Labels/LatencyLabel";

const { Item } = Form;

const InputStreamQSSection = ({ initialValue, prefix, stundAddress, setFieldsValue }) => {
  const { formatMessage } = useIntl();
  const [autoIP, setAutoIp] = useState(initialValue?.ownIp === AUTO_IP || !initialValue?.ownIp);

  const handleSwitchAutoIp = (autoIPActive) => {
    if (autoIPActive) {
      setFieldsValue({
        input: {
          ownIp: stundAddress,
        },
      });
    } else {
      setFieldsValue({
        input: {
          ownIp: initialValue?.ownIp === AUTO_IP ? stundAddress : initialValue?.ownIp || DEFAULT_VALUES.IP,
        },
      });
    }
    setAutoIp(autoIPActive);
  };

  return (
    <Row gutter={24}>
      <Col md={24} lg={12}>
        <Row justify="space-between" align="middle" gutter={24}>
          <Col md={8} lg={8}>
            <Item name={[...prefix, "switchAutoIp"]} valuePropName="checked">
              <Switch
                defaultChecked={autoIP}
                checkedChildren={formatMessage(globalTranslations.autoIPON)}
                unCheckedChildren={formatMessage(globalTranslations.autoIPOFF)}
                onChange={handleSwitchAutoIp}
              />
            </Item>
          </Col>
          <Col md={16} lg={16}>
            <IPField
              label={formatMessage(globalTranslations.ownIPAddress)}
              name={[...prefix, "ownIp"]}
              disabled={autoIP}
            />
          </Col>
        </Row>
      </Col>
      <Col md={24} lg={12}>
        <NetworkField name={[...prefix, "interfaceIp"]} />
      </Col>
      <Col md={24} lg={12}>
        <InputNumberField
          name={[...prefix, "ffmpegStreamProperties", "oheadbw"]}
          label={OverheadBandwidthLabel()}
          fieldValidation={required}
        />
      </Col>
      <Col md={24} lg={12}>
        <InputNumberField
          name={[...prefix, "ffmpegStreamProperties", "rcvlatency"]}
          label={LatencyLabel("input")}
          fieldValidation={required}
        />
      </Col>
      <Col md={24} lg={24}>
        <PassphraseField
          prefix={[...prefix, "ffmpegStreamProperties"]}
          initialValue={initialValue?.ffmpegStreamProperties?.passphrase}
        />
      </Col>
    </Row>
  );
};

InputStreamQSSection.propTypes = {
  initialValue: PropTypes.shape({
    connectionMethod: PropTypes.string,
    dstPort: PropTypes.number,
    ffmpegStreamProperties: PropTypes.shape({
      connectTimeout: PropTypes.number,
      mode: PropTypes.string,
      oheadbw: PropTypes.number,
      rcvlatency: PropTypes.number,
    }),
    interfaceIp: PropTypes.string,
    ownIp: PropTypes.string,
  }),
  prefix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  stundAddress: PropTypes.string.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
};

InputStreamQSSection.defaultProps = {
  initialValue: null,
};

const mapStateToProps = (state) => ({
  stundAddress: CONNECTION_SELECTORS.getStundAddress(state),
});

export default connect(mapStateToProps, null)(InputStreamQSSection);
