import React from "react";
import PropTypes from "prop-types";
import { Input, Form } from "antd";
import { useIntl } from "react-intl";

import globalTranslations from "../../../lib/translations";

const { Item } = Form;

const InputTextFiled = ({ name, label, rules }) => {
  const { formatMessage } = useIntl();

  return (
    <Item
      label={label}
      name={name}
      rules={[...rules, { required: true, message: formatMessage(globalTranslations.required) }]}
    >
      <Input type="text" />
    </Item>
  );
};

InputTextFiled.propTypes = {
  label: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  rules: PropTypes.arrayOf(PropTypes.object),
};

InputTextFiled.defaultProps = {
  label: "",
  rules: [],
};

export default InputTextFiled;
