import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import { Button, Spin, Table } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

import store from "../../../store";

import WebsocketService from "../../../services/websocket";
import CloudChannelsServices from "../../../services/cloudChannels";

import { selectors as CLOUD_CONNECTION_SELECTORS } from "../../../ducks/cloudConnection";
import { selectors as NODE_CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";
import { selectors as ACCOUNT_SELECTORS } from "../../../ducks/account";
import { selectors as LOADING_SELECTORS, actions as LOADING_ACTIONS } from "../../../ducks/loadingData";
import { actions as NODE_ACTIONS } from "../../../ducks/node";

import { WEBSOCKET_STATUS, QUICKSTREAM_METHOD, LOADING_DATA_TYPE, QUICKSTREAM } from "../../../lib/utils/constants";

import TranslatedStatus from "../../../components/TranslatedStatus";
import AddNewChannelButton from "../../../components/AddNewChannelButton";
import NodeChannelsActionButtons from "../../../components/NodeChannelsActionButtons";
import ValidLicenseBadge from "../../../components/ValidLicenseBadge";

import { errorNotification } from "../../../lib/utils/notification";

const translations = defineMessages({
  name: { id: "ChannelList.name", defaultMessage: "Name" },
  logs: {
    id: "ChannelList.logs",
    defaultMessage: "Logs",
  },
  stats: {
    id: "ChannelList.stats",
    defaultMessage: "Stats",
  },
  mainInput: {
    id: "ChannelList.mainInput",
    defaultMessage: "Main",
  },
  backupInput: {
    id: "ChannelList.backupInput",
    defaultMessage: "Backup",
  },
  status: {
    id: "ChannelList.status",
    defaultMessage: "Input status",
  },
  inputSource: {
    id: "ChannelList.inputSource",
    defaultMessage: "Input source",
  },
  licenseStatus: {
    id: "ChannelList.licenseStatus",
    defaultMessage: "License status",
  },
  actions: {
    id: "ChannelList.actions",
    defaultMessage: "Actions",
  },
  wrongCloudNodeName: {
    id: "general.wrongCloudNodeName",
    defaultMessage: "Node does not exist in Quickstream Cloud",
  },
});

const NodeChannelsList = ({
  channels,
  connectionStatus,
  loggedToCloud,
  loading,
  loadingDataType,
  match: {
    params: { cnn, cwid },
  },
  nodes,
  openLogTab,
  openStatisticTab,
  websocketConnection,
}) => {
  const { formatMessage } = useIntl();
  const nodeData = nodes.find((node) => node.cwid === cwid);

  const [dataFromCloudFlag, setDataFromCloudFlag] = useState(false);

  const channelsJs = channels.toJS();

  const getInterfaces = useCallback(() => {
    WebsocketService.sendMessage(
      JSON.stringify({ cloudMsgType: "sendMessage", command: "getStunAddr", to: nodeData?.cwid })
    );
    store.dispatch(LOADING_ACTIONS.SET_LOADING("getStunAddr"));
    // eslint-disable-next-line
  }, []);

  const getHostname = useCallback(() => {
    WebsocketService.sendMessage(JSON.stringify({ cloudMsgType: "sendMessage", command: "getHostname", to: cwid }));
    store.dispatch(LOADING_ACTIONS.SET_LOADING("getHostname"));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const isConnection = websocketConnection && connectionStatus === WEBSOCKET_STATUS.connected;

    if (isConnection) {
      getInterfaces();
      getHostname();
    }
  }, [websocketConnection, connectionStatus, getInterfaces, getHostname]);

  useEffect(() => {
    if (nodeData?.cwid) {
      WebsocketService.sendMessage(
        JSON.stringify({ command: "getAllChannels", cloudMsgType: "sendMessage", to: nodeData?.cwid })
      );
      store.dispatch(LOADING_ACTIONS.SET_LOADING("getAllChannels"));
      store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    }
  }, [nodeData]);

  useEffect(() => {
    if (dataFromCloudFlag) {
      return;
    }
    async function fetchPermissionDescription({ cloudId, channelId }) {
      if (loggedToCloud) {
        await CloudChannelsServices.getChannelPermissionsDescription(
          { cloudId, channelId },
          {
            errorNotification: errorNotification(formatMessage),
          }
        );
      }
    }

    const outputQSMethodChannels = Object.values(channelsJs).filter((channel) => {
      return channel.config?.nonMuxedOutputs && channel.config?.nonMuxedOutputs[0].connectionType === QUICKSTREAM;
    });

    if (outputQSMethodChannels.length > 0) {
      outputQSMethodChannels.forEach((channel) => {
        fetchPermissionDescription({
          cloudId: channel.config?.nonMuxedOutputs && channel.config?.nonMuxedOutputs[0].cloudId,
          channelId: channel.channelId,
        });
      });
      setDataFromCloudFlag(true);
    }
  }, [channels, channelsJs, dataFromCloudFlag, formatMessage, loggedToCloud]);

  const columns = [
    {
      key: "name",
      title: formatMessage(translations.name),
      sorter: (currentValue, nextValue) => currentValue.config.name.localeCompare(nextValue.config.name),
      render: (text, { channelId, config: { name } }) => (
        <StyledLink key={channelId} to={`/cloudNodes/${cwid}/${cnn}/channel/${channelId}`}>
          <span>{name}</span>
        </StyledLink>
      ),
    },
    {
      key: "logs",
      title: formatMessage(translations.logs),
      render: (text, { channelId }) => (
        <Button type="link" key={channelId} onClick={() => openLogTab("logs", channelId)}>
          <FormattedMessage id="ChannelList.logs" defaultMessage="Logs" />
        </Button>
      ),
    },
    {
      key: "stats",
      title: formatMessage(translations.stats),
      render: (text, { channelId }) => (
        <Button type="link" key={channelId} onClick={() => openStatisticTab("statistics", channelId)}>
          <FormattedMessage id="ChannelList.statistics" defaultMessage="Statistics" />
        </Button>
      ),
    },
    {
      key: "InputSource",
      align: "center",
      title: formatMessage(translations.inputSource),
      dataIndex: "status",
      sorter: (currentValue, nextValue) => currentValue.status.usesBackup.localeCompare(nextValue.status.usesBackup),
      render: ({ usesBackup }) => {
        return usesBackup ? formatMessage(translations.backupInput) : formatMessage(translations.mainInput);
      },
    },
    {
      key: "status",
      align: "center",
      title: formatMessage(translations.status),
      dataIndex: "status",
      sorter: (currentValue, nextValue) =>
        currentValue.status.channelStatusText.localeCompare(nextValue.status.channelStatusText),
      render: (status) => {
        return <TranslatedStatus channelStatus={status?.channelStatusText} />;
      },
    },
    {
      key: "licenseStatus",
      align: "center",
      title: formatMessage(translations.licenseStatus),
      dataIndex: "validLicense",
      sorter: (currentValue, nextValue) => currentValue.validLicense.localeCompare(nextValue.validLicense),
      render: (validLicense, record) => {
        return <ValidLicenseBadge validLicense={validLicense} licensesUsed={record.licensesUsed} />;
      },
    },
    {
      key: "actions",
      title: formatMessage(translations.actions),
      render: (text, { status, channelId, config: { version, input, nonMuxedOutputs } }) => {
        const hasPermissionId = +input?.permissionId || false;
        const hasCloudId = (nonMuxedOutputs && nonMuxedOutputs[0].cloudId) || false;
        const quickstreamMethod =
          (nonMuxedOutputs && nonMuxedOutputs[0].connectionType === QUICKSTREAM_METHOD.value) ||
          input?.connectionType === QUICKSTREAM_METHOD.value;

        return (
          <NodeChannelsActionButtons
            cwid={nodeData?.cwid}
            cnn={nodeData?.cnn}
            status={status?.channelStatusText}
            requestStatus={status?.requestedStatusText}
            channelId={channelId}
            cloudData={{ hasPermissionId, hasCloudId }}
            loggedToCloud={!!loggedToCloud}
            quickstreamMethod={quickstreamMethod}
            version={version}
          />
        );
      },
    },
  ];

  const isLoadingChannelList = loading && loadingDataType === LOADING_DATA_TYPE.channelList;

  const data = Object.entries(channelsJs).map(([, item]) => item);

  if (nodes.length > 0 && !nodeData) {
    errorNotification(formatMessage)(translations.wrongCloudNodeName);

    return <Redirect to="/shares" />;
  }

  return (
    <div>
      {isLoadingChannelList && <Spin size="small" />}
      {!isLoadingChannelList && <Table dataSource={data} {...{ columns }} rowKey={(record) => record.channelId} />}
      {nodeData && <AddNewChannelButton nodeData={nodeData} />}
    </div>
  );
};

const StyledLink = styled(Link)`
  &:hover {
    color: #ffa940;
  }
`;

NodeChannelsList.propTypes = {
  channels: ImmutablePropTypes.map.isRequired,
  loggedToCloud: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  loadingDataType: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ cnn: PropTypes.string.isRequired, cwid: PropTypes.string.isRequired }),
  }).isRequired,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      cnn: PropTypes.string.isRequired,
      cwid: PropTypes.string.isRequired,
    })
  ).isRequired,
  connectionStatus: PropTypes.string,
  openStatisticTab: PropTypes.func.isRequired,
  openLogTab: PropTypes.func.isRequired,
  websocketConnection: PropTypes.bool,
};

NodeChannelsList.defaultProps = {
  loggedToCloud: null,
  connectionStatus: null,
  websocketConnection: null,
};

const mapStateToProps = (state) => ({
  channels: NODE_CHANNEL_SELECTORS.getChannels(state),
  loggedToCloud: ACCOUNT_SELECTORS.getUser(state),
  nodes: CLOUD_CONNECTION_SELECTORS.getNodes(state),
  connectionStatus: CLOUD_CONNECTION_SELECTORS.getStatus(state),
  websocketConnection: CLOUD_CONNECTION_SELECTORS.getWebsocketConnection(state),
  loading: LOADING_SELECTORS.getLoading(state),
  loadingDataType: LOADING_SELECTORS.getLoadingDataType(state),
});

export default connect(mapStateToProps, null)(NodeChannelsList);
