import React from "react";
import ReactDOM from "react-dom";
import { connect, Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import * as PropTypes from "prop-types";
import "fontsource-roboto";

import "./App.less";

import store from "./store";
import { actions as ACCOUNT_ACTIONS } from "./ducks/account";
import { actions as LANG_ACTIONS, selectors as LANG_SELECTORS } from "./ducks/lang";

import WebsocketService from "./services/websocket";

import { messages } from "./config";

import Layout from "./components/Layout";

const token = window.localStorage.getItem("authToken");
if (token) {
  store.dispatch(ACCOUNT_ACTIONS.INITIALIZE({ token }));
  WebsocketService.initialize();
}

const initialLang = window.localStorage.getItem("lang") || "en";
store.dispatch(LANG_ACTIONS.CHANGE_LANG_DATA({ lang: initialLang }));

const MainComponent = ({ lang }) => {
  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <Layout />
    </IntlProvider>
  );
};

MainComponent.propTypes = {
  lang: PropTypes.string,
};

MainComponent.defaultProps = {
  lang: null,
};

const mapStateToProps = (state) => ({
  lang: LANG_SELECTORS.getLang(state),
});

const MainComponentWithState = connect(mapStateToProps, null)(MainComponent);

ReactDOM.render(
  <Provider store={store}>
    <MainComponentWithState />
  </Provider>,
  document.getElementById("root")
);
