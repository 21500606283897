import React, { useCallback, useState, useEffect } from "react";
import { LockOutlined } from "@ant-design/icons";
import { Input, Button, Form, Card, Spin } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { withRouter } from "react-router-dom";

import AccountService from "../../services/account";
import { errorNotification, successNotification } from "../../lib/utils/notification";
import { max32characters, password } from "../../lib/utils/formValidation";

import logoUrl from "../../lib/images/logo-cloud-black.png";

import LoadingReCaptcha from "../LoadingReCaptcha";
import ReturnSignInFooter from "../ReturnSignInFooter";

const { REACT_APP_RECAPTCHA_V3_PUBLIC_KEY } = process.env;
const { Item } = Form;
const { Password } = Input;

const translations = defineMessages({
  confirmPassword: {
    id: "ChangePasswordForm.confirmPassword",
    defaultMessage: "Confirm new password",
  },
  confirmPasswordNoInput: {
    id: "ChangePasswordForm.validationConfirmPassword",
    defaultMessage: "Please input confirmation password",
  },
  confirmPasswordNotIdentical: {
    id: "ChangePasswordForm.validationNotIdentical",
    defaultMessage: "Confirmation password need to be the same as password",
  },
  password: {
    id: "ChangePasswordForm.password",
    defaultMessage: "New password",
  },
  passwordNoInput: {
    id: "ChangePasswordForm.validationNoPassword",
    defaultMessage: "Please input password",
  },
  passwordTooShort: {
    id: "ChangePasswordForm.validationMinLength",
    defaultMessage: "Password must be at least 8 characters long",
  },
  returnSignInLinkTitle: {
    id: "ChangePasswordForm.returnSignInLinkTitle",
    defaultMessage: "Back to",
  },
});

const ChangePasswordForm = withRouter(
  ({
    match: {
      params: { resetLink },
    },
  }) => {
    const [form] = Form.useForm();
    const { getFieldValue } = form;
    const { formatMessage } = useIntl();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [reCaptchaToken, setReCaptchaToken] = useState(null);

    const checkReCaptcha = useCallback(async () => {
      const reCaptchaResponse = await executeRecaptcha("changePasswordForm");
      setReCaptchaToken(reCaptchaResponse);
    }, [executeRecaptcha]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        checkReCaptcha();
      }, 200);

      const interval = setInterval(async () => {
        checkReCaptcha();
      }, 120000);

      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }, [checkReCaptcha]);

    const handleFinish = useCallback(
      ({ newPassword }) => {
        const data = {
          newPassword,
          resetLink,
          reCaptchaToken,
        };
        AccountService.resetPassword(data, {
          errorNotification: errorNotification(formatMessage),
          successNotification: successNotification(formatMessage),
        });
        checkReCaptcha();
      },
      [formatMessage, resetLink, checkReCaptcha, reCaptchaToken]
    );

    const validatePassword = useCallback(
      (rule, value) => {
        const passwordValue = getFieldValue("newPassword");
        if (!value) {
          return Promise.reject(formatMessage(translations.confirmPasswordNoInput));
        }
        if (value !== passwordValue) {
          return Promise.reject(formatMessage(translations.confirmPasswordNotIdentical));
        }

        return Promise.resolve();
      },
      [formatMessage, getFieldValue]
    );

    return (
      <StyledFormContainer>
        <Spin spinning={!reCaptchaToken} tip={<LoadingReCaptcha checkReCaptcha={checkReCaptcha} />}>
          <Card
            title={<FormattedMessage id="ChangePasswordForm.changePassword" defaultMessage="Change password" />}
            extra={<img src={logoUrl} alt="quickstream logo" width="200" />}
          >
            <StyledForm onFinish={handleFinish} form={form}>
              <Item
                name="newPassword"
                rules={[
                  { required: true, message: formatMessage(translations.passwordNoInput) },
                  { min: 8, message: formatMessage(translations.passwordTooShort) },
                  password,
                  max32characters,
                ]}
              >
                <Password
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="password"
                  autoComplete="new-password"
                  placeholder={formatMessage(translations.password)}
                />
              </Item>
              <Item name="confirmPassword" rules={[{ validator: validatePassword }, password]}>
                <Password
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="password"
                  autoComplete="new-password"
                  placeholder={formatMessage(translations.confirmPassword)}
                />
              </Item>
              <StyledButton type="primary" htmlType="submit">
                <FormattedMessage id="ChangePasswordForm.submit" defaultMessage="Change password" />
              </StyledButton>
            </StyledForm>
          </Card>
        </Spin>
        <ReturnSignInFooter linkTitle={formatMessage(translations.returnSignInLinkTitle)} />
      </StyledFormContainer>
    );
  }
);

ChangePasswordForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      resetLink: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
`;

const StyledForm = styled(Form)`
  min-width: 450px;
`;

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ChangePasswordFormContainer = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_V3_PUBLIC_KEY}>
      <ChangePasswordForm />
    </GoogleReCaptchaProvider>
  );
};

export default ChangePasswordFormContainer;
