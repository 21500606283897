import React from "react";
import { Card } from "antd";
import styled from "styled-components";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import RegistrationForm from "../../components/RegistrationForm";
import ReturnSignInFooter from "../../components/ReturnSignInFooter";

import logoUrl from "../../lib/images/logo-cloud-black.png";

const { REACT_APP_RECAPTCHA_V3_PUBLIC_KEY } = process.env;

const translations = defineMessages({
  registrationLinkTitle: {
    id: "PasswordForgotForm.registrationLinkTitle",
    defaultMessage: "Already have an account?",
  },
});

const Registration = () => {
  const { formatMessage } = useIntl();

  return (
    <StyledFormContainer>
      <Card
        title={<FormattedMessage id="Registration.title" defaultMessage="Registration Form" />}
        extra={<img src={logoUrl} alt="quickstream logo" width="200" />}
      >
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_V3_PUBLIC_KEY}>
          <RegistrationForm />
        </GoogleReCaptchaProvider>
      </Card>
      <ReturnSignInFooter linkTitle={formatMessage(translations.registrationLinkTitle)} />
    </StyledFormContainer>
  );
};

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default Registration;
