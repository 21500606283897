import React from "react";
import PropTypes from "prop-types";
import { Tabs, Card } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { selectors as CONNECTION_SELECTORS } from "../../../ducks/node";
import { selectors as NAVIGATION_SELECTORS } from "../../../ducks/navigation";

import { NODE_CHANEL_TABS } from "../../../lib/utils/constants";

import StatisticsLive from "./StatisticsLive";
import StatisticHistorical from "./StatisticHistorical";

const { TabPane } = Tabs;

const StatisticsContainer = ({ cwid, channelId, setChannelId, channelActiveTab }) => {
  if (channelActiveTab !== NODE_CHANEL_TABS.statistics) {
    return null;
  }

  return (
    <Card>
      <Tabs defaultActiveKey="input">
        <TabPane tab={<FormattedMessage id="StatisticsContainer.liveData" defaultMessage="Live data" />} key="live">
          <StatisticsLive channelId={channelId} setChannelId={setChannelId} cwid={cwid} />
        </TabPane>
        <TabPane
          tab={<FormattedMessage id="StatisticsContainer.historicalData" defaultMessage="Historical data" />}
          key="historical"
        >
          <StatisticHistorical channelId={channelId} setChannelId={setChannelId} />
        </TabPane>
      </Tabs>
    </Card>
  );
};

StatisticsContainer.propTypes = {
  channelActiveTab: PropTypes.string,
  channelId: PropTypes.string,
  cwid: PropTypes.string,
  setChannelId: PropTypes.func.isRequired,
};

StatisticsContainer.defaultProps = {
  channelActiveTab: null,
  channelId: "",
  cwid: null,
};

const mapStateToProps = (state) => ({
  channelActiveTab: NAVIGATION_SELECTORS.getChannelsActiveTab(state),
  cwid: CONNECTION_SELECTORS.getNodeCwid(state),
});

export default connect(mapStateToProps, null)(StatisticsContainer);
