import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const SET_SYSTEM_STATS = createAction("STATISTICS/NODE/SET_SYSTEM_STATS");
export const CLEAR_ALL_STATISTIC_NODE = createAction("STATISTICS/NODE/CLEAR_ALL_STATISTIC_NODE");

const ONE_DAY = 8640;

const defaultState = new Map({
  data: {},
  interfaces: {},
});
const reducer = handleActions(
  {
    [SET_SYSTEM_STATS]: (
      state,
      { payload: { from, cpuLoad, ramAvailable, ramTotal, time, totalIncoming, totalOutgoing, networkLoads } }
    ) => {
      const oldStateData = state.get("data");
      const oldStateDataFrom = oldStateData[from] || [];
      const oldStateInterfaces = state.get("interfaces");

      let incoming;
      let outgoing;

      networkLoads.forEach((networkInterface) => {
        const oldIncomingData = state.getIn(["interfaces", [from], "incoming", [networkInterface.interface]]) || [];
        const oldOutgoingData = state.getIn(["interfaces", [from], "outgoing", [networkInterface.interface]]) || [];

        if (oldIncomingData.length > ONE_DAY) {
          oldIncomingData.shift();
        }
        if (oldOutgoingData.length > ONE_DAY) {
          oldOutgoingData.shift();
        }
        incoming = {
          ...incoming,
          [networkInterface.interface]: [...oldIncomingData, { value: networkInterface.incoming, time }],
        };
        outgoing = {
          ...outgoing,
          [networkInterface.interface]: [...oldOutgoingData, { value: networkInterface.outgoing, time }],
        };
      });

      return state.merge({
        data: {
          ...oldStateData,
          [from]: [
            ...oldStateDataFrom,
            { cpuLoad, ramAvailable, ramTotal, time, totalIncoming, totalOutgoing, networkLoads },
          ],
        },
        interfaces: { ...oldStateInterfaces, [from]: { incoming, outgoing } },
      });
    },
    [CLEAR_ALL_STATISTIC_NODE]: () => {
      return defaultState;
    },
  },
  defaultState
);

export const selectors = {
  getStatisticNode: (state) => state.getIn(["nodeSystemStatistics", "data"]),
  getStatisticNodeInterfaces: (state) => state.getIn(["nodeSystemStatistics", "interfaces"]),
};

export default reducer;
