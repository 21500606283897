import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

import { WEBSOCKET_STATUS } from "../lib/utils/constants";

export const actions = {
  CLEAR_CONNECTION: createAction("CLOUD/CLEAR_CONNECTION"),
  DISCONNECT: createAction("CLOUD/DISCONNECT"),
  SET_CONNECTION: createAction("CLOUD/SET_CONNECTION"),
  SET_NODES: createAction("CLOUD/SET_NODES"),
  SET_CONNECTION_AUTH: createAction("CLOUD/SET_CONNECTION_AUTH"),
  NODE_UPDATE_STATUS: createAction("CLOUD/NODE_UPDATE_STATUS"),
  NODE_UPDATE_CNN: createAction("CLOUD/UPDATE_NODE_CNN"),
};

const defaultState = new Map({
  websocketConnection: null,
  cwid: null,
  nodes: [],
  status: null,
});

const reducer = handleActions(
  {
    [actions.SET_CONNECTION]: (state) =>
      state.merge({ websocketConnection: true, status: WEBSOCKET_STATUS.connecting }),
    [actions.SET_CONNECTION_AUTH]: (state, { payload }) =>
      state.merge({ cwid: payload, status: WEBSOCKET_STATUS.connected }),
    [actions.SET_NODES]: (state, { payload }) => state.merge({ nodes: payload }),
    [actions.SET_NODES]: (state, { payload }) => state.merge({ nodes: payload }),
    [actions.NODE_UPDATE_STATUS]: (state, { payload: { cwid, status } }) => {
      const oldState = state.get("nodes");
      const updatedNodes = oldState.map((node) => {
        if (node.cwid === cwid) {
          return { ...node, status };
        }

        return node;
      });

      return state.merge({ nodes: updatedNodes });
    },
    [actions.NODE_UPDATE_CNN]: (state, { payload: { hostname, from } }) => {
      const oldState = state.get("nodes");
      const updatedNodes = oldState.map((node) => {
        if (node.cwid === from) {
          return { ...node, cnn: hostname };
        }

        return node;
      });

      return state.merge({ nodes: updatedNodes });
    },
    [actions.DISCONNECT]: (state) => state.merge({ websocketConnection: false, cwid: null }),
    [actions.CLEAR_CONNECTION]: () => defaultState,
  },
  defaultState
);

export const selectors = {
  getNodes: (state) => state.getIn(["cloudConnection", "nodes"]),
  getWebsocketConnection: (state) => state.getIn(["cloudConnection", "websocketConnection"]),
  getStatus: (state) => state.getIn(["cloudConnection", "status"]),
};

export default reducer;
