export default (channelId) => {
  const parsedChannelId = channelId
    .toString(10)
    .split("")
    .map((id, index) => {
      const dashesIndexArray = [0, 3, 6];
      if (dashesIndexArray.includes(index)) {
        return `${id}-`;
      }

      return id;
    })
    .join("");

  return parsedChannelId;
};
