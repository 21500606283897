import React from "react";
import { Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute";

// import HomePage from "../../pages/Home";
import Account from "../../pages/Account";
import AccountsList from "../../pages/Admin/AccountsList";
import LicenseList from "../../pages/Admin/License/LicenseList";
import NewLicenseForm from "../../pages/Admin/License/NewLicenseForm";
import ChannelsList from "../../pages/Channels";
import ChannelDetailView from "../../pages/ChannelDetailView";
import NodeDashboard from "../../pages/Node/NodeDashboard";
import NodeChannelEdit from "../../pages/NodeChannelEdit";
import NodeSystem from "../../pages/Node/NodeSystem";
import NodeLicenseList from "../../pages/Node/NodeLicenseList";

import NodeChannelForm from "../../pages/ChannelForm";
import NodeChannelDetailView from "../../pages/NodeChannelDetailView";

const NavigationLayoutRoutes = () => (
  <Switch>
    <ProtectedRoute path="/" exact component={ChannelsList} />
    <ProtectedRoute path="/account" exact component={Account} />
    <ProtectedRoute path="/admin/accounts" exact component={AccountsList} />
    <ProtectedRoute path="/admin/license" exact component={LicenseList} />
    <ProtectedRoute path="/admin/license/new" exact component={NewLicenseForm} />
    <ProtectedRoute path="/shares" exact component={ChannelsList} />
    <ProtectedRoute exact path="/channel/:type/:id" component={ChannelDetailView} />
    <ProtectedRoute exact path="/cloudNodes/:cwid/:cnn/channels" component={NodeDashboard} />
    <ProtectedRoute exact path="/cloudNodes/:cwid/:cnn/system" component={NodeSystem} />
    <ProtectedRoute exact path="/cloudNodes/:cwid/:cnn/licenses" component={NodeLicenseList} />
    <ProtectedRoute exact path="/cloudNodes/:cwid/:cnn/channel/add" component={NodeChannelForm} />
    <ProtectedRoute exact path="/cloudNodes/:cwid/:cnn/channel/edit/:id" component={NodeChannelEdit} />
    <ProtectedRoute exact path="/cloudNodes/:cwid/:cnn/channel/:id" component={NodeChannelDetailView} />
    <Redirect to="/login" />
  </Switch>
);

export default NavigationLayoutRoutes;
