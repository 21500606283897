import React from "react";
import { Tabs } from "antd";
import PropTypes from "prop-types";

import StatisticsView from "./StatisticsView";
import StatisticsGraph from "./StatisticsGraph";
import StatisticOutputTooltip from "./StatisticOutputTooltip";
import { CHANNEL_SOURCE } from "../../../lib/utils/constants";

const { TabPane } = Tabs;

const StatisticOutput = ({ loggedToCloud, statistics }) => {
  const channelData = {};
  statistics.forEach((statOutputArray) => {
    statOutputArray.forEach((statistic) => {
      channelData[statistic.channelName] = [
        ...(channelData[statistic.channelName] ? channelData[statistic.channelName] : []),
        statistic,
      ];
    });
  });

  const selectTabName = ({ channelName, username, permissionId }) => {
    if (permissionId === CHANNEL_SOURCE.wrapperChannel) {
      return username;
    }

    if (!loggedToCloud) {
      return <StatisticOutputTooltip channelName={channelName} />;
    }

    return `${channelName} (${username})`;
  };

  const parsedTabPane = () => {
    return Object.entries(channelData).map((parsedChannel) => {
      const channelName = parsedChannel[0];
      const statData = parsedChannel[1];
      const { username, permissionId } = statData[0];

      const newestChannelOutStats = statData[statData.length - 1];
      const {
        channelName: removedChannelName,
        permissionId: removedPermissionId,
        username: removedUsername,
        ...restNewestChannelOutStats
      } = newestChannelOutStats;

      return (
        <TabPane tab={selectTabName({ channelName, permissionId, username })} key={channelName}>
          <StatisticsGraph data={statData} />
          {newestChannelOutStats && <StatisticsView data={restNewestChannelOutStats} />}
        </TabPane>
      );
    });
  };

  return <Tabs>{parsedTabPane()}</Tabs>;
};

StatisticOutput.propTypes = {
  loggedToCloud: PropTypes.bool.isRequired,
  statistics: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        ccErrors: PropTypes.string,
        ccErrorsPerHour: PropTypes.string,
        ccErrorsTotal: PropTypes.string,
        channelId: PropTypes.string,
        channelName: PropTypes.string,
        cloudId: PropTypes.string,
        mbpsBandwidth: PropTypes.string,
        mbpsRecvRate: PropTypes.string,
        mbpsSendRate: PropTypes.string,
        msRtt: PropTypes.string,
        msTimestamp: PropTypes.string,
        permissionId: PropTypes.string,
        pktRcvBelated: PropTypes.string,
        pktRcvDrop: PropTypes.string,
        pktRcvLoss: PropTypes.string,
        pktRcvRetrans: PropTypes.string,
        pktRecv: PropTypes.string,
        pktRetrans: PropTypes.string,
        pktSent: PropTypes.string,
        pktSndDrop: PropTypes.string,
        pktSndLoss: PropTypes.string,
        time: PropTypes.string,
        username: PropTypes.string,
      })
    )
  ).isRequired,
};

export default StatisticOutput;
