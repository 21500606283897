import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Divider, Tabs, Card, Empty } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ImmutablePropTypes from "react-immutable-proptypes";
import store from "../../../store";

import { selectors as CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";
import { actions as LOADING_ACTIONS } from "../../../ducks/loadingData";
import { CLEAR_HIST_STATISTIC } from "../../../ducks/nodeStatistics";
import { selectors as NODE_SELECTORS } from "../../../ducks/node";
import WebsocketManager from "../../../services/websocket";

import SelectEngine from "../../../components/SelectEngine";
import StatisticHistoricalGraph from "./StatisticHistoricalGraph";
import StatisticHistoricalOutput from "./StatisticHistoricalOutput";

import { HIST_STATS_TYPE, CONNECTION_METHOD } from "../../../lib/utils/constants";

const { TabPane } = Tabs;

const StatisticHistorical = ({ channels, channelId, setChannelId, cwid }) => {
  const [scale, setScale] = useState(0);
  const [activeTab, setActiveTab] = useState(HIST_STATS_TYPE.input);

  const selectedChannelData = channels.get(channelId);
  const inputConnectionMethod = selectedChannelData?.config.input.connectionType;

  const getCloudId = useCallback(() => {
    switch (inputConnectionMethod) {
      case CONNECTION_METHOD.srtCaller.value:
      case CONNECTION_METHOD.srtRendezvous.value:
      case CONNECTION_METHOD.srtListener.value:
      case CONNECTION_METHOD.udpMulticast.value:
      case CONNECTION_METHOD.udpUnicast.value:
        return "-1";
      default:
        return selectedChannelData?.config.input.cloudId;
    }
  }, [inputConnectionMethod, selectedChannelData]);

  useEffect(() => {
    const message = {
      channelId,
      cloudId: getCloudId(),
      scale,
    };

    if (!channelId) {
      return;
    }

    WebsocketManager.sendMessage(
      JSON.stringify({ ...message, cloudMsgType: "sendMessage", command: "getStatsInMin", to: cwid })
    );
    WebsocketManager.sendMessage(
      JSON.stringify({ ...message, cloudMsgType: "sendMessage", command: "getStatsOutInit", to: cwid })
    );
    store.dispatch(LOADING_ACTIONS.SET_LOADING("getStatsMin"));
  }, [channelId, scale, cwid, getCloudId]);

  const [...channelIds] = channels && channels.keys();
  const sortedChannelsIds = channelIds.sort((a, b) => a - b);
  const handleSelectChange = (selectedEngineId) => {
    setChannelId(selectedEngineId);
    store.dispatch(CLEAR_HIST_STATISTIC());
  };

  const cloudId = getCloudId();

  const handleChangeTab = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  if (!channelId) {
    return (
      <Empty
        description={
          <FormattedMessage id="StatisticHistorical.noData" defaultMessage="There is no historical data available" />
        }
      />
    );
  }

  return (
    <Card>
      <FormattedMessage id="StatisticHistorical.channel" defaultMessage="Channel" />
      {": "}
      <SelectEngine
        channels={channels}
        channelId={channelId}
        channelIds={sortedChannelsIds}
        handleSelectChange={handleSelectChange}
      />
      <Divider />
      <Tabs defaultActiveKey={activeTab} onChange={handleChangeTab}>
        <TabPane
          tab={<FormattedMessage id="StatisticHistorical.input" defaultMessage="Input" />}
          key={HIST_STATS_TYPE.input}
        >
          {activeTab === HIST_STATS_TYPE.input && (
            <StatisticHistoricalGraph channelId={channelId} cloudId={cloudId} scale={scale} setScale={setScale} />
          )}
        </TabPane>
        <TabPane
          tab={<FormattedMessage id="StatisticHistorical.output" defaultMessage="Output" />}
          key={HIST_STATS_TYPE.output}
        >
          {activeTab === HIST_STATS_TYPE.output && (
            <StatisticHistoricalOutput channelId={channelId} cloudId={cloudId} scale={scale} setScale={setScale} />
          )}
        </TabPane>
      </Tabs>
    </Card>
  );
};

StatisticHistorical.propTypes = {
  cwid: PropTypes.string,
  channels: ImmutablePropTypes.map.isRequired,
  channelId: PropTypes.string.isRequired,
  setChannelId: PropTypes.func.isRequired,
};

StatisticHistorical.defaultProps = {
  cwid: null,
};

const mapStateToProps = (state) => ({
  cwid: NODE_SELECTORS.getNodeCwid(state),
  channels: CHANNEL_SELECTORS.getChannels(state),
});

export default connect(mapStateToProps, null)(StatisticHistorical);
