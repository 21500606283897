import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const SET_OWN_CHANNELS = createAction("CLOUD/SET_OWN_CHANNELS");
export const SET_SHARED_CHANNELS = createAction("CLOUD/SET_SHARED_CHANNELS");
export const CLEAR_CHANNELS = createAction("CLOUD/CLEAR_CHANNELS");
export const ADD_NEW_OWN_CHANNEL = createAction("CLOUD/ADD_NEW_OWN_CHANNEL");
export const REMOVE_OWN_CHANNEL = createAction("CLOUD/REMOVE_OWN_CHANNEL");
export const SET_ALL_CHANNELS = createAction("CLOUD/SET_ALL_CHANNELS");
export const ADD_NEW_ALL_CHANNEL = createAction("CLOUD/ADD_NEW_ALL_CHANNEL");
export const REMOVE_ALL_CHANNEL = createAction("CLOUD/REMOVE_ALL_CHANNEL");
export const REMOVE_SHARED_CHANNEL = createAction("CLOUD/REMOVE_SHARED_CHANNEL");
export const SET_CHANNEL_PERMISSIONS = createAction("CLOUD/SET_CHANNEL_PERMISSIONS");
export const UPDATE_OWN_CHANNEL = createAction("CLOUD/UPDATE_OWN_CHANNEL");

const defaultState = new Map({
  ownChannels: [],
  sharedChannels: [],
  allChannels: [],
});

const reducer = handleActions(
  {
    [SET_ALL_CHANNELS]: (state, { payload }) => state.merge({ allChannels: payload }),
    [SET_OWN_CHANNELS]: (state, { payload }) => state.merge({ ownChannels: payload }),
    [ADD_NEW_OWN_CHANNEL]: (state, { payload }) => state.updateIn(["ownChannels"], (array) => array.concat([payload])),
    [ADD_NEW_ALL_CHANNEL]: (state, { payload }) => state.updateIn(["allChannels"], (array) => array.concat([payload])),
    [REMOVE_OWN_CHANNEL]: (state, { payload }) =>
      state.updateIn(["ownChannels"], (array) => array.filter((channel) => channel.id !== payload)),
    [REMOVE_ALL_CHANNEL]: (state, { payload }) =>
      state.updateIn(["allChannels"], (array) => array.filter((channel) => channel.id !== payload)),
    [REMOVE_SHARED_CHANNEL]: (state, { payload }) =>
      state.updateIn(["sharedChannels"], (array) => array.filter((channel) => channel.id !== payload)),
    [SET_SHARED_CHANNELS]: (state, { payload }) => state.merge({ sharedChannels: payload }),
    [SET_CHANNEL_PERMISSIONS]: (state, { payload }) => {
      const statePermissionData = state.get("permissions");

      return state.merge({ permissions: { ...statePermissionData, ...payload } });
    },
    [UPDATE_OWN_CHANNEL]: (state, { payload }) => {
      const oldState = state.get("ownChannels");

      const parsedOwnChannels = oldState.map((channel) => {
        if (channel.cloudId === payload.cloudId) {
          return { ...channel, ...payload };
        }

        return channel;
      });

      return state.merge({ ownChannels: parsedOwnChannels });
    },
    [CLEAR_CHANNELS]: () => defaultState,
  },
  defaultState
);

export const selectors = {
  getOwnChannels: (state) => state.getIn(["cloudChannels", "ownChannels"]),
  getSharedChannels: (state) => state.getIn(["cloudChannels", "sharedChannels"]),
  getAllChannels: (state) => state.getIn(["cloudChannels", "allChannels"]),
  getChannelPermissions: (state) => state.getIn(["cloudChannels", "permissions"]),
};

export default reducer;
