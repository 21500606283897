import parseChannelId from "./parseChannelId";
import parseConnectionMethodName from "./parseConnectionMethodName";

export default ({ filedKey, fieldValue }) => {
  switch (filedKey) {
    case "passphrase":
      return Array(fieldValue.length + 1).join("•");
    case "connectionType":
      return parseConnectionMethodName(fieldValue);
    case "cloudId":
      return parseChannelId(fieldValue);
    case "mode":
      return fieldValue.charAt(0).toUpperCase() + fieldValue.slice(1);
    case "type":
      return fieldValue.charAt(0).toUpperCase() + fieldValue.slice(1);
    case "triggerBitrateEnabled":
    case "triggerDisconnectEnabled":
    case "triggerConnectionTimeEnabled":
      return fieldValue ? "Enabled" : "Disabled";
    default:
      return fieldValue;
  }
};
