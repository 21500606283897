import React, { useState } from "react";
import { Row, Col, Card, Divider } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { CONNECTION_METHOD } from "../../../lib/utils/constants";

import ConnectionMethod from "../../../components/Fields/ConnectionMethod";
import InputStreamUdpSection from "../InputStreamSection/InputStreamUdpSection";
import InputStreamSrtSection from "../InputStreamSection/InputStreamSrtSection";
import InputStreamSectionBackupTriggers from "./InputStreamSectionBackupTriggers";

const SRT_METHODS = [
  CONNECTION_METHOD.srtCaller.value,
  CONNECTION_METHOD.srtRendezvous.value,
  CONNECTION_METHOD.srtListener.value,
];
const UDP_METHODS = [CONNECTION_METHOD.udpMulticast.value, CONNECTION_METHOD.udpUnicast.value];

const InputStreamSectionBackup = ({ initialValue, getFieldValue }) => {
  const backupConnectionMethodValue = getFieldValue(["input", "backup", "backupConnection", "connectionType"]);

  const [backupConnectionMethod, setBackupConnectionMethod] = useState(
    initialValue?.backupConnection?.connectionType || backupConnectionMethodValue || CONNECTION_METHOD.udpUnicast.value
  );

  const isUDPBackupMethod = UDP_METHODS.includes(backupConnectionMethod);
  const isSRTBackupMethod = SRT_METHODS.includes(backupConnectionMethod);

  return (
    <Card title={<FormattedMessage id="InputStreamSectionBackup.backup" defaultMessage="Backup" />}>
      <Row type="flex" justify="start" gutter={24}>
        <Col span={12}>
          <ConnectionMethod
            name={["input", "backup", "backupConnection", "connectionType"]}
            initialValue={initialValue?.backupConnection?.connectionType}
            setConnectionMethod={setBackupConnectionMethod}
            isBackup
          />
        </Col>
        <Divider />
        {isUDPBackupMethod && (
          <InputStreamUdpSection
            prefix={["input", "backup", "backupConnection"]}
            connectionMethod={backupConnectionMethod}
            initialValue={initialValue}
          />
        )}
        {isSRTBackupMethod && (
          <InputStreamSrtSection
            prefix={["input", "backup", "backupConnection"]}
            connectionMethod={backupConnectionMethod}
            initialValue={initialValue}
          />
        )}
      </Row>
      <InputStreamSectionBackupTriggers
        prefix={["input", "backup"]}
        initialValue={initialValue}
        getFieldValue={getFieldValue}
      />
    </Card>
  );
};

InputStreamSectionBackup.propTypes = {
  getFieldValue: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    backupConnection: PropTypes.object.isRequired,
    triggerBitrateEnabled: PropTypes.bool.isRequired,
    triggerBitrateLevel: PropTypes.number,
    triggerBitrateTimeSecs: PropTypes.number,
    triggerDisconnectEnabled: PropTypes.bool.isRequired,
    triggerConnectionTimeEnabled: PropTypes.bool.isRequired,
  }),
};

InputStreamSectionBackup.defaultProps = {
  initialValue: null,
};

export default InputStreamSectionBackup;
