import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Select, Tooltip, Form } from "antd";

import CloudDisableTooltip from "../../CloudDisableTooltip";
import ConnectionMethodLabel from "../../../pages/ChannelForm/Labels/ConnectionMethodLabel";

import {
  CONNECTION_METHOD,
  QUICKSTREAM_METHOD,
  DEMO_CONNECTION_METHOD,
  UDP_OUTPUT_METHOD,
  OUTPUT_TYPE,
} from "../../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;

const CONNECTION_METHOD_ARRAY = Object.values(CONNECTION_METHOD);

const ConnectionMethod = ({ disabled, name, initialValue, loggedToCloud, setConnectionMethod, isBackup }) => {
  const isOutput = name[0] === OUTPUT_TYPE;

  const CONNECTION_METHOD_INPUT = [
    ...CONNECTION_METHOD_ARRAY,
    ...(isBackup ? [] : [QUICKSTREAM_METHOD, ...DEMO_CONNECTION_METHOD]),
  ];
  const { udpUnicast, udpMulticast, ...rest } = CONNECTION_METHOD;
  const CONNECTION_METHOD_OUTPUT = [
    ...Object.values(rest),
    UDP_OUTPUT_METHOD,
    QUICKSTREAM_METHOD,
    DEMO_CONNECTION_METHOD[0],
  ];

  return (
    <Tooltip
      placement="top"
      title={
        initialValue === QUICKSTREAM_METHOD.value && !loggedToCloud ? (
          <CloudDisableTooltip
            title={
              <FormattedMessage
                id="general.disableEditQSMethod"
                defaultMessage="to Quickstream Cloud is needed to edit this channel"
              />
            }
          />
        ) : null
      }
    >
      <Item name={name} label={ConnectionMethodLabel(!!loggedToCloud, isBackup)}>
        <Select disabled={disabled} onChange={(value) => setConnectionMethod(value)}>
          {isOutput &&
            CONNECTION_METHOD_OUTPUT.map((option) => (
              <Option
                key={option.value}
                value={option.value}
                disabled={!loggedToCloud && option.value === QUICKSTREAM_METHOD.value}
              >
                {option.label}
              </Option>
            ))}
          {!isOutput &&
            CONNECTION_METHOD_INPUT.map((option) => (
              <Option
                key={option.value}
                value={option.value}
                disabled={!loggedToCloud && option.value === QUICKSTREAM_METHOD.value}
              >
                {option.label}
              </Option>
            ))}
        </Select>
      </Item>
    </Tooltip>
  );
};

ConnectionMethod.propTypes = {
  isBackup: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  initialValue: PropTypes.string,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  loggedToCloud: PropTypes.bool.isRequired,
  setConnectionMethod: PropTypes.func.isRequired,
};

ConnectionMethod.defaultProps = {
  isBackup: false,
  initialValue: "",
};

export default ConnectionMethod;
