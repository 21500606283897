import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

import { WEBSOCKET_STATUS } from "../lib/utils/constants";

export const actions = {
  CLEAR_NODE_CONNECTION: createAction("NODE/CLEAR_NODE_CONNECTION"),
  SET_NETWORKINTERFACES: createAction("NODE/NETWORKINTERFACES"),
  SET_NODE_CNN: createAction("NODE/SET_NODE_CNN"),
  SET_NODE_CONNECTION: createAction("NODE/SET_NODE_CONNECTION"),
  SET_NODE_FINGERPRINT: createAction("NODE/SET_NODE_FINGERPRINT"),
  SET_NODE_LICENSE_LIST: createAction("NODE/SET_NODE_LICENSE_LIST"),
  SET_NODE_START: createAction("NODE/SET_NODE_START"),
  SET_NODE_VERSION: createAction("NODE/SET_NODE_VERSION"),
  SET_SHORT_FINGERPRINT: createAction("NODE/SET_SHORT_FINGERPRINT"),
  SET_STUND_ADDRESS: createAction("NODE/SET_STUND_ADDRESS"),
};

const defaultState = new Map({
  cnn: null,
  cwid: null,
  fingerprint: "",
  licenseList: {},
  meta: null,
  networkInterfaces: [],
  nodeVersion: "",
  shortFingerprint: "",
  status: WEBSOCKET_STATUS.disconnected,
  stundAddress: "",
});

const reducer = handleActions(
  {
    [actions.SET_NODE_CONNECTION]: (state, { payload }) =>
      state.merge({ ...payload, status: WEBSOCKET_STATUS.connected }),
    [actions.SET_NODE_START]: (state) => state.merge({ status: WEBSOCKET_STATUS.connected }),
    [actions.SET_NODE_FINGERPRINT]: (state, { payload }) => state.merge({ fingerprint: payload }),
    [actions.SET_SHORT_FINGERPRINT]: (state, { payload }) => state.merge({ shortFingerprint: payload }),
    [actions.SET_NODE_CNN]: (state, { payload }) => state.merge({ cnn: payload }),
    [actions.SET_NETWORKINTERFACES]: (state, { payload }) => state.merge({ networkInterfaces: payload }),
    [actions.CLEAR_NODE_CONNECTION]: () => defaultState,
    [actions.SET_STUND_ADDRESS]: (state, { payload }) => state.merge({ stundAddress: payload }),
    [actions.SET_NODE_VERSION]: (state, { payload }) => state.merge({ nodeVersion: payload }),
    [actions.SET_NODE_LICENSE_LIST]: (state, { payload }) => {
      const oldNodeLicensesList = state.get("licenseList");

      return state.merge({ licenseList: { ...oldNodeLicensesList, [payload.from]: payload.list } });
    },
  },
  defaultState
);

export const selectors = {
  getNodeCwid: (state) => state.getIn(["node", "cwid"]),
  getStatus: (state) => state.getIn(["node", "status"]),
  getFingerprint: (state) => state.getIn(["node", "fingerprint"]),
  getShortFingerprint: (state) => state.getIn(["node", "shortFingerprint"]),
  getCnn: (state) => state.getIn(["node", "cnn"]),
  getNetworkInterfaces: (state) => state.getIn(["node", "networkInterfaces"]),
  getStundAddress: (state) => state.getIn(["node", "stundAddress"]),
  getNodeVersion: (state) => state.getIn(["node", "nodeVersion"]),
  getWrapperPublicKey: (state) => state.getIn(["node", "wrapperPublicKey"]),
  getNodeLicenses: (state) => state.getIn(["node", "licenseList"]),
};

export default reducer;
