import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";
import PropTypes from "prop-types";

import { history } from "../../store";

import themeColor from "../../lib/style/theme";

const CloudDisableTooltip = ({ title }) => {
  return (
    <>
      <StyledButton type="link" onClick={() => history.push("/guest/homepage")}>
        <FormattedMessage id="general.login" defaultMessage="Login" />
      </StyledButton>
      {title}
    </>
  );
};

CloudDisableTooltip.propTypes = {
  title: PropTypes.object.isRequired,
};

const StyledButton = styled(Button)`
  color: ${themeColor.orange};
  padding-right: 4px;
  padding-left: 0px;
`;

export default CloudDisableTooltip;
