import React from "react";
import { Modal, Divider, Row, Col, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import iconUrl from "../../lib/images/icon128x128.png";

import themeColor from "../../lib/style/theme";

const { Title, Text } = Typography;
const year = moment().year();

const About = ({ handleShowModal, showModal }) => {
  return (
    <Modal
      title={<FormattedMessage id="About.title" defaultMessage="About Quickstream" />}
      visible={showModal}
      footer={null}
      onCancel={handleShowModal}
    >
      <Row type="flex" justify="center" gutter={12}>
        <Col span={6}>
          <StyledIcon />
        </Col>
        <Col span={18}>
          <Title level={2}>
            <FormattedMessage id="About.quickstream" defaultMessage="Quickstream" />
          </Title>
          <StyledDiv>
            <Text strong>
              <FormattedMessage id="About.pleaseCheckOur" defaultMessage="Please check our" />{" "}
            </Text>
            <StyledLink href="https://quickstream.tech/faq" target="_blank" rel="noopener noreferrer">
              FAQ
            </StyledLink>{" "}
            <Text strong>
              <FormattedMessage id="general.and" defaultMessage="and" />
            </Text>{" "}
            <StyledLink
              href="https://help.quickstream.tech/portal/kb/quickstream"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="About.knowledgeBase" defaultMessage="knowledge base " />
            </StyledLink>
          </StyledDiv>
          <div>
            <div>
              <Text strong>
                <FormattedMessage id="About.directContact" defaultMessage="For direct contact:" />
              </Text>
            </div>
            <div>
              <Text strong>
                <FormattedMessage id="general.www" defaultMessage="WWW:" />{" "}
              </Text>
              <StyledLink href="https://quickstream.tech/" target="_blank" rel="noopener noreferrer">
                www.quickstream.tech
              </StyledLink>
            </div>
            <div>
              <Text strong>
                <FormattedMessage id="general.email" defaultMessage="email:" />{" "}
              </Text>
              <StyledLink href="mailto:hello@quickstream.tech">hello@quickstream.tech</StyledLink>
            </div>
          </div>
        </Col>
      </Row>
      <Divider />
      <div>
        <span>{`© 2018–${year} tvhive sp. z o.o.`}</span>{" "}
        <FormattedMessage id="About.footer" defaultMessage="All rights reserved." />
      </div>
    </Modal>
  );
};

const StyledDiv = styled.div`
  margin-bottom: 10px;
`;

const StyledIcon = styled.div`
  width: 100px;
  height: 100px;
  background: url(${iconUrl});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

const StyledLink = styled.a`
  color: ${themeColor.yellowLink};

  &:hover {
    color: ${themeColor.yellowLinkHover};
  }
`;

About.propTypes = {
  handleShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default About;
