import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

import { NODE_CHANEL_TABS } from "../lib/utils/constants";

export const actions = {
  SET_ACTIVE_SUBMENU: createAction("NAVIGATION/SET_ACTIVE_SUBMENU"),
  SET_ACTIVE_KEY: createAction("NAVIGATION/SET_ACTIVE_KEY"),
  SET_CHANNELS_ACTIVE_TAB: createAction("NAVIGATION/SET_CHANNELS_ACTIVE_KEY"),
  CLEAR_ACTIVE_KEY: createAction("NAVIGATION/CLEAR_ACTIVE_KEY"),
  CLEAR_NAVIGATION: createAction("NAVIGATION/CLEAR"),
  CLEAR_ACTIVE_SUBMENU: createAction("NAVIGATION.CLEAR_ACTIVE_SUBMENU"),
};

const initialState = new Map({
  activeKey: [],
  activeSubmenu: ["/cloudNodes"],
  channelsActiveTab: NODE_CHANEL_TABS.channelList,
});

const reducer = handleActions(
  {
    [actions.SET_ACTIVE_SUBMENU]: (state, { payload }) => state.merge({ ...payload }),
    [actions.SET_ACTIVE_KEY]: (state, { payload }) => state.merge(payload),
    [actions.SET_CHANNELS_ACTIVE_TAB]: (state, { payload }) => state.merge({ ...payload }),
    [actions.CLEAR_ACTIVE_KEY]: (state) => state.merge({ activeKey: [] }),
    [actions.CLEAR_ACTIVE_SUBMENU]: (state) => state.merge({ activeSubmenu: ["/cloudNodes"] }),
    [actions.CLEAR_NAVIGATION]: () => initialState,
  },
  initialState
);

export const selectors = {
  getActiveNavKey: (state) => state.getIn(["navigation", "activeKey"]),
  getActiveNavSubmenu: (state) => state.getIn(["navigation", "activeSubmenu"]),
  getChannelsActiveTab: (state) => state.getIn(["navigation", "channelsActiveTab"]),
};

export default reducer;
