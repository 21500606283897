/* eslint-disable max-classes-per-file */
import { camelizeKeys } from "humps";
import moment from "moment";
import { defineMessages } from "react-intl";

import store, { history } from "../../store";
import { actions as ACCOUNT_ACTIONS } from "../../ducks/account";
import { actions as NAVIGATION_ACTIONS } from "../../ducks/navigation";
import { actions as LICENSE_ACTIONS } from "../../ducks/license";

import parseJwt from "../utils/parseJwt";
import getUserFromJWToken from "../utils/getUserFromJWToken";
import getMessageFromErrorCode from "../utils/getMessageFromErrorCode";
import { RECAPTCHA_ERROR } from "../utils/constants";

const translations = defineMessages({
  deactivatedUser: {
    id: "API.deactivatedUser",
    defaultMessage: "User was deactivated - please contact with admin",
  },
  logoutUser: {
    id: "API.logout",
    defaultMessage: "User was logged out",
  },
});

const buildUrl = (path) => {
  const API_ROOT = process.env.REACT_APP_API;
  const API_PORT = process.env.REACT_APP_API_PORT;

  return path.indexOf(API_ROOT) === -1 ? `${API_ROOT}:${API_PORT}${path}` : path;
};

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

function checkTokenExpireDate(token) {
  if (!token && window && window.exp) return false;

  const parsedToken = parseJwt(token);
  const tokenExpTimestamp = parsedToken.exp;

  const expireSoon = moment().add(2, "hours").utc().unix();

  return tokenExpTimestamp < expireSoon;
}

function tokenIsActive(token) {
  if (!token) return false;

  const parsedToken = parseJwt(token);

  const tokenExpTimestamp = parsedToken.exp;
  const currentTimestamp = moment().utc().unix();

  if (window && window.exp) return false;

  return tokenExpTimestamp > currentTimestamp;
}

function logoutUser(errorNotification) {
  window.localStorage.removeItem("authToken");
  store.dispatch(ACCOUNT_ACTIONS.CLEAR_ACCOUNT_DATA());
  store.dispatch(NAVIGATION_ACTIONS.CLEAR_ACTIVE_KEY());
  store.dispatch(LICENSE_ACTIONS.CLEAR_LICENSE());
  errorNotification(translations.logoutUser);
}

const request = async ({ method, path, body, errorNotification }) => {
  const url = path.includes("http") ? path : buildUrl(path);
  const token = window.localStorage.getItem("authToken");

  const active = tokenIsActive(token);

  if (token && !active) {
    logoutUser(errorNotification);

    return Promise.reject(new Error("Your session expired or you do not have access for this resources."));
  }

  if (token && active) {
    headers.Authorization = `Bearer ${token}`;
  } else {
    delete headers.Authorization;
  }

  const response = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
  });

  const tokenExpireSoon = checkTokenExpireDate(token);

  if (tokenExpireSoon) {
    AuthAPI.rotateToken({ errorNotification });
  }

  const json = await response.json();

  if (!response.ok) {
    if (response.status === 417) {
      errorNotification(translations.deactivatedUser);
      logoutUser(errorNotification);
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      ...json,
      statusCode: response.status, // statusCode is deprecated.
      status: response.status,
    });
  }

  return camelizeKeys(json);
};

export const get = (path, errorNotification) => request({ method: "GET", path, errorNotification });
export const post = (path, body, errorNotification) => request({ method: "POST", path, body, errorNotification });
export const patch = (path, body) => request({ method: "PATCH", path, body });
export const put = (path, body, errorNotification) => request({ method: "PUT", path, body, errorNotification });
export const destroy = (path, errorNotification) => request({ method: "DELETE", path, errorNotification });

export class AuthAPI {
  static HOST = process.env.REACT_APP_API;

  static PORT = process.env.REACT_APP_API_PORT;

  static login({ data, header }, { errorNotification }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${this.HOST}:${this.PORT}/web/auth`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...header,
          },
          body: JSON.stringify(data),
        });

        const json = await response.json();

        if (!response.ok) {
          const errorMessage = getMessageFromErrorCode(response.status);
          errorNotification(errorMessage);

          if (response.status === RECAPTCHA_ERROR) {
            history.push("/login/2fa");
          }
        }

        resolve(json);
      } catch (error) {
        reject(error);
      }
    });
  }

  static createUser(data, { errorNotification }) {
    return post(`${this.HOST}:${this.PORT}/user`, data, errorNotification);
  }

  static invitationsAccount = (data, { errorNotification }) => {
    return post(`${this.HOST}:${this.PORT}/account/invite`, data, errorNotification);
  };

  static createUserInvitation(data, { errorNotification }) {
    return post(`${this.HOST}:${this.PORT}/account/activation/invitation`, data, errorNotification);
  }

  static forgotPassword(data, { errorNotification }) {
    return put(`${this.HOST}:${this.PORT}/account/password/forgot`, data, errorNotification);
  }

  static auth2faLogin(data, { errorNotification }) {
    return put(`${this.HOST}:${this.PORT}/web/auth/2fa`, data, errorNotification);
  }

  static auth2fa(data, { errorNotification }) {
    return post(`${this.HOST}:${this.PORT}/web/auth/2fa`, data, errorNotification);
  }

  static accountActivation(data, { errorNotification }) {
    return post(`${this.HOST}:${this.PORT}/account/activation`, data, errorNotification);
  }

  static editPassword(data, { errorNotification }) {
    return put(`${this.HOST}:${this.PORT}/account/password`, data, errorNotification);
  }

  static editEmailOptIn(data, { errorNotification }) {
    return put(`${this.HOST}:${this.PORT}/account/optIn`, data, errorNotification);
  }

  static resetPassword(data, { errorNotification }) {
    return put(`${this.HOST}:${this.PORT}/account/password/reset`, data, errorNotification);
  }

  static editUsername(data, { errorNotification }) {
    return put(`${this.HOST}:${this.PORT}/account/username`, data, errorNotification);
  }

  static deleteCurrentAccount({ errorNotification }) {
    return destroy(`${this.HOST}:${this.PORT}/user`, errorNotification);
  }

  static getAccountList({ errorNotification }) {
    return get(`${this.HOST}:${this.PORT}/admin/users`, errorNotification);
  }

  static deleteAccount(userId, { errorNotification }) {
    return destroy(`${this.HOST}:${this.PORT}/admin/remove/${userId}`, errorNotification);
  }

  static rotateToken = async ({ errorNotification }) => {
    try {
      const { token } = await get(`${this.HOST}:${this.PORT}/auth/check`, errorNotification);
      store.dispatch(ACCOUNT_ACTIONS.SAVE_ACCOUNT_DATA({ ...getUserFromJWToken(token), token }));

      window.localStorage.setItem("authToken", token);
      store.dispatch(ACCOUNT_ACTIONS.INITIALIZE({ token }));
    } catch (error) {
      const errorMessage = getMessageFromErrorCode(error.status);
      errorNotification(errorMessage);
      logoutUser(errorNotification);
    }
  };
}

export class LicenseAPI {
  static HOST = process.env.REACT_APP_LICENSE_API;

  static PORT = process.env.REACT_APP_LICENSE_API_PORT;

  static getLicense({ errorNotification }) {
    return get(`${this.HOST}:${this.PORT}/license`, errorNotification);
  }

  static createLicense(data, { errorNotification }) {
    return post(`${this.HOST}:${this.PORT}/license`, { ...data }, errorNotification);
  }

  static extendLicense(data, { errorNotification }) {
    return put(`${this.HOST}:${this.PORT}/license`, { ...data }, errorNotification);
  }

  static uniqLicense(licenseId, { errorNotification }) {
    return get(`${this.HOST}:${this.PORT}/license/unique/${licenseId}`, errorNotification);
  }

  static deleteLicense(licenseId, { errorNotification }) {
    return destroy(`${this.HOST}:${this.PORT}/admin/license/remove/${licenseId}`, errorNotification);
  }
}

export class PlatformAPI {
  static HOST = process.env.REACT_APP_PLATFORM_API;

  static PORT = process.env.REACT_APP_PLATFORM_API_PORT;

  static getAllChannelsList = ({ errorNotification }) => {
    return get(`${this.HOST}:${this.PORT}/channels`, errorNotification);
  };

  static getSharedChannelsList = ({ errorNotification }) => {
    return get(`${this.HOST}:${this.PORT}/channels/shared`, errorNotification);
  };

  static getChannelPermissions = (id, { errorNotification }) => {
    return get(`${this.HOST}:${this.PORT}/channel/permissions/${id}`, errorNotification);
  };

  static editNodeHostname = (data, { errorNotification }) => {
    return put(`${this.HOST}:${this.PORT}/node/edit`, data, errorNotification);
  };

  static getChannelPermissionsDescription = (cloudId, { errorNotification }) => {
    return get(`${this.HOST}:${this.PORT}/channel/descriptions/${cloudId}`, errorNotification);
  };

  static removeChannelPermission = (permissionId, { errorNotification }) => {
    return put(
      `${this.HOST}:${this.PORT}/channel/permissions/remove/${permissionId}`,
      { delete: true },
      errorNotification
    );
  };

  static activateChannelPermission = (data, { errorNotification }) => {
    return put(`${this.HOST}:${this.PORT}/channel/permissions/activate`, data, errorNotification);
  };

  static getOwnChannelsList = ({ errorNotification }) => {
    return get(`${this.HOST}:${this.PORT}/channels/own`, errorNotification);
  };

  static createChannel = (data, { errorNotification }) => {
    return post(`${this.HOST}:${this.PORT}/channel/new`, data, errorNotification);
  };

  static invitationsPermission = (data, { errorNotification }) => {
    return post(`${this.HOST}:${this.PORT}/channel/invite`, data, errorNotification);
  };

  static editCloudChannel = (data, { errorNotification }) => {
    return put(`${this.HOST}:${this.PORT}/channel/edit`, data, errorNotification);
  };

  static removeChannel = (cloudId, { errorNotification }) => {
    return destroy(`${this.HOST}:${this.PORT}/channel/remove/${cloudId}`, errorNotification);
  };

  static addChannelPermission = (data, { errorNotification }) => {
    return post(`${this.HOST}:${this.PORT}/channel/permissions`, data, errorNotification);
  };

  static checkUser = (data, { errorNotification }) => {
    return post(`${this.HOST}:${this.PORT}/user/check`, data, errorNotification);
  };

  static connectFingerprintToChannel = (data, { errorNotification }) => {
    return put(`${this.HOST}:${this.PORT}/channels/own/connect`, data, errorNotification);
  };

  static connectFingerprintToPermission = (data, { errorNotification }) => {
    return put(`${this.HOST}:${this.PORT}/channels/shared/connect`, data, errorNotification);
  };

  static removeFingerprintFromPermission = (data, { errorNotification }) => {
    return put(`${this.HOST}:${this.PORT}/channels/shared/remove`, data, errorNotification);
  };

  static removeFingerprintFromChannel = (data, { errorNotification }) => {
    return put(`${this.HOST}:${this.PORT}/channels/own/remove`, data, errorNotification);
  };

  static addNewCWID = (data, { errorNotification }) => {
    return post(`${this.HOST}:${this.PORT}/node/new`, data, errorNotification);
  };
}
