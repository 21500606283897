import React from "react";
import { Card } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

import { selectors as CHANNEL_SELECTORS } from "../../ducks/nodeChannels";

import ChannelForm from "../ChannelForm";

const ChannelEdit = ({ channels, match }) => {
  const { id } = match.params;
  const channelConfig = channels.getIn([`${id}`, "config"]);

  return (
    <Card>
      <ChannelForm channelConfig={channelConfig} editMode />
    </Card>
  );
};

ChannelEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  channels: ImmutablePropTypes.map.isRequired,
};

const mapStateToProps = (state) => ({
  channels: CHANNEL_SELECTORS.getChannels(state),
});

export default connect(mapStateToProps, null)(ChannelEdit);
