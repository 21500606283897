/* eslint-disable max-len */
import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Divider } from "antd";

import themeColor from "../../lib/style/theme";

const OptinTips = () => {
  return (
    <>
      <div>
        <StyledTips>
          <FormattedMessage
            id="OptinTips.tips1"
            defaultMessage="I hereby consent to the processing of my personal data provided by me in the extent of an e-mail address by tvhive sp.z o.o. with its registered office at Kraków (30-504) ul. Kalwaryjska 69, 30-504, NIP: 6793185462 for marketing purposes. The consent granted may be withdrawn at any time"
          />
          .
        </StyledTips>
      </div>
      <div>
        <StyledTips>
          <FormattedMessage
            id="OptinTips.tips2"
            defaultMessage="I hereby consent to the sending of commercial information by electronic means of communication by or on behalf of tvhive sp. z o.o. with its registered office at Krakow (30-504), ul. Kalwaryjska 69, NIP: 6793185462. The consent granted may be withdrawn at any time"
          />
          .
        </StyledTips>
      </div>
      <div>
        <StyledTips>
          <FormattedMessage
            id="OptinTips.tips3"
            defaultMessage="I hereby consent to the use of telecommunications terminal equipment of which I am a user (telephone, computer) and automatic calling systems for the purposes of direct marketing carried out by or on behalf of tvhive sp.z o.o. with its registered office at Krakow (30-504), ul. Kalwaryjska 69, NIP: 6793185462. The consent granted may be withdrawn at any time"
          />
          .
        </StyledTips>
      </div>
      <Divider />
      <div>
        <StyledTips>
          <FormattedMessage
            id="OptinTips.tips4"
            defaultMessage="The administrator of personal data is thive sp.z o.o. with its registered office at Krakow (30-504), ul. Kalwaryjska 69. Personal data are being processed in order to conclude an agreement, provide services and for the purposes specified in the provided consent. The full content of the information clause is available in the"
          />
          <StyledLink type="link" target="_blank" href="https://quickstream.tech/cloud-terms">
            <FormattedMessage id="OptinTips.termsAndConditions" defaultMessage="Terms and Conditions" />
          </StyledLink>{" "}
          <FormattedMessage
            id="OptinTips.tips5"
            defaultMessage="available for the User at the time of setting up the account on the Website"
          />
          .
        </StyledTips>
      </div>
    </>
  );
};

const StyledLink = styled.a`
  margin: 0 0 0 3px;
  color: ${themeColor.orange};

  &:hover {
    color: ${themeColor.orangeHover};
  }
`;

const StyledTips = styled.span`
  font-size: 10px;
  color: ${themeColor.greyTips};
`;

export default OptinTips;
