import { camelizeKeys } from "humps";
import { notification } from "antd";
import moment from "moment";

import store, { history } from "../store";

import { actions as CLOUD_CONNECTION_ACTIONS } from "../ducks/cloudConnection";
import { actions as LOADING_ACTIONS } from "../ducks/loadingData";
import { actions as NODE_ACTIONS } from "../ducks/node";
import { actions as NAVIGATION_ACTIONS } from "../ducks/navigation";
import { SET_SYSTEM_STATS } from "../ducks/nodeSystemStatistics";
import {
  SET_HIST_STATS_IN_MIN,
  SET_HIST_OUT_PERMISSION_IDS,
  SET_HIST_STATS_OUT_MIN,
  CLEAR_HIST_STATISTIC,
  SET_HIST_STATS_IN,
  SET_HIST_STATS_OUT,
} from "../ducks/nodeStatistics";

// eslint-disable-next-line import/no-cycle
import NodeService from "./node";

import { MESSAGE_TYPE, WEBSOCKET_STATUS } from "../lib/utils/constants";
import NodeTranslations from "../lib/translations/nodeTranslations";
import CloudTranslations from "../lib/translations/cloudTranslations";
import roundNumber from "../lib/utils/roundNumber";

class WebsocketHandler {
  socket = null;

  timeout = null;

  initialize = () => {
    const HOST = process.env.REACT_APP_PLATFORM_API.split("://");

    return new Promise((resolve, reject) => {
      // Create WebSocket connection.
      this.socket = new WebSocket(`wss://${HOST[1]}`);

      this.timeout = setTimeout(() => {
        this.cloudNotificationHandler({ cloudMsgType: MESSAGE_TYPE.ERROR, errorCode: 1 });
        reject();
      }, 8000);

      this.socket.onopen = () => {
        this.socket.onmessage = this.messageHandler;
        resolve();
        clearTimeout(this.timeout);

        const token = window.localStorage.getItem("authToken");
        const username = store.getState().getIn(["account", "username"]);
        const cwid = username;
        store.dispatch(CLOUD_CONNECTION_ACTIONS.SET_CONNECTION());
        this.socket.send(
          JSON.stringify({
            cloudMsgType: "clientAuth",
            cwid,
            token,
          })
        );
      };

      this.socket.onclose = () => {
        const cloudConnectionStatus = store.getState().getIn(["cloudConnection", "status"]);

        if (cloudConnectionStatus === WEBSOCKET_STATUS.connected) {
          this.cloudNotificationHandler({ cloudMsgType: MESSAGE_TYPE.WARNING, errorCode: "cloudDisconnection" });
          history.push("/login");
        }

        store.dispatch(CLOUD_CONNECTION_ACTIONS.DISCONNECT());
        store.dispatch(NODE_ACTIONS.CLEAR_NODE_CONNECTION());
      };

      this.socket.onerror = (error) => {
        clearTimeout(this.timeout);
        store.dispatch(CLOUD_CONNECTION_ACTIONS.DISCONNECT());
        reject(new Error(`Cannot connect to Websocket: ${error?.target?.url}`));
      };
    });
  };

  nodeErrorHandle = ({ error }) => {
    this.nodeNotificationErrorHandler({ error });
  };

  setDefaultTranslation = (type) => {
    switch (type) {
      case MESSAGE_TYPE.ERROR:
        return NodeTranslations[7000];
      default:
        return NodeTranslations[0];
    }
  };

  nodeNotificationErrorHandler = ({ error }) => {
    const getErrorCode = () => {
      switch (error.msgType) {
        case MESSAGE_TYPE.ERROR:
          return error.errorCode;
        default:
          return `warning-${error.warningCode}`;
      }
    };

    const errorMessage = NodeTranslations[getErrorCode()] || this.setDefaultTranslation(error.msgType);

    notification[error.msgType]({
      message: errorMessage.defaultMessage,
    });
  };

  cloudNotificationHandler = ({ cloudMsgType, errorCode, extraMsg }) => {
    const {
      1: { defaultMessage },
    } = CloudTranslations;
    const translation = CloudTranslations[errorCode];

    if (extraMsg) {
      notification[cloudMsgType]({
        message: `${translation?.defaultMessage} ${extraMsg} ` || defaultMessage,
      });
    } else {
      notification[cloudMsgType]({
        message: translation?.defaultMessage || defaultMessage,
      });
    }
  };

  nodeNotificationHandler = ({ msgType, message }) => {
    const translation = NodeTranslations[message];

    notification[msgType]({
      message: translation.defaultMessage,
    });
  };

  showWarningNotification = ({ message }) => {
    const {
      0: { defaultMessage },
    } = NodeTranslations;

    notification[MESSAGE_TYPE.WARNING]({
      message: message || defaultMessage,
    });
  };

  messageHandler = (event) => {
    const message = camelizeKeys(JSON.parse(event?.data));
    const cloudMsgType = message?.cloudMsgType;
    const nodeMsgType = message?.msgType;

    if (cloudMsgType) {
      switch (cloudMsgType) {
        case MESSAGE_TYPE.CLIENT_AUTHORIZATION:
          store.dispatch(CLOUD_CONNECTION_ACTIONS.SET_CONNECTION_AUTH(message?.cwid));
          this.cloudNotificationHandler({ cloudMsgType: MESSAGE_TYPE.SUCCESS, errorCode: "cloudConnected" });
          break;
        case MESSAGE_TYPE.GET_NODES:
          store.dispatch(CLOUD_CONNECTION_ACTIONS.SET_NODES(message.nodes));
          store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
          break;
        case MESSAGE_TYPE.NODE_UPDATE_STATUS:
          // eslint-disable-next-line no-case-declarations
          const connectedNodeCwid = store.getState().getIn(["node", "cwid"]);

          if (message.status === WEBSOCKET_STATUS.disconnected && connectedNodeCwid === message.from) {
            store.dispatch(NAVIGATION_ACTIONS.CLEAR_ACTIVE_SUBMENU());
            store.dispatch(NODE_ACTIONS.CLEAR_NODE_CONNECTION());
            this.nodeNotificationHandler({ msgType: MESSAGE_TYPE.ERROR, message: "nodeDisconnected" });
            history.push("/shares");
          }

          store.dispatch(CLOUD_CONNECTION_ACTIONS.NODE_UPDATE_STATUS({ cwid: message.from, status: message.status }));
          break;
        case MESSAGE_TYPE.ERROR:
          this.cloudNotificationHandler({ cloudMsgType: message.cloudMsgType, errorCode: message.errorCode });
          break;
        case MESSAGE_TYPE.WARNING:
          this.cloudNotificationHandler({ cloudMsgType: message.cloudMsgType, errorCode: message.errorCode });
          break;
        default:
          // eslint-disable-next-line no-console
          console.log("default-messageHandler", message);
      }
    }

    if (nodeMsgType) {
      const connectedNodeCwid = store.getState().getIn(["node", "cwid"]);

      switch (nodeMsgType) {
        case MESSAGE_TYPE.LOG_LEVEL:
          NodeService.updateLogLevel(message);
          store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
          break;
        case MESSAGE_TYPE.GET_LOGS:
          NodeService.loadMoreLogData(message);
          break;
        case MESSAGE_TYPE.NEW_NODE_CHANNEL_LOG:
          NodeService.newLogData(message);
          break;
        case MESSAGE_TYPE.UPDATE_LOGS:
          NodeService.deleteLogs();
          break;
        case MESSAGE_TYPE.CHANNEL_STATUS:
          if (connectedNodeCwid === message.from) {
            NodeService.updateChannel(message);
          }
          break;
        case MESSAGE_TYPE.GET_HOST_NAME:
          store.dispatch(CLOUD_CONNECTION_ACTIONS.NODE_UPDATE_CNN(message));
          store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
          break;
        case MESSAGE_TYPE.SET_HOSTNAME:
          store.dispatch(CLOUD_CONNECTION_ACTIONS.NODE_UPDATE_CNN(message));

          if (connectedNodeCwid === message.from) {
            store.dispatch(NODE_ACTIONS.SET_NODE_CNN(message?.hostname));
            history.push(`/cloudNodes/${message?.from}/${message?.hostname}/system`);
          }
          break;
        case MESSAGE_TYPE.UPDATE_CHANNELS:
          NodeService.updateChannelsList(message);
          break;
        case MESSAGE_TYPE.NETWORK_INTERFACES:
          NodeService.setNetworkInterfaces(message?.interfaces);
          break;
        case MESSAGE_TYPE.NODE_LICENSE:
          NodeService.setNodeLicenseList(message);
          break;
        case MESSAGE_TYPE.STUND_ADDRESS:
          NodeService.setStundAddress(message?.addr);
          break;
        case MESSAGE_TYPE.FINGERPRINT:
          NodeService.setFingerprint(message?.fingerprint);
          break;
        case MESSAGE_TYPE.SHORT_FINGERPRINT:
          NodeService.setShortFingerprint(message?.shortFingerprint);
          break;
        case MESSAGE_TYPE.ERROR:
          this.nodeErrorHandle({ error: message });
          break;
        case MESSAGE_TYPE.WARNING:
          this.nodeNotificationErrorHandler({ error: message });
          break;
        case MESSAGE_TYPE.NODE_CHANNEL_STATISTIC:
          NodeService.updateChannelStatistic(message);
          break;
        case MESSAGE_TYPE.SET_HIST_STATS_IN_MIN:
          store.dispatch(SET_HIST_STATS_IN_MIN(message?.minTimestamp));
          store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
          break;
        case MESSAGE_TYPE.SET_HIST_STATS_OUT_INIT:
          store.dispatch(SET_HIST_OUT_PERMISSION_IDS(message?.permissionIdList));
          store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
          break;
        case MESSAGE_TYPE.SET_HIST_STATS_OUT_MIN:
          store.dispatch(SET_HIST_STATS_OUT_MIN(message?.minTimestamp));
          break;
        case MESSAGE_TYPE.NODE_STATS:
          store.dispatch(
            SET_SYSTEM_STATS({
              ...message,
              totalIncoming: roundNumber(message.totalIncoming),
              totalOutgoing: roundNumber(message.totalOutgoing),
              time: moment.unix(message.time).format("HH:mm:ss DD-MM-YYYY"),
            })
          );
          break;

        case MESSAGE_TYPE.SET_HIST_STATS_IN:
          if (message?.error) {
            this.nodeNotificationHandler({ msgType: "error", message: message?.error });
            store.dispatch(CLEAR_HIST_STATISTIC());

            return;
          }

          store.dispatch(SET_HIST_STATS_IN(message?.data));
          store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
          break;
        case MESSAGE_TYPE.SET_HIST_STATS_OUT:
          if (message?.error) {
            this.nodeNotificationHandler({ msgType: "error", message: message?.error });
            store.dispatch(CLEAR_HIST_STATISTIC());

            return;
          }

          store.dispatch(SET_HIST_STATS_OUT(message?.data));
          store.dispatch(LOADING_ACTIONS.CLEAR_LOADING());
          break;
        default:
          // eslint-disable-next-line no-console
          console.log("default-messageHandler", message);
      }
    }
  };

  disconnect = async () => {
    if (this.socket) {
      await this.socket.close();
    }
  };

  sendMessage = (message) => {
    this.socket.send(message);
  };
}

export default new WebsocketHandler();
