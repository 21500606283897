import React from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { connect } from "react-redux";
import moment from "moment";

import { selectors as STATISTIC_SELECTORS } from "../../../ducks/nodeStatistics";

import themeColor from "../../../lib/style/theme";

const StatisticHistoricalScaleInfo = ({ statMin }) => (
  <Row>
    <InfoContainer span={24}>
      <InfoCircleOutlined />{" "}
      <FormattedMessage
        id="StatisticHistoricalScaleInfo.minTimeRange"
        defaultMessage="The oldest data available for this scale"
      />
      {` ${moment.unix(statMin).format("YYYY-MM-DD HH:mm")}`}
    </InfoContainer>
  </Row>
);

const InfoContainer = styled(Col)`
  color: ${themeColor.orange};
`;

StatisticHistoricalScaleInfo.propTypes = {
  statMin: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  statInMin: STATISTIC_SELECTORS.getStatisticInMin(state),
  statOutMin: STATISTIC_SELECTORS.getStatisticOutMin(state),
});

export default connect(mapStateToProps, null)(StatisticHistoricalScaleInfo);
