import React from "react";
import { FormattedMessage } from "react-intl";
import { ReloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import PropTypes from "prop-types";

const LoadingReCaptcha = ({ checkReCaptcha }) => {
  return (
    <>
      <FormattedMessage id="RegistrationForm.loadingReCaptcha" defaultMessage="Loading reCaptcha..." />{" "}
      <Button shape="round" type="primary" icon={<ReloadOutlined />} onClick={() => checkReCaptcha()}>
        <span>
          <FormattedMessage id="RegistrationForm.reload" defaultMessage="Reload" />
        </span>
      </Button>
    </>
  );
};

LoadingReCaptcha.propTypes = {
  checkReCaptcha: PropTypes.func.isRequired,
};

export default LoadingReCaptcha;
