import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../ducks/cloudChannels";

import { CHANNEL_SOURCE, CONNECTION_METHOD } from "../../lib/utils/constants";

const ChannelPermissionLabel = ({ channelId, connectionType, permissions, permissionId }) => {
  if (permissionId === +CHANNEL_SOURCE.wrapperChannel) {
    const UDP_METHODS = [CONNECTION_METHOD.udpMulticast.value, CONNECTION_METHOD.udpUnicast.value];
    const SRT_METHODS = [
      CONNECTION_METHOD.srtCaller.value,
      CONNECTION_METHOD.srtRendezvous.value,
      CONNECTION_METHOD.srtListener.value,
    ];
    if (UDP_METHODS.includes(connectionType)) {
      return <FormattedMessage id="ChannelPermissionLabel.udpOutput" defaultMessage="UDP Output" />;
    }

    if (SRT_METHODS.includes(connectionType)) {
      return <FormattedMessage id="ChannelPermissionLabel.srtOutput" defaultMessage="SRT Output" />;
    }
  }

  const permissionDataExist = permissions && !!permissions[channelId];
  if (permissionDataExist) {
    const permissionDescriptionArray = permissions[channelId];
    const descriptionData = permissionDescriptionArray.find((permission) => +permission.id === +permissionId);

    return `${descriptionData.description} (${descriptionData.username})`;
  }

  return `Missing cloud data id: ${permissionId}`;
};

ChannelPermissionLabel.propTypes = {
  connectionType: PropTypes.string.isRequired,
  permissionId: PropTypes.number.isRequired,
  permissions: PropTypes.object,
};

export const mapStateToProps = (state) => ({
  permissions: CLOUD_CHANNELS_SELECTORS.getChannelPermissions(state),
});

export default connect(mapStateToProps)(ChannelPermissionLabel);
