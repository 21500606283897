import React from "react";
import CookieConsent from "react-cookie-consent";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import themeColor from "../../lib/style/theme";

const CookieConsentContainer = ({ setCookieAccepted }) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText={<FormattedMessage id="general.Accept" defaultMessage="Accept" />}
      cookieName="QuickstreamCloudCookieApproval"
      style={{ background: "#000" }}
      buttonStyle={{ background: `${themeColor.orange}`, color: "#FFF", fontSize: "14px" }}
      onAccept={() => setCookieAccepted(true)}
    >
      <StyledCookieTitle>
        <FormattedMessage id="CookieConsent.cookies" defaultMessage="Cookies" />
      </StyledCookieTitle>
      <StyledParagraph>
        <span>
          <FormattedMessage
            id="CookieConsent.Info1"
            defaultMessage="The website administrator (tvhive sp. z o.o. with its registered office at Krakow (30-504), ul. Kalwaryjska 69, NIP: 6793185462) uses cookies. Information on the types of cookies used, the purposes, methods and period of their use is specified in the"
          />{" "}
        </span>
        <StyledLink href="https://quickstream.tech/privacypolicy" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="CookieConsent.privacyPolicy" defaultMessage="Privacy Policy" />
        </StyledLink>
        .
      </StyledParagraph>
    </CookieConsent>
  );
};

const StyledParagraph = styled.div`
  margin-left: 10px;
`;

const StyledLink = styled.a`
  color: ${themeColor.yellowLink};

  &:hover {
    color: ${themeColor.yellowLinkHover};
  }
`;

const StyledCookieTitle = styled.h3`
  color: ${themeColor.orange};
`;

CookieConsentContainer.propTypes = {
  setCookieAccepted: PropTypes.func.isRequired,
};

export default CookieConsentContainer;
