import React from "react";
import { useMountEffect } from "@reonomy/reactive-hooks";
import { DeleteOutlined, QuestionCircleOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Card, Table, Button, Popconfirm, Divider } from "antd";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { history } from "../../store";

import CloudChannelsServices from "../../services/cloudChannels";
import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../ducks/cloudChannels";

import { errorNotification, successNotification } from "../../lib/utils/notification";
import parseCloudId from "../../lib/utils/parseCloudId";

import EditSharedChannelModal from "./ChannelModal/EditSharedChannelModal";

const translations = defineMessages({
  channelName: {
    id: "OwnChannelList.channelName",
    defaultMessage: "Share name",
  },
  id: {
    id: "OwnChannelList.id",
    defaultMessage: "ID",
  },
  owner: {
    id: "OwnChannelList.owner",
    defaultMessage: "Owner",
  },
  actions: {
    id: "general.actions",
    defaultMessage: "Actions",
  },
});

const OwnChannelList = ({ ownChannelsList }) => {
  const { formatMessage } = useIntl();

  async function fetchChannels() {
    CloudChannelsServices.getOwnChannels({ errorNotification: errorNotification(formatMessage) });
  }

  useMountEffect(() => {
    fetchChannels();
  });

  const removeChannel = (cloudId) => {
    CloudChannelsServices.removeChannel(cloudId, {
      errorNotification: errorNotification(formatMessage),
      successNotification: successNotification(formatMessage),
    });
  };

  const columns = [
    {
      key: "channelName",
      dataIndex: "channelName",
      title: formatMessage(translations.channelName),
      sorter: (currentValue, nextValue) => currentValue.channelName.localeCompare(nextValue.channelName),
      render: (text, { id, channelName }) => (
        <Link key={id} to={`/channel/own/${id}`}>
          <span>{channelName}</span>
        </Link>
      ),
    },
    {
      key: "cloudId",
      dataIndex: "cloudId",
      title: formatMessage(translations.id),
      sorter: (currentValue, nextValue) => currentValue.cloudId - nextValue.cloudId,
      render: (cloudId) => {
        return parseCloudId(cloudId);
      },
    },
    {
      key: "id",
      dataIndex: "userId",
      title: formatMessage(translations.owner),
      sorter: (currentValue, nextValue) => currentValue.userId.localeCompare(nextValue.userId),
    },
    {
      key: "actions",
      title: formatMessage(translations.actions),
      render: (text, { id: channelId, channelName, isEncrypted, cloudId }) => (
        <>
          <EditSharedChannelModal cloudId={cloudId} initialValues={{ channelName, isEncrypted: !!isEncrypted }} />
          <Divider type="vertical" />
          <Button type="primary" icon={<ShareAltOutlined />} onClick={() => history.push(`/channel/own/${channelId}`)}>
            <span>
              <FormattedMessage id="ChannelDetailView.share" defaultMessage="Share" />
            </span>
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title={
              <FormattedMessage
                id="ActionButtons.confirmRemove"
                defaultMessage="Are you sure, you want to remove this channel?"
              />
            }
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => removeChannel(channelId)}
            okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
            cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              <span>
                <FormattedMessage id="general.remove" defaultMessage="Remove" />
              </span>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <Card>
      <Table columns={columns} dataSource={ownChannelsList} rowKey={(record) => record.id} />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  ownChannelsList: CLOUD_CHANNELS_SELECTORS.getOwnChannels(state),
});

OwnChannelList.propTypes = {
  ownChannelsList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cloudId: PropTypes.number.isRequired,
        channelName: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        isEncrypted: PropTypes.bool.isRequired,
        isPublic: PropTypes.bool.isRequired,
        userId: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
};

export default connect(mapStateToProps, null)(OwnChannelList);
