import React, { useCallback, useState } from "react";
import { Input, Button, Checkbox, Form } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import CloudChannelsService from "../../../services/cloudChannels";
import { errorNotification, successNotification } from "../../../lib/utils/notification";

import { az09space, max32characters, required } from "../../../lib/utils/formValidation";

const { Item } = Form;

const translations = defineMessages({
  required: {
    id: "NewChannelFormModal.required",
    defaultMessage: "Share name field required",
  },
  placeholder: {
    id: "NewChannelFormModal.placeholder",
    defaultMessage: "Please input share name",
  },
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const NewChannelFormModal = ({ cloudId, handleShowModal, initialValues, editMode }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { resetFields } = form;

  const handleFinish = useCallback(
    (formData) => {
      if (editMode) {
        setLoading(true);
        CloudChannelsService.editCloudChannel(
          { ...formData, cloudId },
          {
            errorNotification: errorNotification(formatMessage),
            successNotification: successNotification(formatMessage),
            setLoading,
          }
        );
      } else {
        setLoading(true);
        CloudChannelsService.createChannel(formData, {
          errorNotification: errorNotification(formatMessage),
          successNotification: successNotification(formatMessage),
          setLoading,
        });
      }
      resetFields();
      handleShowModal();
    },
    [formatMessage, resetFields, handleShowModal, editMode, cloudId]
  );

  return (
    <Form {...formItemLayout} onFinish={handleFinish} initialValues={initialValues} form={form}>
      <Item
        name="channelName"
        label={<FormattedMessage id="NewChannelFormModal.label" defaultMessage="Share name" />}
        rules={[
          { required: true, message: formatMessage(translations.required) },
          max32characters,
          az09space,
          required,
        ]}
      >
        <Input placeholder={formatMessage(translations.placeholder)} />
      </Item>
      <HiddenInput
        valuePropName="checked"
        name="isEncrypted"
        label={<FormattedMessage id="NewChannelFormModal.isEncrypted" defaultMessage="Encrypted" />}
      >
        <Checkbox />
      </HiddenInput>
      <StyledButton type="primary" htmlType="submit" disabled={loading}>
        {editMode && <FormattedMessage id="general.save" defaultMessage="Save" />}
        {!editMode && <FormattedMessage id="NewChannelFormModal.submitCreate" defaultMessage="Create new share" />}
      </StyledButton>
    </Form>
  );
};

const HiddenInput = styled(Item)`
  display: none;
  visibility: hidden;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
`;

NewChannelFormModal.propTypes = {
  cloudId: PropTypes.number,
  editMode: PropTypes.bool,
  handleShowModal: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    channelName: PropTypes.string,
    isEncrypted: PropTypes.bool,
  }),
};

NewChannelFormModal.defaultProps = {
  cloudId: null,
  editMode: null,
  initialValues: {
    channelName: null,
    isEncrypted: false,
  },
};

export default NewChannelFormModal;
