import React, { useState, useEffect, useRef, useCallback } from "react";
import { Select, Button, Row, Col, Card, DatePicker, Divider, Empty } from "antd";
import { Line, Chart } from "react-chartjs-2";
import moment from "moment";
import zoom from "chartjs-plugin-zoom";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { decamelize } from "humps";
import store from "../../../store";

import { CLEAR_HIST_STATS_IN, selectors as STATISTIC_SELECTORS } from "../../../ducks/nodeStatistics";
import { selectors as NODE_SELECTORS } from "../../../ducks/node";
import WebsocketManager from "../../../services/websocket";

import translations from "../../../lib/translations/nodeTranslations";
import { STATISTIC_OUTPUT_TYPE, STATISTIC_INPUT_TYPE } from "../../../lib/utils/constants";

import StatisticHistoricalScaleInfo from "./StatisticHistoricalScaleInfo";

const { Option } = Select;
const { RangePicker } = DatePicker;

const StatisticHistoricalGraph = ({
  channelId,
  cloudId,
  cwid,
  statIn,
  statOut,
  statInMin,
  scale,
  setScale,
  output,
  statOutMin,
  permissionId,
}) => {
  const statRef = useRef(null);
  const { formatMessage } = useIntl();
  useEffect(() => {
    Chart.plugins.register(zoom);
  }, []);

  const data = output ? statOut : statIn;

  const statMin = output ? statOutMin : statInMin;

  const defaultData = useCallback(
    (selectedScale) => {
      const availablePast1Day = moment().subtract(1, "d").unix() > statMin;
      const availablePast1Hour = moment().subtract(1, "h").unix() > statMin;
      const availablePast7Days = moment().subtract(7, "h").unix() > statMin;

      switch (selectedScale) {
        case 1:
          return [availablePast1Day ? moment().subtract(1, "d") : moment.unix(statMin), moment()];
        case 2:
          return [availablePast7Days ? moment().subtract(7, "d") : moment.unix(statMin), moment()];
        default:
          return [availablePast1Hour ? moment().subtract(1, "h") : moment.unix(statMin), moment()];
      }
    },
    [statMin]
  );

  const STATISTIC_TYPE = output ? STATISTIC_OUTPUT_TYPE : STATISTIC_INPUT_TYPE;
  const [dates, setDates] = useState(defaultData(scale));
  const [selectedStat, setSelectedStat] = useState(STATISTIC_TYPE[0]);

  useEffect(() => {
    setDates(defaultData(scale));
  }, [channelId, scale, statMin, selectedStat, defaultData]);

  const dataReady = data && data.length > 0;
  const rangeSelected = dates && !!dates[0] && dates.length > 0;

  const fetchData = useCallback(() => {
    const message = {
      channelId,
      cloudId,
      dataName: decamelize(selectedStat),
      end: rangeSelected && !!dates[1] && dates[1].unix(),
      scale,
      start: rangeSelected && dates[0].unix(),
    };
    if (rangeSelected && !!dates[1]) {
      if (output) {
        WebsocketManager.sendMessage(
          JSON.stringify({
            ...message,
            cloudMsgType: "sendMessage",
            command: "getStatsOut",
            permissionId,
            to: cwid,
          })
        );
      } else {
        WebsocketManager.sendMessage(
          JSON.stringify({
            ...message,
            cloudMsgType: "sendMessage",
            command: "getStatsIn",
            to: cwid,
          })
        );
      }
    }
  }, [channelId, cloudId, cwid, dates, output, permissionId, rangeSelected, scale, selectedStat]);

  useEffect(() => {
    fetchData();
  }, [channelId, dates, selectedStat, fetchData]);

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i += 1) {
      result.push(i);
    }

    return result;
  };

  const disabledDateTime = (current, type) => {
    if (type === "start") {
      const sameAsCurrentDay = moment().date() === current?.date();
      const currentHour = moment().hour();
      const sameAsCurrentHour = moment().hour() === current?.hour();

      return {
        disabledHours: () => (sameAsCurrentDay ? range(currentHour + 1, 24) : []),
        disabledMinutes: () => (sameAsCurrentDay && sameAsCurrentHour ? range(currentHour + 1, 60) : []),
      };
    }

    const startRangeMinutes = rangeSelected && dates[0].minute();
    const startRangeHour = rangeSelected && dates[0].hour();
    const startRangeDay = rangeSelected && dates[0].date();
    const sameDayAsStartDay = current && current.date() === startRangeDay;
    const sameHourAsStartDay = current && current.hour() === startRangeHour;

    return {
      disabledHours: () => (sameDayAsStartDay ? range(0, startRangeHour) : []),
      disabledMinutes: () => (sameDayAsStartDay && sameHourAsStartDay ? range(0, startRangeMinutes) : []),
    };
  };

  const disabledDate = (current) => {
    const futureTime = current > moment();

    return futureTime;
  };

  const handleChangeScale = (selectedScale) => {
    setScale(selectedScale);
    setDates(defaultData(selectedScale));
  };

  const handleResetZoom = () => {
    if (statRef.current) {
      statRef.current.chartInstance.resetZoom();
    }
  };

  const parseStatisticName = (statisticName) => {
    const translationExist = statisticName in translations;

    return translationExist ? formatMessage(translations[statisticName]) : statisticName;
  };

  const handleChangeStatistic = (selectedValue) => {
    setSelectedStat(selectedValue);
    handleResetZoom();
    store.dispatch(CLEAR_HIST_STATS_IN());
  };

  const options = {
    pan: {
      enabled: true,
      mode: "yx",
    },
    zoom: {
      enabled: true,
      mode: "x",
    },
  };

  const parsedData = {
    labels: data && data.map((statistic) => moment.unix(statistic.t).format("HH:mm DD-MM-YYYY")),
    datasets: [
      {
        label: parseStatisticName(selectedStat),
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: data && data.map((statistic) => statistic.v),
      },
    ],
  };

  const getRanges = () => {
    switch (scale) {
      case 0:
        return {
          "last 5 min": [moment().subtract(5, "m"), moment()],
          "last 15 min": [moment().subtract(15, "m"), moment()],
          "last 30 min": [moment().subtract(30, "m"), moment()],
          "last 45 min": [moment().subtract(45, "m"), moment()],
          "last 60 min": [moment().subtract(60, "m"), moment()],
        };
      case 1:
        return {
          "last 1 h": [moment().subtract(1, "h"), moment()],
          "last 3 h": [moment().subtract(3, "h"), moment()],
          "last 7 h": [moment().subtract(7, "h"), moment()],
          "last 12 h": [moment().subtract(12, "h"), moment()],
          "last 24 h": [moment().subtract(24, "h"), moment()],
        };
      default:
        return {
          "last 1 day": [moment().subtract(1, "d"), moment()],
          "last 3 days": [moment().subtract(3, "d"), moment()],
          "last 7 days": [moment().subtract(7, "d"), moment()],
          "last 14 days": [moment().subtract(14, "d"), moment()],
        };
    }
  };

  return (
    <StyledCard title={<FormattedMessage id="StatisticHistoricalGraph.title" defaultMessage="Statistics chart" />}>
      <Row type="flex" justify="space-between" align="top">
        <StyledCol>
          <FormattedMessage id="StatisticHistoricalGraph.statistic" defaultMessage="Statistic:" />{" "}
          <Select onChange={handleChangeStatistic} style={{ width: 240 }} defaultValue={selectedStat}>
            {STATISTIC_TYPE.map((statisticName) => (
              <Option key={statisticName}>{parseStatisticName(statisticName)}</Option>
            ))}
          </Select>
        </StyledCol>
        <StyledCol>
          <FormattedMessage id="StatisticHistorical.scale" defaultMessage="Scale" />
          {": "}
          <Select defaultValue={scale} style={{ width: 120 }} onChange={handleChangeScale}>
            <Option key={0} value={0}>
              <FormattedMessage id="StatisticHistorical.30Seconds" defaultMessage="30 seconds" />
            </Option>
            <Option key={1} value={1}>
              <FormattedMessage id="StatisticHistorical.5minutes" defaultMessage="5 minutes" />
            </Option>
            <Option key={2} value={2}>
              <FormattedMessage id="StatisticHistorical.1hour" defaultMessage="1 hour" />
            </Option>
          </Select>
        </StyledCol>
        <StyledCol>
          <FormattedMessage id="StatisticHistorical.selectDate" defaultMessage="Date" />
          {": "}
          <RangePicker
            allowEmpty={[false, true]}
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
            format="YYYY-MM-DD HH:mm"
            ranges={getRanges()}
            value={dates}
            onCalendarChange={(value) => {
              setDates(value);
            }}
            showTime={{ format: "HH:mm" }}
          />
        </StyledCol>
      </Row>
      <Row>
        <StatisticHistoricalScaleInfo scale={scale} statMin={statMin} />
      </Row>
      <Divider />
      {dataReady && (
        <>
          <Row type="flex" justify="space-between">
            <Col />
            <Col>
              <Button type="primary" onClick={handleResetZoom}>
                <FormattedMessage id="StatisticHistoricalGraph.resetZoom" defaultMessage="Reset zoom" />
              </Button>
            </Col>
          </Row>
          <Line data={parsedData} options={options} ref={statRef} />
        </>
      )}
      {!dataReady && (
        <StyledEmpty
          description={
            <FormattedMessage
              id="StatisticHistoricalGraph.noData"
              defaultMessage="Sorry, but no data for these criteria"
            />
          }
        />
      )}
    </StyledCard>
  );
};

const StyledEmpty = styled(Empty)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;
`;

const StyledCard = styled(Card)`
  margin-bottom: 10px;
`;

const StyledCol = styled(Col)`
  margin: 15px 0;
`;

const statsShape = {
  ccErrors: PropTypes.string,
  ccErrorsPerHour: PropTypes.string,
  ccErrorsTotal: PropTypes.string,
  channelId: PropTypes.number,
  channelName: PropTypes.string,
  cloudId: PropTypes.number,
  mbpsBandwidth: PropTypes.string,
  mbpsRecvRate: PropTypes.string,
  mbpsSendRate: PropTypes.string,
  msRtt: PropTypes.string,
  msTimestamp: PropTypes.string,
  permissionId: PropTypes.string,
  pktRcvBelated: PropTypes.string,
  pktRcvDrop: PropTypes.string,
  pktRcvLoss: PropTypes.string,
  pktRcvRetrans: PropTypes.string,
  pktRecv: PropTypes.string,
  pktRetrans: PropTypes.string,
  pktSent: PropTypes.string,
  pktSndDrop: PropTypes.string,
  pktSndLoss: PropTypes.string,
  time: PropTypes.string,
  username: PropTypes.string,
};

StatisticHistoricalGraph.propTypes = {
  channelId: PropTypes.string.isRequired,
  cloudId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  cwid: PropTypes.string,
  permissionId: PropTypes.number,
  statIn: PropTypes.arrayOf(
    PropTypes.shape({
      ...statsShape,
    })
  ).isRequired,
  statOut: PropTypes.arrayOf(
    PropTypes.shape({
      ...statsShape,
    })
  ).isRequired,
  output: PropTypes.bool,
  statInMin: PropTypes.number.isRequired,
  statOutMin: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  setScale: PropTypes.func.isRequired,
};

StatisticHistoricalGraph.defaultProps = {
  cwid: null,
  output: false,
  permissionId: 0,
};

const mapStateToProps = (state) => ({
  cwid: NODE_SELECTORS.getNodeCwid(state),
  statInMin: STATISTIC_SELECTORS.getStatisticInMin(state),
  statOutMin: STATISTIC_SELECTORS.getStatisticOutMin(state),
  statIn: STATISTIC_SELECTORS.getStatisticIn(state),
  statOut: STATISTIC_SELECTORS.getStatisticOut(state),
});

export default connect(mapStateToProps, null)(StatisticHistoricalGraph);
