import * as React from "react";
import PropTypes from "prop-types";
import { Col } from "antd";
import { useIntl } from "react-intl";

import { IPField, PortField, TTLField } from "../../../components/Fields/Fields";
import NetworkField from "../../../components/Fields/NetworkField";

import globalTranslations from "../../../lib/translations";

import NetworkFieldLabel from "../Labels/NetworkFieldLabel";

export const OutputStreamUdpSection = ({ prefix }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Col sm={12} md={12} lg={12}>
        <IPField label={formatMessage(globalTranslations.adressIp)} name={[...prefix, "dstIp"]} />
      </Col>
      <Col sm={12} md={12} lg={6}>
        <PortField name={[...prefix, "dstPort"]} />
      </Col>
      <Col sm={12} md={12} lg={6}>
        <TTLField name={[...prefix, "ffmpegStreamProperties", "ttl"]} />
      </Col>
      <Col sm={12} md={12} lg={12}>
        <NetworkField name={[...prefix, "interfaceIp"]} label={NetworkFieldLabel("output")} />
      </Col>
    </>
  );
};

OutputStreamUdpSection.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

export default OutputStreamUdpSection;
