import React from "react";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const StatisticOutputTooltip = ({ channelName }) => {
  return (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage id="StatisticOutputTooltip.tooltip" defaultMessage="Login to cloud for permission name" />
      }
    >
      <QuestionCircleFilled /> {channelName}
    </Tooltip>
  );
};

StatisticOutputTooltip.propTypes = {
  channelName: PropTypes.string.isRequired,
};

export default StatisticOutputTooltip;
