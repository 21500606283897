import React, { useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import { defineMessages, useIntl } from "react-intl";
import { Input, InputNumber, Form } from "antd";

const { Item } = Form;

const messages = defineMessages({
  ip: {
    id: "channels.field.ip.validation",
    defaultMessage: "IPv4 address has an invalid format!",
  },
  notNumber: {
    id: "channels.field.validation.notNumber",
    defaultMessage: "Please provide a number!",
  },
  latencyTooBig: {
    id: "channels.field.latency.validation.tooBig",
    defaultMessage: "Latency must be less than {LATENCY_MAX}!",
  },
  latencyTooSmall: {
    id: "channels.field.latency.validation.tooSmall",
    defaultMessage: "Latency must be greater than {LATENCY_MIN}!",
  },
  overheadTooBig: {
    id: "channels.field.overhead.validation.tooBig",
    defaultMessage: "Latency must be less than {OVERHEAD_MIN}!",
  },
  overheadTooSmall: {
    id: "channels.field.overhead.validation.tooSmall",
    defaultMessage: "Latency must be greater than {OVERHEAD_MIN}!",
  },
  connectionMethod: {
    id: "sourceSection.connectionMethod",
    defaultMessage: "Connection method",
  },
  ipRequired: {
    id: "IPField.ipRequired",
    defaultMessage: "Please input IP address",
  },
  portRequired: {
    id: "PortField.portRequired",
    defaultMessage: "Please input port number",
  },
  latencyRequired: {
    id: "LatencyField.latencyRequired",
    defaultMessage: "Please specify latency parameter",
  },
  latency: {
    id: "LatencyField.latency",
    defaultMessage: "Latency",
  },
  overhead: {
    id: "OverheadField.overhead",
    defaultMessage: "Overhead",
  },
  overheadRequired: {
    id: "OverheadField.overheadRequired",
    defaultMessage: "Please input overhead number",
  },
  ttl: {
    id: "TTLField.ttl",
    defaultMessage: "TTL",
  },
  ttlRequired: {
    id: "TTLField.ttlRequired",
    defaultMessage: "Please input ttl number",
  },
});

export const DEFAULT_VALUES = {
  PORT: 1234,
  IP: "0.0.0.0",
  LATENCY: 125,
  OVERHEAD: 25,
  TTL: 1,
};

export const IPField = ({ disabled, name, label }) => {
  const { formatMessage } = useIntl();

  return (
    <Item
      name={name}
      label={label || "IP"}
      rules={[
        { required: true, message: formatMessage(messages.ipRequired) },
        {
          pattern: new RegExp(
            // eslint-disable-next-line max-len
            "^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$"
          ),
          message: formatMessage(messages.ip),
        },
      ]}
    >
      <Input type="text" disabled={disabled} />
    </Item>
  );
};

IPField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  label: PropTypes.string,
};

IPField.defaultProps = {
  disabled: false,
  label: "IP",
};

export const TTLField = ({ name }) => {
  const { formatMessage } = useIntl();

  return (
    <Item
      name={name}
      label={formatMessage(messages.ttl)}
      rules={[{ required: true, message: formatMessage(messages.ttlRequired) }]}
    >
      <InputNumber type="number" min={1} max={255} />
    </Item>
  );
};

TTLField.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
};

export const NameField = ({ name, label, validationMessage, fieldValidation, type }) => {
  return (
    <Item
      name={name}
      label={label}
      rules={
        fieldValidation
          ? [{ ...fieldValidation }, { required: true, message: validationMessage }]
          : [{ required: true, message: validationMessage }]
      }
    >
      <Input type={type} />
    </Item>
  );
};

NameField.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  fieldValidation: PropTypes.object,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  validationMessage: PropTypes.string.isRequired,
};

NameField.defaultProps = {
  fieldValidation: null,
  type: "text",
};

export const PortField = ({ name, label }) => {
  const { formatMessage } = useIntl();
  const inputEl = useRef(null);

  useLayoutEffect(() => {
    function handleMouseScroll() {
      inputEl.current.blur();
    }
    window.addEventListener("mousewheel", handleMouseScroll);

    return () => window.removeEventListener("mousewheel", handleMouseScroll);
  }, []);

  return (
    <Item
      name={name}
      label={label || "Port"}
      rules={[{ required: true, message: formatMessage(messages.portRequired) }]}
    >
      <InputNumber ref={inputEl} type="number" min={1} max={42138} />
    </Item>
  );
};

PortField.propTypes = {
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  label: PropTypes.string,
};

PortField.defaultProps = {
  label: "Port",
};
