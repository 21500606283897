import React from "react";
import styled from "styled-components";
import { Button, Affix } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { history } from "../../store";

const AddNewChannelButton = ({ nodeData }) => {
  return (
    <Affix offsetBottom={0}>
      <StyledAddNewChannelButton
        type="primary"
        onClick={() => history.push(`/cloudNodes/${nodeData.cwid}/${nodeData.cnn}/channel/add`)}
      >
        <FormattedMessage id="AddNewChannelButton.create" defaultMessage="Create new channel" />
      </StyledAddNewChannelButton>
    </Affix>
  );
};

const StyledAddNewChannelButton = styled(Button)`
  display: flex;
  width: 100%;
  margin-top: 15px;
  justify-content: center;
`;

AddNewChannelButton.propTypes = {
  nodeData: PropTypes.shape({
    cnn: PropTypes.string.isRequired,
    cwid: PropTypes.string.isRequired,
  }).isRequired,
};

export default AddNewChannelButton;
