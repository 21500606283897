import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const actions = {
  SET_LOADING: createAction("LOADING/SET_LOADING"),
  CLEAR_LOADING: createAction("LOADING/CLEAR_LOADING"),
};

const initialState = new Map({
  dataType: "",
  loading: false,
});

const reducer = handleActions(
  {
    [actions.SET_LOADING]: (state, { payload }) => state.merge({ loading: true, dataType: payload }),
    [actions.CLEAR_LOADING]: () => initialState,
  },
  initialState
);

export const selectors = {
  getLoading: (state) => state.getIn(["loadingData", "loading"]),
  getLoadingDataType: (state) => state.getIn(["loadingData", "dataType"]),
};

export default reducer;
