const LogsLabel = ({ logValue }) => {
  switch (+logValue) {
    case 0:
      return "Critical";
    case 1:
      return "Error";
    case 2:
      return "Warning";
    case 3:
      return "Info";
    default:
      return "Debug";
  }
};

export default LogsLabel;
