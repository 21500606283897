/* eslint-disable max-len */
import { defineMessages } from "react-intl";

export default defineMessages({
  adressIp: {
    id: "global.ipAdress",
    defaultMessage: "IP Address",
  },
  ownIPAddress: {
    id: "global.ownIpAddress",
    defaultMessage: "Own IP Address",
  },
  autoIPON: {
    id: "global.autoIPON",
    defaultMessage: "Auto IP ON",
  },
  autoIPOFF: {
    id: "global.autoIPOFF",
    defaultMessage: "Auto IP OFF",
  },
  backupDisabled: {
    id: "global.backupDisabled",
    defaultMessage: "Backup disabled",
  },
  backupEnabled: {
    id: "global.backupEnabled",
    defaultMessage: "Backup enabled",
  },
  destinationPort: {
    id: "global.destinationPort.label",
    defaultMessage: "Destination Port",
  },
  enabled: {
    id: "general.enabled",
    defaultMessage: "Enabled",
  },
  disabled: {
    id: "general.disabled",
    defaultMessage: "Disabled",
  },
  sourceIP: {
    id: "global.sourceIP.label",
    defaultMessage: "Source IP",
  },
  sourcePort: {
    id: "global.sourcePort.label",
    defaultMessage: "Source Port",
  },
  connectTimeout: {
    id: "global.connectTimeout.label",
    defaultMessage: "Timeout (s)",
  },
  oheadbw: {
    id: "global.oheadbw.label",
    defaultMessage: "Overhead bandwidth (%)",
  },
  passphrase: {
    id: "global.passphrase.label",
    defaultMessage: "Passphrase",
  },
  advancedParameters: {
    id: "global.advancedParameters.label",
    defaultMessage: "Advanced parameters",
  },
  somethingWentWrong: {
    id: "global.somethingWentWrong",
    defaultMessage: "Upss... Something Went Wrong!",
  },
  yes: {
    id: "global.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "global.no",
    defaultMessage: "No",
  },
  required: {
    id: "global.required",
    defaultMessage: "Field is required",
  },
  edit: {
    id: "global.edit",
    defaultMessage: "Edit",
  },
  save: {
    id: "global.save",
    defaultMessage: "Save",
  },
  defaultError: {
    id: "global.defaultError",
    defaultMessage: "Connection error",
  },
  platformConnectionError: {
    id: "global.platformConnectionError",
    defaultMessage: "Platform connection error",
  },
  400: {
    id: "global.400error",
    defaultMessage: "Bad request",
  },
  401: {
    id: "global.401error",
    defaultMessage: "User doesn't exist",
  },
  402: {
    id: "global.402error",
    defaultMessage: "Incorrect username and/or password",
  },
  403: {
    id: "global.403error",
    defaultMessage: "Your token has expired or you do not have access to this resource",
  },
  404: {
    id: "global.404error",
    defaultMessage: "Not Found",
  },
  405: {
    id: "global.405error",
    defaultMessage: "New password need to be different than old password",
  },
  406: {
    id: "global.406error",
    defaultMessage:
      "Sorry but failed recaptcha validation test - please try again or contact our support team at hello@quickstream.tech",
  },
  407: {
    id: "global.407error",
    defaultMessage: "New username needs to be different than old username",
  },
  408: {
    id: "global.408error",
    defaultMessage: "Request Time-Out",
  },
  409: {
    id: "global.409error",
    defaultMessage: "User with this username already exists",
  },
  410: {
    id: "global.410error",
    defaultMessage: "Only admin can add another admin account",
  },
  // 411: {
  //   id: "global.411error",
  //   defaultMessage: "Only admin can add another admin account",
  // },
  412: {
    id: "global.412error",
    defaultMessage: "Email verification error - please use your business non-free email address",
  },
  413: {
    id: "global.413error",
    defaultMessage: "Admin rights required",
  },
  414: {
    id: "global.414error",
    defaultMessage: "License does not exist",
  },
  415: {
    id: "global.415error",
    defaultMessage: "User was deactivated - please contact with admin",
  },
  420: {
    id: "global.420error",
    defaultMessage: "This channelId does not exist",
  },
  422: {
    id: "global.422error",
    defaultMessage: "Wrong data provided - input validation error",
  },
  429: {
    id: "global.429error",
    defaultMessage: "Too many requests from this IP, please try again after 10s",
  },
  452: {
    id: "global.452error",
    defaultMessage: "General exception ",
  },
  453: {
    id: "global.453error",
    defaultMessage: "General database exception",
  },
  454: {
    id: "global.454error",
    defaultMessage: "Database error while inserting ",
  },
  455: {
    id: "global.455error",
    defaultMessage: "Database error while updating",
  },
  456: {
    id: "global.456error",
    defaultMessage: "Database error while querying ",
  },
  457: {
    id: "global.457error",
    defaultMessage: "Database error while deleting",
  },
  458: {
    id: "global.458error",
    defaultMessage: "Data integrity exception",
  },
  459: {
    id: "global.459error",
    defaultMessage: "Incorrect input value",
  },
  460: {
    id: "global.460error",
    defaultMessage: "System error - command rejected",
  },
  461: {
    id: "global.461error",
    defaultMessage: "Invalid id engine",
  },
  462: {
    id: "global.462error",
    defaultMessage: "Invalid license",
  },
  463: {
    id: "global.463error",
    defaultMessage: "Invalid permissions",
  },
  464: {
    id: "global.464error",
    defaultMessage: "Channel does not exist",
  },
  465: {
    id: "global.465error",
    defaultMessage: "User for this permission does not exist",
  },
  466: {
    id: "global.466error",
    defaultMessage: "Channel with output QS method does not exist in cloud",
  },
  467: {
    id: "global.467error",
    defaultMessage:
      "Output Quickstream connection method error - please login to Quickstream Cloud as owner of channels from the channels list",
  },
  468: {
    id: "global.468error",
    defaultMessage: "Node does not exist in cloud",
  },
  469: {
    id: "global.469error",
    defaultMessage: "Unable to create a permanent license for a virtual machine",
  },
  471: {
    id: "global.471error",
    defaultMessage: "License does not exist",
  },
  472: {
    id: "global.472error",
    defaultMessage: "This licenseId already exists in database",
  },
  473: {
    id: "global.473error",
    defaultMessage: "This channel has Shares connected - please remove it first",
  },
  474: {
    id: "global.474error",
    defaultMessage: "Node is already connected to Quickstream Cloud",
  },
  475: {
    id: "global.475error",
    defaultMessage: "The old password you have entered is incorrect",
  },
  476: {
    id: "global.476error",
    defaultMessage: "This email address is already taken",
  },
  477: {
    id: "global.477error",
    defaultMessage:
      // eslint-disable-next-line max-len
      "This node has been shared in the Quickstream Cloud for other users, please remove shares manually before disconnecting node from Quickstream Cloud",
  },
  478: {
    id: "global.478error",
    defaultMessage: "Shared channel does not exist or was removed by owner",
  },
  479: {
    id: "global.479error",
    defaultMessage: "Your activation link has expired. Please fill out the registration form again.",
  },
  480: {
    id: "global.480error",
    defaultMessage: "This share is connected - please remove it from chanel input first.",
  },
  500: {
    id: "global.500error",
    defaultMessage: "Internal Server Error",
  },
});
