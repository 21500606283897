import React, { useEffect } from "react";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Select, Tooltip, Form } from "antd";
import PropTypes from "prop-types";
import { useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { connect } from "react-redux";
import store from "../../store";

import { selectors as NODE_SELECTORS } from "../../ducks/node";
import { actions as LOADING_ACTIONS, selectors as LOADING_SELECTORS } from "../../ducks/loadingData";

import WebsocketManager from "../../services/websocket";

import themeColor from "../../lib/style/theme";
import parseJwt from "../../lib/utils/parseJwt";
import { required } from "../../lib/utils/formValidation";
import { LOADING_DATA_TYPE } from "../../lib/utils/constants";

const { Item } = Form;
const { Option } = Select;

const translations = defineMessages({
  label: {
    id: "LicenseField.label",
    defaultMessage: "License",
  },
  licenseIdTooltip: {
    id: "LicenseField.licenseIdTooltip",
    defaultMessage:
      "Select the license you want to assign to this channel. More information about licensing is available here:",
  },
});

const LicenseField = ({ cwid, licenseList, loading, loadingDataType }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    WebsocketManager.sendMessage(JSON.stringify({ cloudMsgType: "sendMessage", command: "getLicenses", to: cwid }));
    store.dispatch(LOADING_ACTIONS.SET_LOADING(LOADING_DATA_TYPE.wrapperLicense));
  }, [cwid]);

  const LicenseIdTooltip = (
    <>
      <div>{formatMessage(translations.licenseIdTooltip)}</div>
      <StyledLink
        href="https://help.quickstream.tech/portal/kb/articles/licencing"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://help.quickstream.tech/portal/kb/articles/licencing
      </StyledLink>
    </>
  );

  const nodeLicensesList = licenseList[cwid] || [];

  const parsedNodeLicensesList = nodeLicensesList.map((license) => {
    return { ...parseJwt(license.licenseToken), id: license.id, validLicense: license.validLicense };
  });

  const licenseIdLabel = (
    <StyledLabel>
      <span>{formatMessage(translations.label)}</span>
      <Tooltip placement="top" title={LicenseIdTooltip}>
        <QuestionCircleFilled />
      </Tooltip>
    </StyledLabel>
  );

  const isLoadingLicenses = loading && loadingDataType === LOADING_DATA_TYPE.wrapperLicense;

  return (
    <StyledItem name="licenseId" label={licenseIdLabel} rules={[{ ...required }]}>
      <Select loading={isLoadingLicenses}>
        {parsedNodeLicensesList.map((option) => (
          <Option key={option.licenseId} value={option.licenseId} disabled={option.validLicense !== 1}>
            {option.licenseId}
          </Option>
        ))}
      </Select>
    </StyledItem>
  );
};

LicenseField.propTypes = {
  cwid: PropTypes.string.isRequired,
  licenseList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingDataType: PropTypes.string.isRequired,
};

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

const StyledLink = styled.a`
  color: ${themeColor.yellowLink};

  &:hover {
    color: ${themeColor.yellowLinkHover};
  }
`;

const StyledItem = styled(Item)`
  .ant-form-item-control {
    max-width: 80%;
  }
`;

const mapStateToProps = (state) => ({
  cwid: NODE_SELECTORS.getNodeCwid(state),
  loading: LOADING_SELECTORS.getLoading(state),
  loadingDataType: LOADING_SELECTORS.getLoadingDataType(state),
  licenseList: NODE_SELECTORS.getNodeLicenses(state),
});

export default connect(mapStateToProps, null)(LicenseField);
