import { bindActionCreators } from "redux";
import { defineMessages } from "react-intl";
import store from "../store";

import WebsocketService from "./websocket";

import { PlatformAPI } from "../lib/api";
import getMessageFromErrorCode from "../lib/utils/getMessageFromErrorCode";

const translations = defineMessages({
  editNodeHostnameSuccess: {
    id: "cloudService.editNodeHostnameSuccess",
    defaultMessage: "Hostname for node was successfully updated",
  },
});

const actions = bindActionCreators(
  {
    editNodeHostname: (data, { errorNotification, successNotification }) => async () => {
      try {
        await PlatformAPI.editNodeHostname(data, { errorNotification });
        WebsocketService.sendMessage(
          JSON.stringify({ cloudMsgType: "sendMessage", command: "setHostname", hostname: data?.cnn, to: data?.cwid })
        );
        successNotification(translations.editNodeHostnameSuccess);
      } catch (error) {
        const errorMessage = getMessageFromErrorCode({ status: error.status, code: error.errorCode });
        errorNotification(errorMessage);
      }
    },
  },
  store.dispatch
);

export default actions;
