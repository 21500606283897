import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Descriptions, Badge, Card, Spin, Empty, Switch, Tooltip } from "antd";
import styled from "styled-components";

import store, { history } from "../../store";

import WebsocketService from "../../services/websocket";

import { selectors as NODE_CHANNEL_SELECTORS } from "../../ducks/nodeChannels";
import { selectors as CLOUD_CONNECTION_SELECTORS } from "../../ducks/cloudConnection";
import { actions as LOADING_ACTIONS } from "../../ducks/loadingData";
import { actions as NODE_ACTIONS } from "../../ducks/node";

import themeColor from "../../lib/style/theme";
import parseChannelConfigField from "../../lib/utils/parseChannelConfigField";
import { errorNotification } from "../../lib/utils/notification";

import TranslatedStatus from "../../components/TranslatedStatus";
import ChannelPermissionLabel from "./ChannelPermissionLabel";
import { CONNECTION_METHOD, NODE_CHANNEL_STATE, WEBSOCKET_STATUS, CHANNEL_STATE } from "../../lib/utils/constants";

import NodeChannelTitle from "./NodeChannelTitle";

const { Item } = Descriptions;

const translations = defineMessages({
  connectionType: {
    id: "NodeChannelDetailView.connectionMethod",
    defaultMessage: "Connection method",
  },
  dstPort: {
    id: "NodeChannelDetailView.dstPort",
    defaultMessage: "Port",
  },
  interfaceIp: {
    id: "NodeChannelDetailView.interfaceIp",
    defaultMessage: "Network Interface",
  },
  type: {
    id: "NodeChannelDetailView.type",
    defaultMessage: "Type",
  },
  dstIp: {
    id: "NodeChannelDetailView.dstIp",
    defaultMessage: "IP",
  },
  connectTimeout: {
    id: "NodeChannelDetailView.connectTimeout",
    defaultMessage: "Timeout (s)",
  },
  oheadbw: {
    id: "NodeChannelDetailView.oheadbw",
    defaultMessage: "Overhead bandwidth (%)",
  },
  mode: {
    id: "NodeChannelDetailView.mode",
    defaultMessage: "Mode",
  },
  rcvlatency: {
    id: "NodeChannelDetailView.rcvlatency",
    defaultMessage: "Latency (ms)",
  },
  peerlatency: {
    id: "NodeChannelDetailView.peerlatency",
    defaultMessage: "Latency (ms)",
  },
  ttl: {
    id: "NodeChannelDetailView.ttl",
    defaultMessage: "TTL",
  },
  passphrase: {
    id: "NodeChannelDetailView.passphrase",
    defaultMessage: "Channel encryption",
  },
  pbkeylen: {
    id: "NodeChannelDetailView.pbkeylen",
    defaultMessage: "Key length",
  },
  cloudId: {
    id: "NodeChannelDetailView.cloudId",
    defaultMessage: "Cloud ID",
  },
  cloudName: {
    id: "NodeChannelDetailView.cloudName",
    defaultMessage: "Cloud name",
  },
  description: {
    id: "NodeChannelDetailView.description",
    defaultMessage: "Share name",
  },
  outputName: {
    id: "NodeChannelDetailView.outputName",
    defaultMessage: "Output name",
  },
  triggerBitrateEnabled: {
    id: "NodeChannelDetailView.triggerBitrateEnabled",
    defaultMessage: "On Bitrate drop",
  },
  triggerBitrateLevel: {
    id: "NodeChannelDetailView.triggerBitrateLevel",
    defaultMessage: "Bitrate Level (kibps)",
  },
  triggerBitrateTimeSecs: {
    id: "NodeChannelDetailView.triggerBitrateTimeSecs",
    defaultMessage: "Bitrate drop duration (s)",
  },
  triggerDisconnectEnabled: {
    id: "NodeChannelDetailView.triggerDisconnectEnabled",
    defaultMessage: "On disconnect",
  },
  triggerConnectionTimeEnabled: {
    id: "NodeChannelDetailView.triggerConnectionTimeEnabled",
    defaultMessage: "On connection failure",
  },
  triggerConnectionTimeSecs: {
    id: "NodeChannelDetailView.triggerConnectionTimeSecs",
    defaultMessage: "Maximum connection time (s)",
  },
});

export const NodeChannelDetailView = ({ match, channels, nodes, websocketConnection, websocketStatus }) => {
  const { id, cwid } = match.params;
  const { formatMessage } = useIntl();

  const nodeData = nodes.find((node) => node.cwid === cwid);
  const channelData = channels.find((channel) => channel.channelId === id);

  if (nodes.length > 0 && !nodeData) {
    errorNotification(formatMessage)(translations.wrongCloudNodeName);
    history.push("/");
  }

  const isConnection = websocketConnection && websocketStatus === WEBSOCKET_STATUS.connected;

  useEffect(() => {
    if (isConnection && !nodeData) {
      WebsocketService.sendMessage(JSON.stringify({ cloudMsgType: "getNodes" }));
      store.dispatch(LOADING_ACTIONS.SET_LOADING("getNodes"));
    }
  }, [isConnection, nodeData]);

  useEffect(() => {
    if (nodeData?.cwid && !channelData) {
      WebsocketService.sendMessage(
        JSON.stringify({ command: "getAllChannels", cloudMsgType: "sendMessage", to: nodeData?.cwid })
      );
      store.dispatch(LOADING_ACTIONS.SET_LOADING("getAllChannels"));
      store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    }
  }, [nodeData, channelData]);

  if (!channelData) {
    return (
      <Empty
        description={
          <FormattedMessage
            id="NodeChannelDetailView.missingChannelData"
            defaultMessage="Missing channel data - make sure that the channel still exists (it may have been deleted)"
          />
        }
      />
    );
  }

  const {
    status: { channelStatusText, requestedStatusText, connected, outputs, usesBackup },
    config,
  } = channelData;

  const { input, nonMuxedOutputs, name } = config;
  const { connectionType } = nonMuxedOutputs && nonMuxedOutputs[0];

  const badgeStatus = (value) => {
    switch (value) {
      case NODE_CHANNEL_STATE.PLAYING:
        return "processing";
      case NODE_CHANNEL_STATE.STOPPED:
        return "warning";
      case NODE_CHANNEL_STATE.PROCESS_DISCONNECTED:
        return "error";
      case NODE_CHANNEL_STATE.CONNECTING:
        return "warning";
      case NODE_CHANNEL_STATE.CONNECTED:
        return "success";
      default:
        return "default";
    }
  };

  const configItems = (configChannel, configChannelName) => {
    const {
      ownIp,
      permissionId,
      switchAutoIp,
      channelEncryptionSwitch,
      outputId,
      type,
      switchInputBackup,
      backup,
      ...parsedConfigChannel
    } = configChannel;

    return Object.keys(parsedConfigChannel).map((filedKey) => {
      const fieldValue = configChannel[filedKey];
      if (fieldValue && typeof fieldValue === "object") return configItems(fieldValue, configChannelName);

      return (
        <Item
          key={`${configChannelName}-${filedKey}`}
          label={translations[filedKey] ? formatMessage(translations[filedKey]) : filedKey}
        >
          {parseChannelConfigField({ filedKey, fieldValue })}
        </Item>
      );
    });
  };

  const clientsConnected = () => {
    const UDP_METHODS = [CONNECTION_METHOD.udpMulticast.value, CONNECTION_METHOD.udpUnicast.value];

    if (UDP_METHODS.includes(connectionType)) {
      return <span>N/A</span>;
    }

    if (connected || connected === 0) {
      return <Badge count={connected} showZero style={{ backgroundColor: `${themeColor.green}` }} />;
    }

    return <FormattedMessage id="NodeChannelDetailView.noData" defaultMessage="No data" />;
  };

  const handleSwitchBackup = (value) => {
    WebsocketService.sendMessage(
      JSON.stringify({
        channelId: id,
        cloudMsgType: "sendMessage",
        command: "streamSetBackup",
        to: nodeData?.cwid,
        useBackup: value,
      })
    );
  };

  const isValidBackupStatus = [CHANNEL_STATE.CONNECTED].includes(requestedStatusText);

  return (
    <Card>
      <Descriptions bordered title={<NodeChannelTitle channelId={id} nodeData={nodeData} />}>
        <Item
          span={3}
          label={<FormattedMessage id="NodeChannelDetailView.channelName" defaultMessage="Channel name" />}
        >
          {name}
        </Item>
        <Item span={3} label={<FormattedMessage id="NodeChannelDetailView.channelId" defaultMessage="Channel ID" />}>
          {id}
        </Item>
        <Item label={<FormattedMessage id="NodeChannelDetailView.inputStatus" defaultMessage="Input status" />}>
          <Badge
            status={badgeStatus(channelStatusText)}
            text={<TranslatedStatus channelStatus={channelStatusText} />}
          />
        </Item>
        <Item
          label={<FormattedMessage id="NodeChannelDetailView.clientsConnected" defaultMessage="Clients connected" />}
        >
          {clientsConnected()}
        </Item>
      </Descriptions>
      {input && (
        <StyledCard
          title={<FormattedMessage id="NodeChannelDetailView.inputStream" defaultMessage="Input stream" />}
          extra={
            input.backup && (
              <Tooltip
                placement="top"
                title={
                  !isValidBackupStatus ? (
                    <FormattedMessage
                      id="ChannelDetail.channelMustBeRunning"
                      defaultMessage="Channel must be connected"
                    />
                  ) : null
                }
              >
                <Switch
                  unCheckedChildren={
                    <FormattedMessage id="ChannelDetail.switchToBackup" defaultMessage="Switch to Backup Input" />
                  }
                  checkedChildren={
                    <FormattedMessage id="ChannelDetail.switchToMain" defaultMessage="Switch to Main Input" />
                  }
                  checked={usesBackup}
                  onChange={handleSwitchBackup}
                  disabled={!isValidBackupStatus}
                />
              </Tooltip>
            )
          }
        >
          <Spin
            indicator={<></>}
            spinning={usesBackup}
            tip={<FormattedMessage id="NodeChannelDetailView.inActive" defaultMessage="Inactive" />}
          >
            <StyledDescriptions
              bordered
              column={2}
              title={<FormattedMessage id="NodeChannelDetailView.mainInput" defaultMessage="Main input" />}
            >
              {configItems(input, "input")}
            </StyledDescriptions>
          </Spin>
          {input?.backup && (
            <Spin
              indicator={<></>}
              spinning={!usesBackup}
              tip={<FormattedMessage id="NodeChannelDetailView.inActive" defaultMessage="Inactive" />}
            >
              <StyledDescriptions
                bordered
                column={2}
                title={<FormattedMessage id="NodeChannelDetailView.backupInput" defaultMessage="Backup input" />}
              >
                {configItems(input?.backup, "inputBackup")}
              </StyledDescriptions>
            </Spin>
          )}
        </StyledCard>
      )}
      {nonMuxedOutputs && (
        <StyledCard title={<FormattedMessage id="ChannelDetail.outputStream" defaultMessage="Output stream" />}>
          <StyledDescriptions bordered column={2}>
            {configItems(nonMuxedOutputs[0], "nonMuxedOutputs[0]")}
          </StyledDescriptions>
        </StyledCard>
      )}
      {nonMuxedOutputs && outputs && outputs.length > 0 && (
        <StyledCard title={<FormattedMessage id="ChannelDetail.outputStatus" defaultMessage="Output status" />}>
          <StyledDescriptions bordered column={2}>
            {outputs.map((output) => (
              <Item
                key={output.permissionId}
                label={
                  <ChannelPermissionLabel
                    permissionId={output.permissionId}
                    channelId={id}
                    connectionType={connectionType}
                  />
                }
              >
                <Badge
                  status={badgeStatus(output.threadStatusText)}
                  text={<TranslatedStatus channelStatus={output.threadStatusText} />}
                />
              </Item>
            ))}
          </StyledDescriptions>
        </StyledCard>
      )}
    </Card>
  );
};

const StyledCard = styled(Card)`
  margin: 15px 0;
`;

NodeChannelDetailView.propTypes = {
  channels: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      cwid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      cnn: PropTypes.string.isRequired,
      cwid: PropTypes.string.isRequired,
    })
  ).isRequired,
  websocketConnection: PropTypes.bool,
  websocketStatus: PropTypes.string,
};

NodeChannelDetailView.defaultProps = {
  websocketStatus: null,
  websocketConnection: null,
};

const StyledDescriptions = styled(Descriptions)`
  margin-top: 16px;
`;

export const mapStateToProps = (state) => ({
  channels: NODE_CHANNEL_SELECTORS.getChannels(state),
  nodes: CLOUD_CONNECTION_SELECTORS.getNodes(state),
  websocketStatus: CLOUD_CONNECTION_SELECTORS.getStatus(state),
  websocketConnection: CLOUD_CONNECTION_SELECTORS.getWebsocketConnection(state),
});

export default connect(mapStateToProps)(NodeChannelDetailView);
