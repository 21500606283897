import React, { useEffect, useState } from "react";
import { AreaChartOutlined, CodeOutlined, ControlOutlined } from "@ant-design/icons";
import { Tabs, Card, Affix } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import styled from "styled-components";

import store from "../../../store";
import { actions as NAVIGATION_ACTIONS, selectors as NAVIGATION_SELECTORS } from "../../../ducks/navigation";
import { selectors as NODE_CHANNEL_SELECTORS } from "../../../ducks/nodeChannels";

import NodeChannelsList from "../NodeChannelsList";
import Logs from "../../../components/Logs";
import NodeStatisticsContainer from "../NodeStatistics";
import NodeHeaderSystemStats from "../../../components/NodeHeaderSystemStats";

const { TabPane } = Tabs;

const NodeDashboard = ({ activeTab, channels, ...rest }) => {
  const [channelId, setChannelId] = useState("-1");
  const [statisticChannelId, setStatisticChannelId] = useState();

  useEffect(() => {
    const [...channelIds] = channels && channels.keys();
    if (!statisticChannelId) {
      setStatisticChannelId(channelIds[0]);
    }
  }, [channels, statisticChannelId]);

  const openTabs = (key) => {
    store.dispatch(NAVIGATION_ACTIONS.SET_CHANNELS_ACTIVE_TAB({ channelsActiveTab: key }));
  };

  const openLogTab = (key, id) => {
    store.dispatch(NAVIGATION_ACTIONS.SET_CHANNELS_ACTIVE_TAB({ channelsActiveTab: key }));
    setChannelId(id);
  };

  const openStatisticTab = (key, id) => {
    store.dispatch(NAVIGATION_ACTIONS.SET_CHANNELS_ACTIVE_TAB({ channelsActiveTab: key }));
    setStatisticChannelId(id);
  };

  return (
    <>
      <StyledAffix offsetTop={0}>
        <Card>
          <NodeHeaderSystemStats />
        </Card>
      </StyledAffix>
      <StyledCard>
        <Tabs activeKey={activeTab || "channelList"} onTabClick={openTabs}>
          <StyledTabPane
            tab={
              <span>
                <ControlOutlined />
                <FormattedMessage id="NodeDashboard.channelList" defaultMessage="Channel list" />
              </span>
            }
            key="channelList"
          >
            <NodeChannelsList openLogTab={openLogTab} openStatisticTab={openStatisticTab} {...rest} />
          </StyledTabPane>
          <StyledTabPane
            tab={
              <span>
                <CodeOutlined />
                <FormattedMessage id="NodeDashboard.logs" defaultMessage="Logs" />
              </span>
            }
            key="logs"
          >
            <Logs channelId={channelId} setChannelId={setChannelId} />
          </StyledTabPane>
          <TabPane
            tab={
              <span>
                <AreaChartOutlined />
                <FormattedMessage id="NodeDashboard.statistics" defaultMessage="Statistics" />
              </span>
            }
            key="statistics"
          >
            <NodeStatisticsContainer channelId={statisticChannelId} setChannelId={setStatisticChannelId} />
          </TabPane>
        </Tabs>
      </StyledCard>
    </>
  );
};

const StyledCard = styled(Card)`
  max-width: calc(100vw - 270px);
  margin-bottom: 10px;
`;

const StyledAffix = styled(Affix)`
  margin-bottom: 10px;
`;

const StyledTabPane = styled(TabPane)`
  outline: none;
`;

NodeDashboard.propTypes = {
  channels: ImmutablePropTypes.map.isRequired,
  activeTab: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  activeTab: NAVIGATION_SELECTORS.getChannelsActiveTab(state),
  channels: NODE_CHANNEL_SELECTORS.getChannels(state),
});

export default connect(mapStateToProps, null)(NodeDashboard);
