import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Row, Col, Card, Collapse, Divider } from "antd";
import styled from "styled-components";

import ConnectionMethod from "../../../components/Fields/ConnectionMethod";
import InputNumberField from "../../../components/Fields/InputNumberField";
import OutputStreamUdpSection from "./OutputStreamUdpSection";
import OutputStreamSrtSection from "./OutputStreamSrtSection";
import CloudChannelList from "../../../components/Fields/CloudChannelList";
import InputTextFiled from "../../../components/Fields/InputTextField";
import OutputStreamQSSection from "../InputStreamSection/InputStreamQSSection";
import DemoConnectionMethod from "../../../components/DemoConnectionMethod";

import themeColor from "../../../lib/style/theme";
import { CONNECTION_METHOD, QUICKSTREAM_METHOD, DEMO_CONNECTION_METHOD } from "../../../lib/utils/constants";
import { max32characters, az09space } from "../../../lib/utils/formValidation";

const { Panel } = Collapse;

const translations = defineMessages({
  cloudIdLabel: {
    id: "CloudChannelList.cloudIdLabel",
    defaultMessage: "Cloud ID",
  },
  outputName: {
    id: "OutputStreamSection.outputName",
    defaultMessage: "Output name",
  },
  cloudName: {
    id: "OutputStreamSection.cloudName",
    defaultMessage: "Cloud name",
  },
});

const SRT_METHODS = [
  CONNECTION_METHOD.srtCaller.value,
  CONNECTION_METHOD.srtRendezvous.value,
  CONNECTION_METHOD.srtListener.value,
];
const UDP_METHODS = [CONNECTION_METHOD.udpMulticast.value, CONNECTION_METHOD.udpUnicast.value];

export const OutputStreamSection = ({
  channelList,
  getFieldValue,
  initialValue,
  loggedToCloud,
  setFieldsValue,
  setDisableCondition,
}) => {
  const [connectionMethod, setConnectionMethod] = useState(
    initialValue?.connectionType || CONNECTION_METHOD.udpUnicast.value
  );

  const DEMO_CONNECTION_METHOD_VALUE_ARRAY = DEMO_CONNECTION_METHOD.map((method) => method.value);
  const isSRTMethod = SRT_METHODS.includes(connectionMethod);
  const isUDPMethod = UDP_METHODS.includes(connectionMethod);
  const isQuickstreamMethod = connectionMethod === QUICKSTREAM_METHOD.value;
  const isDemoConnectionMethod = DEMO_CONNECTION_METHOD_VALUE_ARRAY.includes(connectionMethod);
  const selectedConnectionMethodLabel = DEMO_CONNECTION_METHOD.find((method) => method.value === connectionMethod);

  const freeChannelList = channelList.filter((channel) => !channel.cwid);

  useEffect(() => {
    setDisableCondition(isDemoConnectionMethod);
  }, [setDisableCondition, isDemoConnectionMethod]);
  const { formatMessage } = useIntl();

  return (
    <Card title={<FormattedMessage id="destination.outputStream" defaultMessage="Output Stream" />}>
      <Row type="flex" justify="start" gutter={24}>
        <Col span={12}>
          <ConnectionMethod
            name={["nonMuxedOutputs", 0, "connectionType"]}
            initialValue={initialValue?.connectionType}
            disabled={!loggedToCloud && isQuickstreamMethod}
            loggedToCloud={loggedToCloud}
            setConnectionMethod={setConnectionMethod}
          />
        </Col>
        <Col span={12}>
          <InputTextFiled
            name={["nonMuxedOutputs", 0, "outputName"]}
            label={formatMessage(translations.outputName)}
            rules={[max32characters, az09space]}
          />
        </Col>
        {initialValue?.outputId && (
          <StyledHiddenCol span={4}>
            <InputNumberField name={["nonMuxedOutputs", 0, "outputId"]} label="outputId" />
          </StyledHiddenCol>
        )}
        <Divider />
        {isDemoConnectionMethod && <DemoConnectionMethod label={selectedConnectionMethodLabel.label} />}
        {isQuickstreamMethod && (
          <>
            <Col span={12}>
              <CloudChannelList
                channelList={freeChannelList}
                disabled={!loggedToCloud}
                getFieldValue={getFieldValue}
                initialValue={
                  initialValue?.cloudId && initialValue?.cloudName
                    ? {
                        cloudId: initialValue?.cloudId,
                        channelName: initialValue?.cloudName,
                        permissionId: initialValue?.permissionId,
                        description: initialValue?.description,
                      }
                    : null
                }
                name={["nonMuxedOutputs", 0]}
                label={formatMessage(translations.cloudIdLabel)}
                sameAsValidation="input"
                setFieldsValue={setFieldsValue}
              />
            </Col>
            <StyledHiddenCol span={4} label={formatMessage(translations.cloudName)}>
              <InputTextFiled name={["nonMuxedOutputs", 0, "cloudName"]} />
            </StyledHiddenCol>
          </>
        )}
        {isSRTMethod && (
          <OutputStreamSrtSection
            prefix={["nonMuxedOutputs", 0]}
            connectionMethod={connectionMethod}
            initialValue={initialValue}
          />
        )}
        {isUDPMethod && <OutputStreamUdpSection prefix={["nonMuxedOutputs", 0]} initialValue={initialValue} />}
        {isQuickstreamMethod && (
          <StyledCollapse>
            <Panel
              header={<FormattedMessage id="general.advancedSettings" defaultMessage="Advanced settings" />}
              key="advancedSettings"
              forceRender
            >
              <OutputStreamQSSection
                prefix={["nonMuxedOutputs", 0]}
                initialValue={initialValue}
                setFieldsValue={setFieldsValue}
              />
            </Panel>
          </StyledCollapse>
        )}
      </Row>
    </Card>
  );
};

const StyledHiddenCol = styled(Col)`
  visibility: hidden;
  display: none;
`;

const StyledCollapse = styled(Collapse)`
  background-color: ${themeColor.orange} !important;
  width: 100%;

  .ant-collapse-header {
    color: #fff !important;
  }
`;

OutputStreamSection.propTypes = {
  channelList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cloudId: PropTypes.number.isRequired,
        channelName: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        isEncrypted: PropTypes.bool.isRequired,
        isPublic: PropTypes.bool.isRequired,
        userId: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
  initialValue: PropTypes.shape({
    connectionType: PropTypes.string,
    permissionId: PropTypes.string,
    description: PropTypes.string,
    cloudId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cloudName: PropTypes.string,
    type: PropTypes.string,
    dstPort: PropTypes.number,
    ffmpegStreamProperties: PropTypes.shape({
      connectTimeout: PropTypes.number,
      mode: PropTypes.string,
      oheadbw: PropTypes.number,
      peerlatency: PropTypes.number,
    }),
    interfaceIp: PropTypes.string,
    outputId: PropTypes.number,
  }),
  getFieldValue: PropTypes.func.isRequired,
  loggedToCloud: PropTypes.bool.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  setDisableCondition: PropTypes.func.isRequired,
};

OutputStreamSection.defaultProps = {
  initialValue: null,
};

export default OutputStreamSection;
