import React, { useCallback, useState, useEffect } from "react";
import { MailOutlined } from "@ant-design/icons";
import { Input, Card, Form, Button, Spin } from "antd";
import styled from "styled-components";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";

import AccountService from "../../services/account";
import { errorNotification, successNotification } from "../../lib/utils/notification";
import { required, max32characters, az09 } from "../../lib/utils/formValidation";
import logoUrl from "../../lib/images/logo-cloud-black.png";

import ReturnSignInFooter from "../../components/ReturnSignInFooter";
import LoadingReCaptcha from "../../components/LoadingReCaptcha";

const { Item } = Form;
const { REACT_APP_RECAPTCHA_V3_PUBLIC_KEY } = process.env;

const translations = defineMessages({
  userData: {
    id: "PasswordForgotForm.userData",
    defaultMessage: "Please input your username or email address",
  },
  notValidEmail: {
    id: "general.notValidEmail",
    defaultMessage: "Invalid email format",
  },
  returnSignInLinkTitle: {
    id: "PasswordForgotForm.returnSignInLinkTitle",
    defaultMessage: "Back to",
  },
});

const PasswordForgotForm = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { resetFields } = form;

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const [disabledSend, setDisabledSend] = useState(true);
  const [userDataValue, setUserDataValue] = useState("");

  const checkReCaptcha = useCallback(async () => {
    const reCaptchaResponse = await executeRecaptcha("passwordForgot");
    setReCaptchaToken(reCaptchaResponse);
  }, [executeRecaptcha]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkReCaptcha();
    }, 200);

    const interval = setInterval(async () => {
      checkReCaptcha();
    }, 120000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [checkReCaptcha]);

  const handleFinish = useCallback(
    async (formData) => {
      AccountService.forgotPassword(
        { ...formData, reCaptchaToken },
        {
          errorNotification: errorNotification(formatMessage),
          successNotification: successNotification(formatMessage),
        }
      );
      checkReCaptcha();
      resetFields();
    },
    [formatMessage, checkReCaptcha, reCaptchaToken, resetFields]
  );

  const onChange = (event) => {
    if (event.target.value) {
      setUserDataValue(event.target.value);
      setDisabledSend(false);
    } else {
      setUserDataValue("");
      setDisabledSend(true);
    }
  };

  const validationRules = () => {
    if (userDataValue.includes("@") || userDataValue.includes(".")) {
      return [{ type: "email", message: formatMessage(translations.notValidEmail) }, required];
    }

    return [az09, max32characters, required];
  };

  return (
    <StyledFormContainer>
      <Spin spinning={!reCaptchaToken} tip={<LoadingReCaptcha checkReCaptcha={checkReCaptcha} />}>
        <Card
          title={<FormattedMessage id="PasswordForgotForm.forgotPassword" defaultMessage="Forgot Password" />}
          extra={<img src={logoUrl} alt="quickstream logo" width="200" />}
        >
          <StyledForm onFinish={handleFinish} form={form}>
            <Item name="userData" rules={validationRules()} onChange={onChange}>
              <Input
                prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder={formatMessage(translations.userData)}
              />
            </Item>
            <StyledButton type="primary" htmlType="submit" disabled={disabledSend}>
              <FormattedMessage id="general.send" defaultMessage="Send email" />
            </StyledButton>
          </StyledForm>
        </Card>
      </Spin>
      <ReturnSignInFooter linkTitle={formatMessage(translations.returnSignInLinkTitle)} />
    </StyledFormContainer>
  );
};

const StyledForm = styled(Form)`
  min-width: 400px;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
  margin: 20px 0 0 0;
`;

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const PasswordForgotContainer = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_V3_PUBLIC_KEY}>
      <PasswordForgotForm />
    </GoogleReCaptchaProvider>
  );
};

export default PasswordForgotContainer;
