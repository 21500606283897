import React from "react";
import styled from "styled-components";
import { Card, Typography, Descriptions } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import moment from "moment";
import translations from "../../../lib/translations/nodeTranslations";

const { Text } = Typography;
const { Item } = Descriptions;

const StatisticsView = ({ data }) => {
  const { formatMessage } = useIntl();

  const transferSpeedValue = (statisticName) => {
    if (+data[statisticName] >= 1024) {
      return `${(+data[statisticName] / 1024).toFixed(2)} [Gbps]`;
    }

    if (+data[statisticName] < 1) {
      return `${(+data[statisticName] * 1024).toFixed(2)} [Kbps]`;
    }

    return `${(+data[statisticName]).toFixed(2)} [Mbps]`;
  };

  const timeDefferenceValue = (statisticName) => {
    const duration = moment.duration(+data[statisticName]);

    const timeDuration = [];

    const years = duration.years();
    const months = duration.months();
    const weeks = duration.weeks();
    const days = duration.days();
    const hrs = duration.hours();
    const mins = duration.minutes();
    const secs = duration.seconds();

    if (years > 0) timeDuration.push(`${years} year`);
    if (months > 0) timeDuration.push(`${months} months`);
    if (weeks > 0) timeDuration.push(`${weeks} weeks`);
    if (days > 0) timeDuration.push(`${days} days`);
    if (hrs > 0) timeDuration.push(`${hrs} hrs`);
    if (mins > 0) timeDuration.push(`${mins} mins`);
    if (secs > 0) timeDuration.push(`${secs} secs`);

    return timeDuration.join(", ");
  };

  const parsedStatisticValue = (statisticName) => {
    switch (statisticName) {
      case "time":
        return moment.unix(data[statisticName]).format("HH:mm:ss DD-MM-YYYY");
      case "mbpsBandwidth":
        return transferSpeedValue(statisticName);
      case "mbpsMaxbw":
        return transferSpeedValue(statisticName);
      case "mbpsSendRate":
        return transferSpeedValue(statisticName);
      case "mbpsRecvRate":
        return transferSpeedValue(statisticName);
      case "msRtt":
        return `${(+data[statisticName]).toFixed(1)} [ms]`;
      case "msTimestamp":
        return timeDefferenceValue(statisticName);
      default:
        return (+data[statisticName]).toLocaleString();
    }
  };

  const statistics = Object.keys(data).map((statisticName) => {
    const translationExist = statisticName in translations;

    const skipData = ["id", "channelId", "cloudId"];
    const skip = skipData.includes(statisticName);

    if (skip) {
      return null;
    }

    return (
      <Item
        label={
          <StyledText type="warning">
            {translationExist ? formatMessage(translations[statisticName]) : statisticName}
          </StyledText>
        }
        key={statisticName}
      >
        {parsedStatisticValue(statisticName)}
      </Item>
    );
  });

  return (
    <Card title={<FormattedMessage id="StatisticsView.statistics" defaultMessage="Statistics live" />}>
      <Descriptions layout="vertical" bordered column={{ xxl: 4, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}>
        {statistics}
      </Descriptions>
    </Card>
  );
};

const StyledText = styled(Text)`
  font-weight: bold;
`;

StatisticsView.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default StatisticsView;
