import React, { useState, useCallback } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import ShareFormModal from "./NewChannelFormModal";

const EditSharedChannelModal = ({ initialValues, cloudId }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  return (
    <>
      <Button icon={<EditOutlined />} onClick={handleShowModal}>
        <span>
          <FormattedMessage id="EditSharedChannelModal.editShare" defaultMessage="Edit" />
        </span>
      </Button>
      <Modal
        title={<FormattedMessage id="EditSharedChannelModal.title" defaultMessage="Edit share form" />}
        visible={showModal}
        footer={null}
        onCancel={handleShowModal}
      >
        <ShareFormModal handleShowModal={handleShowModal} initialValues={initialValues} cloudId={cloudId} editMode />
      </Modal>
    </>
  );
};

EditSharedChannelModal.propTypes = {
  cloudId: PropTypes.number,
  initialValues: PropTypes.shape({
    channelName: PropTypes.string,
    isEncrypted: PropTypes.bool,
  }),
};

EditSharedChannelModal.defaultProps = {
  cloudId: null,
  initialValues: {
    channelName: null,
    isEncrypted: false,
  },
};

export default EditSharedChannelModal;
