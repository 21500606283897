import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";

import store, { history } from "../../store";
import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";
import { actions as NAVIGATION_ACTIONS } from "../../ducks/navigation";
import { actions as NODE_ACTIONS } from "../../ducks/node";
import { selectors as CLOUD_CONNECTION_SELECTORS } from "../../ducks/cloudConnection";

import { NODES_URL, WEBSOCKET_STATUS } from "../../lib/utils/constants";

export const ProtectedRouteComponent = ({ isUserLoggedIn, component: Component, nodes, ...rest }) => {
  const { pathname } = rest.location;

  useEffect(() => {
    store.dispatch(NAVIGATION_ACTIONS.SET_ACTIVE_KEY({ activeKey: [pathname] }));
    if (nodes && nodes.length > 0) {
      const nodesSplittedURL = pathname.split("/");

      const selectedNodeData = nodes.find((node) => node.cwid === nodesSplittedURL[2]);

      if (selectedNodeData && selectedNodeData.status === WEBSOCKET_STATUS.disconnected) {
        store.dispatch(NODE_ACTIONS.CLEAR_NODE_CONNECTION());
        history.push(`/shares`);
      }

      if (nodesSplittedURL[1] === NODES_URL) {
        store.dispatch(
          NAVIGATION_ACTIONS.SET_ACTIVE_SUBMENU({
            activeSubmenu: ["/cloudNodes", `/cloudNodes/${nodesSplittedURL[2]}`],
          })
        );
      } else {
        store.dispatch(NODE_ACTIONS.CLEAR_NODE_CONNECTION());
      }
    }
  }, [pathname, nodes]);

  const getProperComponent = (props) => {
    if (isUserLoggedIn) {
      return <Component {...rest} {...props} />;
    }

    return <Redirect to={`/login?backPath=${pathname}`} />;
  };

  return <Route {...rest} render={getProperComponent} />;
};

export function mapStateToProps(state) {
  return {
    isUserLoggedIn: !!ACCOUNT_SELECTORS.getUser(state),
    nodes: CLOUD_CONNECTION_SELECTORS.getNodes(state),
  };
}

ProtectedRouteComponent.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      cnn: PropTypes.string.isRequired,
      cwid: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default connect(mapStateToProps, null)(ProtectedRouteComponent);
