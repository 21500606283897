import React from "react";
import PropTypes from "prop-types";
import { Select, Tooltip, Form } from "antd";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import { required } from "../../../lib/utils/formValidation";
import parseChannelId from "../../../lib/utils/parseChannelId";

import CloudDisableTooltip from "../../CloudDisableTooltip";

const { Option } = Select;

const { Item } = Form;

const translations = defineMessages({
  fieldRequired: {
    id: "CloudChannelList.fieldRequired",
    defaultMessage: "Please select cloud channel ID",
  },
  sameAsInputCloudId: {
    id: "CloudChannelList.sameAsInputCloudId",
    defaultMessage: "Cannot be the same as input stream cloud ID",
  },
  sameAsOutputCloudId: {
    id: "CloudChannelList.sameAsOutputCloudId",
    defaultMessage: "Cannot be the same as output stream cloud ID",
  },
});

const CloudChannelList = ({
  disabled,
  getFieldValue,
  name,
  label,
  initialValue,
  channelList,
  sameAsValidation,
  setFieldsValue,
}) => {
  const { formatMessage } = useIntl();

  const noInitialValue = initialValue === null;

  const parsedChannelList = [
    ...channelList,
    ...(noInitialValue
      ? []
      : [
          {
            cloudId: initialValue?.cloudId,
            channelName: initialValue?.channelName,
            permissionId: initialValue?.permissionId,
            description: initialValue?.description,
          },
        ]),
  ];

  if (initialValue?.permissionId) {
    setFieldsValue({ [name]: { cloudId: initialValue?.permissionId } });
  }

  const handleChange = (selectedValue, option) => {
    const selectedChannelData =
      parsedChannelList.find((channel) => +channel.permissionId === +option.key) ||
      parsedChannelList.find((channel) => +channel.cloudId === +option.key);
    setFieldsValue({ [name]: { cloudName: selectedChannelData.channelName } });

    if (name[0] === "nonMuxedOutputs") {
      const outputFormData = getFieldValue(["nonMuxedOutputs", 0]);

      setFieldsValue({ nonMuxedOutputs: [{ ...outputFormData, cloudName: selectedChannelData.channelName }] });
    }

    if (name[0] === "input") {
      setFieldsValue({ input: { permissionId: selectedValue } });
      setFieldsValue({ input: { description: selectedChannelData.description } });
      setFieldsValue({ input: { cloudName: selectedChannelData.channelName } });
    }
  };

  const validateCloudId = (rule, value) => {
    const inputCloudId = getFieldValue(["input", "cloudId"]);
    const outputCloudId = getFieldValue(["nonMuxedOutputs", 0, "cloudId"]);

    if (!value) {
      return Promise.reject(formatMessage(translations.fieldRequired));
    }
    if (sameAsValidation === "output" && value === outputCloudId) {
      return Promise.reject(formatMessage(translations.sameAsOutputCloudId));
    }
    if (sameAsValidation === "input" && value === inputCloudId) {
      return Promise.reject(formatMessage(translations.sameAsInputCloudId));
    }

    return Promise.resolve();
  };

  return (
    <Tooltip
      placement="top"
      title={
        disabled ? (
          <CloudDisableTooltip
            title={
              <FormattedMessage
                id="general.disableEditQSMethod"
                defaultMessage="to Quickstream Cloud is needed to edit this channel"
              />
            }
          />
        ) : null
      }
    >
      <Item name={[...name, "cloudId"]} label={label} rules={[{ validator: validateCloudId }, { ...required }]}>
        <Select onSelect={handleChange} disabled={disabled}>
          {parsedChannelList.map((channel) => (
            <Option
              key={channel.permissionId || channel.cloudId || "empty"}
              value={channel.permissionId || channel.cloudId}
            >
              {channel.cloudId && `${parseChannelId(channel.cloudId)} `}
              {channel.description ? <span>{`${channel.description}`}</span> : <span>{`${channel.channelName}`}</span>}
            </Option>
          ))}
        </Select>
      </Item>
    </Tooltip>
  );
};

CloudChannelList.propTypes = {
  disabled: PropTypes.bool.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.shape({
      cloudId: PropTypes.number.isRequired,
      channelName: PropTypes.string.isRequired,
    }),
  ]),
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
  channelList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cloudId: PropTypes.number.isRequired,
        channelName: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        isEncrypted: PropTypes.bool.isRequired,
        isPublic: PropTypes.bool.isRequired,
        userId: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
  sameAsValidation: PropTypes.string.isRequired,
};

CloudChannelList.defaultProps = {
  initialValue: null,
};

export default CloudChannelList;
