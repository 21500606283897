import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Router } from "react-router";
import { history } from "../store";

import Registration from "../pages/Registration";
import PasswordForgot from "../pages/PasswordForgot";
import AccountActivation from "../pages/AccountActivation";

import ProtectedRoute from "../components/ProtectedRoute";
import LoginForm from "../components/LoginForm";
import NavigationLayout from "../components/NavigationLayout";
import ChangePasswordForm from "../components/ChangePasswordForm";
import Login2FA from "../pages/Login2FA";
import Auth2FA from "../pages/Auth2FA";

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path="/login" exact component={LoginForm} />
      <Route path="/login/2fa" exact component={Login2FA} />
      <Route path="/auth/2fa/:token" exact component={Auth2FA} />
      <Route path="/register" exact component={Registration} />
      <Route path="/register/:token" exact component={Registration} />
      <Route path="/password/forgot" exact component={PasswordForgot} />
      <Route path="/account/activation/:activationToken" exact component={AccountActivation} />
      <Route path="/account/password/reset/:resetLink" exact component={ChangePasswordForm} />
      <ProtectedRoute path="/" component={NavigationLayout} />
      <Redirect to="/login" />
    </Switch>
  </Router>
);

export default Routes;
