import React, { useState, useEffect } from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Divider, Button, Row, Col, Popconfirm, Table } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import WebsocketManager from "../../services/websocket";
import { selectors as CONNECTION_SELECTORS } from "../../ducks/node";

import SelectEngine from "../SelectEngine";

import { selectors as CHANNEL_SELECTORS } from "../../ducks/nodeChannels";
import { selectors as LOGS_SELECTORS } from "../../ducks/logs";

import themeColor from "../../lib/style/theme";
import { NODE } from "../../lib/utils/constants";

import LogsLabel from "./LogsLabel";

const Logs = ({ channels, cwid, logs, channelId, setChannelId }) => {
  const logsExist = logs && channelId in logs;
  const logsData = logsExist && logs[channelId];
  const lastLogsId = (logsData && logsData[0] && logsData[0].id) || 0;

  const [lastChannelLog, setLastChannelLog] = useState(lastLogsId);

  useEffect(() => {
    const getLogsParams = {
      command: "getLogs",
      channelId: `${channelId}`,
      first: lastChannelLog,
      count: 10,
      cloudMsgType: "sendMessage",
      to: cwid,
    };

    WebsocketManager.sendMessage(JSON.stringify(getLogsParams));
  }, [channelId, lastChannelLog, cwid]);

  const [...channelIds] = channels.keys();
  const channelsData = channels.toJS();

  const sortedChannelsId = channelIds.sort((a, b) => a - b);
  const parsedChannelsId = ["-1", "0", ...sortedChannelsId];

  const handleSelectChange = (selectedChannelId) => {
    setChannelId(selectedChannelId);
    setLastChannelLog(0);
  };

  const handleLoadMoreLogs = () => {
    if (+lastLogsId !== 1) {
      setLastChannelLog(+lastLogsId - 1);
    }
  };

  const reversedData = logsData && [...logsData].reverse();

  const getClassName = (loglevel) => {
    switch (+loglevel) {
      case 0:
        return "critical";
      case 1:
        return "error";
      case 2:
        return "warning";
      case 3:
        return "info";
      default:
        return "debug";
    }
  };

  const handleRemoveLogs = () => {
    WebsocketManager.sendMessage(
      JSON.stringify({ cloudMsgType: "sendMessage", command: "deleteLogsFromDb", to: cwid })
    );
  };

  const getChannelName = (cloudId) => {
    switch (cloudId) {
      case "0":
        return NODE;
      default:
        return channelsData[cloudId]?.config?.name;
    }
  };

  const columns = [
    {
      title: <FormattedMessage id="Logs.id" defaultMessage="ID" />,
      dataIndex: "id",
      key: "id",
    },
    {
      title: <FormattedMessage id="Logs.time" defaultMessage="Time" />,
      dataIndex: "time",
      key: "time",
    },
    {
      title: <FormattedMessage id="Logs.logSource" defaultMessage="Log source" />,
      dataIndex: "channelId",
      key: "channelId",
      render: (value) => {
        const channelName = getChannelName(value);

        return channelName;
      },
    },
    {
      title: <FormattedMessage id="Logs.errorCode" defaultMessage="Error code" />,
      dataIndex: "errorCode",
      key: "errorCode",
    },
    {
      title: <FormattedMessage id="Logs.level" defaultMessage="Log level" />,
      dataIndex: "level",
      key: "level",
      render: (value) => {
        return <LogsLabel logValue={value} />;
      },
    },
    {
      title: <FormattedMessage id="Logs.description" defaultMessage="Description" />,
      dataIndex: "description",
      key: "description",
      render: (value) => {
        return <StyledDescription>{value}</StyledDescription>;
      },
    },
  ];

  return (
    <>
      <Row type="flex" justify="space-between">
        <Col span={12}>
          <StyledRightMargin>
            <FormattedMessage id="Logs.showLogs" defaultMessage="Show logs:" />
          </StyledRightMargin>
          <SelectEngine
            channels={channels}
            channelId={channelId}
            channelIds={parsedChannelsId}
            handleSelectChange={handleSelectChange}
          />
        </Col>
        <StyledRightCol span={12}>
          <Popconfirm
            title={
              <FormattedMessage
                id="Logs.confirmRemove"
                defaultMessage="Are you sure, you want to remove all logs from database?"
              />
            }
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={handleRemoveLogs}
            okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
            cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              <span>
                <FormattedMessage id="Logs.logsFromDB" defaultMessage="Delete logs" />
              </span>
            </Button>
          </Popconfirm>
        </StyledRightCol>
      </Row>
      <Divider />
      <StyledTable
        columns={columns}
        dataSource={logsExist ? reversedData : []}
        pagination={false}
        bordered
        role="table"
        rowKey={(record) => record.id}
        rowClassName={(record) => getClassName(record.level)}
      />
      <StyledButton type="primary" onClick={handleLoadMoreLogs}>
        <FormattedMessage id="Logs.loadMore" defaultMessage="Load more logs" />
      </StyledButton>
    </>
  );
};

const StyledRightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
`;

const StyledRightMargin = styled.span`
  margin-right: 5px;
`;

const StyledTable = styled(Table)`
  .ant-table-thead {
    .ant-table-cell {
      color: #fff;
      background-color: ${themeColor.orange};
    }
  }
  .critical {
    background-color: ${themeColor.logCritical};
  }
  .error {
    background-color: ${themeColor.logError};
  }
  .warning {
    background-color: ${themeColor.logWarning};
  }
  .debug {
    background-color: ${themeColor.logDebug};
  }
  .info {
    background-color: ${themeColor.logInfo};
  }
`;

const StyledDescription = styled.span`
  word-break: break-all;
`;

export const mapStateToProps = (state) => ({
  cwid: CONNECTION_SELECTORS.getNodeCwid(state),
  channels: CHANNEL_SELECTORS.getChannels(state),
  logs: LOGS_SELECTORS.getLogs(state),
});

Logs.propTypes = {
  cwid: PropTypes.string.isRequired,
  channels: ImmutablePropTypes.map.isRequired,
  channelId: PropTypes.string.isRequired,
  logs: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        channelId: PropTypes.string,
        engineStatus: PropTypes.string.isRequired,
        errorCode: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired,
        srcOrDest: PropTypes.string,
        time: PropTypes.string.isRequired,
        threadId: PropTypes.string,
      })
    )
  ),
  setChannelId: PropTypes.func.isRequired,
};

Logs.defaultProps = {
  logs: {},
};

export default connect(mapStateToProps, null)(Logs);
