import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { ExclamationCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Table, Divider, Tag, Tooltip, Affix, Row, Col, Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";

import { history } from "../../../../store";
import LicenseServices from "../../../../services/license";
import { selectors as LICENSE_SELECTORS } from "../../../../ducks/license";
import { selectors as ACCOUNT_SELECTORS } from "../../../../ducks/account";

import { errorNotification } from "../../../../lib/utils/notification";
import parseJwt from "../../../../lib/utils/parseJwt";
import themeColor from "../../../../lib/style/theme";

import ExtendLicense from "../ExtendLicense";
import LicenseActionButtons from "../LicenseActionButtons";

const PERMANENT_LICENSE = "never";

const setLicenseTagColor = (licenseType) => {
  switch (licenseType) {
    case "professional":
      return "geekblue";
    case "enterprise":
      return "cyan";
    default:
      return "gold";
  }
};

const adminColumn = [
  {
    title: <FormattedMessage id="AccountsList.fingerprint" defaultMessage="Fingerprint" />,
    dataIndex: "fingerprint",
    key: "fingerprint",
    render: (fingerprint) => (
      <StyledLicenseToken>
        <Tooltip title={fingerprint}>
          <StyledInfoCircleOutlined />
        </Tooltip>
      </StyledLicenseToken>
    ),
  },
  {
    title: <FormattedMessage id="AccountsList.action" defaultMessage="Action" />,
    key: "action",
    render: (action, record) => <LicenseActionButtons licenseId={record.id} />,
  },
];

const LicenseList = ({ licenseList, role }) => {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [selectedModalLicense, setSelectedModalLicense] = useState(null);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleShowModal = useCallback((licenseId) => {
    setSelectedModalLicense(licenseId);
    setShowModal(true);
  }, []);

  useEffect(() => {
    LicenseServices.getLicenseList({ errorNotification: errorNotification(formatMessage) });
  }, [formatMessage]);

  const [copiedLicenseId, setCopiedLicenseId] = useState(null);

  const onCopy = (event, licenceId) => {
    const { target } = event;
    target.blur();
    setCopiedLicenseId(licenceId);
    setTimeout(() => {
      setCopiedLicenseId(null);
    }, 1000);
  };

  const isAdmin = role === "admin";

  const columns = [
    {
      title: <FormattedMessage id="AccountsList.licenseId" defaultMessage="License ID" />,
      dataIndex: "licenseId",
      key: "licenseId",
      sorter: (currentValue, nextValue) => currentValue.licenseId.localeCompare(nextValue.licenseId),
    },
    {
      title: <FormattedMessage id="AccountsList.username" defaultMessage="Username" />,
      dataIndex: "username",
      key: "username",
      sorter: (currentValue, nextValue) => currentValue.username.localeCompare(nextValue.username),
    },
    {
      title: <FormattedMessage id="AccountsList.licenseType" defaultMessage="License type" />,
      dataIndex: "licenseType",
      key: "licenseType",
      sorter: (currentValue, nextValue) => currentValue.licenseType.localeCompare(nextValue.licenseType),
      render: (licenseType, record) => (
        <Tag color={setLicenseTagColor(licenseType)} key={record.id}>
          {licenseType && licenseType.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: <FormattedMessage id="AccountsList.engines" defaultMessage="Channels" />,
      dataIndex: "engines",
      key: "engines",
      sorter: (currentValue, nextValue) => currentValue.engines - nextValue.engines,
    },
    {
      title: <FormattedMessage id="AccountsList.expiration" defaultMessage="Expiration" />,
      dataIndex: "licenseExpire",
      key: "licenseExpire",
      sorter: (currentValue, nextValue) => currentValue.licenseExpire.localeCompare(nextValue.licenseExpire),
      render: (licenseExpire, record) => {
        if (licenseExpire === PERMANENT_LICENSE) {
          return <span>{licenseExpire}</span>;
        }

        if (moment().isAfter(licenseExpire)) {
          return (
            <span>
              <Row justify="space-between" align="middle">
                <Col>
                  {licenseExpire}{" "}
                  <Tooltip
                    title={<FormattedMessage id="AccountsList.licenseExpired" defaultMessage="License expired" />}
                  >
                    <ExclamationCircleOutlined style={{ color: `${themeColor.darkRed}` }} />
                  </Tooltip>
                </Col>
                {isAdmin && (
                  <Col>
                    <Button type="primary" onClick={() => handleShowModal(record.licenseId)}>
                      <FormattedMessage id="AccountsList.extend" defaultMessage="Extend" />
                    </Button>
                  </Col>
                )}
              </Row>
            </span>
          );
        }

        return (
          <Row justify="space-between" align="middle">
            <Col>{licenseExpire}</Col>
            {isAdmin && (
              <Col>
                <Button type="primary" onClick={() => handleShowModal(record.licenseId)}>
                  <FormattedMessage id="AccountsList.extend" defaultMessage="Extend" />
                </Button>
              </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: <FormattedMessage id="AccountsList.licenseToken" defaultMessage="License" />,
      dataIndex: "encryptedLicenseToken",
      key: "encryptedLicenseToken",
      render: (encryptedLicenseToken, record) => (
        <StyledLicenseToken>
          <CopyToClipboard text={encryptedLicenseToken}>
            <Tooltip
              title={<FormattedMessage id="AccountsList.copied" defaultMessage="Copied" />}
              placement="right"
              visible={record.licenseId === copiedLicenseId}
            >
              <Button color="orange" onClick={(event) => onCopy(event, record.licenseId)}>
                <FormattedMessage id="AccountsList.copy" defaultMessage="Copy" />
              </Button>
            </Tooltip>
          </CopyToClipboard>
          <Tooltip title={encryptedLicenseToken}>
            <StyledInfoCircleOutlined />
          </Tooltip>
        </StyledLicenseToken>
      ),
    },
  ];

  const parsedColumns = isAdmin ? [...columns, ...adminColumn] : columns;

  const parsedLicenseList = licenseList
    .map((license) => {
      return {
        ...parseJwt(license.licenseToken),
        id: license.id,
        licenseToken: license.licenseToken,
        encryptedLicenseToken: license.encryptedLicenseToken,
        fingerprint: license.fingerprint,
      };
    })
    .reverse();

  const setClassName = (record) => {
    if (record.licenseExpire === PERMANENT_LICENSE) {
      return null;
    }

    if (moment().isAfter(record.licenseExpire)) {
      return "licenseExpired";
    }

    return "";
  };

  return (
    <>
      <StyledTable
        columns={parsedColumns}
        rowClassName={setClassName}
        dataSource={parsedLicenseList}
        pagination={false}
        bordered
        role="table"
        rowKey={(record) => record.licenseId}
      />
      {isAdmin && (
        <>
          <Divider />
          <Affix offsetBottom={15}>
            <FullSizeButton type="primary" onClick={() => history.push("/admin/license/new")}>
              <FormattedMessage id="AccountsList.addNewLicense" defaultMessage="Add new license" />
            </FullSizeButton>
          </Affix>
          <Modal
            title={
              <span>
                <FormattedMessage id="AccountsList.extendModalTitle" defaultMessage="Extend license" />
                {": "}
                {selectedModalLicense}
              </span>
            }
            visible={showModal}
            footer={null}
            onCancel={handleCloseModal}
          >
            <ExtendLicense selectedModalLicense={selectedModalLicense} handleCloseModal={handleCloseModal} />
          </Modal>
        </>
      )}
    </>
  );
};

LicenseList.propTypes = {
  licenseList: PropTypes.arrayOf(
    PropTypes.exact({
      encryptedLicenseToken: PropTypes.string.isRequired,
      fingerprint: PropTypes.string,
      id: PropTypes.number.isRequired,
      licenseToken: PropTypes.string.isRequired,
    })
  ).isRequired,
  role: PropTypes.string.isRequired,
};

const StyledLicenseToken = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const mapStateToProps = (state) => ({
  licenseList: LICENSE_SELECTORS.getLicense(state),
  role: ACCOUNT_SELECTORS.getRole(state),
});

const FullSizeButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
`;

const StyledTable = styled(Table)`
  .licenseExpired {
    background-color: ${themeColor.brightRed};
  }
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  margin-left: 10px;
`;

export default connect(mapStateToProps, null)(LicenseList);
