import React, { useEffect, useRef } from "react";
import { Line, Chart } from "react-chartjs-2";
// import moment from "moment";
import zoom from "chartjs-plugin-zoom";
import { Col, Select, Button, Row } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const { Option } = Select;

const NodeStatisticsGraph = ({ data, selectedStat, setSelectedStat }) => {
  const statRef = useRef(null);
  useEffect(() => {
    Chart.plugins.register(zoom);
  }, []);

  const dataKeysArray = Object.keys(data);
  const selectedData = data[selectedStat] || [];

  const handleResetZoom = () => {
    statRef.current.chartInstance.resetZoom();
  };

  const handleChange = (selectedValue) => {
    setSelectedStat(selectedValue);
    handleResetZoom();
  };

  const options = {
    pan: {
      enabled: true,
      mode: "yx",
    },
    zoom: {
      enabled: true,
      mode: "x",
    },
  };

  const parsedData = {
    labels: selectedData.map((statistic) => statistic.time),
    datasets: [
      {
        backgroundColor: "rgba(75,192,192,0.4)",
        borderCapStyle: "butt",
        borderColor: "rgba(75,192,192,1)",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        data: selectedData.map((statistic) => statistic.value),
        fill: false,
        label: `${selectedStat} [Mibps]`,
        lineTension: 0.1,
        pointBackgroundColor: "#fff",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBorderWidth: 1,
        pointHitRadius: 10,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointHoverRadius: 5,
        pointRadius: 1,
      },
    ],
  };

  return (
    <>
      <Row type="flex" justify="space-between">
        <Col>
          <FormattedMessage id="NodeStatisticsGraph.selectInterface" defaultMessage="Select network interface:" />{" "}
          <Select onChange={handleChange} style={{ width: 250 }} value={selectedStat}>
            {dataKeysArray.map((networkInterfaces) => (
              <Option key={networkInterfaces}>{networkInterfaces}</Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Button type="primary" onClick={handleResetZoom}>
            <FormattedMessage id="NodeStatisticsGraph.resetZoom" defaultMessage="Reset zoom" />
          </Button>
        </Col>
      </Row>
      <Line data={parsedData} options={options} ref={statRef} />
    </>
  );
};

NodeStatisticsGraph.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape(
      PropTypes.arrayOf(
        PropTypes.shape({
          time: PropTypes.string,
          value: PropTypes.number,
        })
      )
    ),
    PropTypes.object,
  ]).isRequired,
  selectedStat: PropTypes.string,
  setSelectedStat: PropTypes.func.isRequired,
};

NodeStatisticsGraph.defaultProps = {
  selectedStat: null,
};

export default NodeStatisticsGraph;
