import React, { useState } from "react";
import { Row, Card, Divider, Tabs, Spin } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { selectors as STATISTIC_NODE_SELECTORS } from "../../../../ducks/nodeSystemStatistics";
import { selectors as CONNECTION_SELECTORS } from "../../../../ducks/node";

import NodeHeaderSystemStats from "../../../../components/NodeHeaderSystemStats";

import NodeStatisticsGraph from "./NodeStatisticsGraph";

import { NODE_STATS_TYPE } from "../../../../lib/utils/constants";

const { TabPane } = Tabs;

const NodeStatistics = ({ nodeNetworkInterfaces, cwid }) => {
  const filteredIncomingStats = nodeNetworkInterfaces && nodeNetworkInterfaces[cwid]?.incoming;
  const filteredOutgoingStats = nodeNetworkInterfaces && nodeNetworkInterfaces[cwid]?.outgoing;

  const [activeTab, setActiveTab] = useState(NODE_STATS_TYPE.incoming);
  const dataKeysArray = (filteredIncomingStats && Object.keys(filteredIncomingStats)) || [""];
  const [selectedStat, setSelectedStat] = useState(dataKeysArray[0]);

  const handleChangeTab = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  if (!filteredIncomingStats && !filteredOutgoingStats) {
    return (
      <StyledCard title={<FormattedMessage id="NodeStatistics.title" defaultMessage="Node Statistics" />}>
        <Row type="flex" justify="center">
          <Spin tip={<FormattedMessage id="NodeStatisticsGraph.waitingForData" defaultMessage="Waiting for data" />} />
        </Row>
      </StyledCard>
    );
  }

  return (
    <StyledCard title={<FormattedMessage id="NodeStatistics.title" defaultMessage="Node Statistics" />}>
      <Row type="flex" justify="start">
        <NodeHeaderSystemStats />
      </Row>
      <Divider />
      <Tabs defaultActiveKey={activeTab} onChange={handleChangeTab}>
        <TabPane
          tab={<FormattedMessage id="NodeStatistics.incomingTraffic" defaultMessage="Incoming network traffic" />}
          key={NODE_STATS_TYPE.incoming}
        >
          {activeTab === NODE_STATS_TYPE.incoming && (
            <NodeStatisticsGraph
              data={filteredIncomingStats}
              selectedStat={selectedStat}
              setSelectedStat={setSelectedStat}
            />
          )}
        </TabPane>
        <TabPane
          tab={<FormattedMessage id="NodeStatistics.outgoingTraffic" defaultMessage="Outgoing network traffic" />}
          key={NODE_STATS_TYPE.outgoing}
        >
          {activeTab === NODE_STATS_TYPE.outgoing && (
            <NodeStatisticsGraph
              data={filteredOutgoingStats}
              selectedStat={selectedStat}
              setSelectedStat={setSelectedStat}
            />
          )}
        </TabPane>
      </Tabs>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: 10px;
  min-height: 625px;
`;

const mapStateToProps = (state) => ({
  nodeNetworkInterfaces: STATISTIC_NODE_SELECTORS.getStatisticNodeInterfaces(state),
  cwid: CONNECTION_SELECTORS.getNodeCwid(state),
});

NodeStatistics.propTypes = {
  cwid: PropTypes.string,
  nodeNetworkInterfaces: PropTypes.shape({
    incomingStats: PropTypes.oneOfType([
      PropTypes.shape(
        PropTypes.arrayOf(
          PropTypes.shape({
            time: PropTypes.string,
            value: PropTypes.number,
          })
        )
      ),
    ]),
    outgoingStats: PropTypes.oneOfType([
      PropTypes.shape(
        PropTypes.arrayOf(
          PropTypes.shape({
            time: PropTypes.string,
            value: PropTypes.number,
          })
        )
      ),
    ]),
  }),
};

NodeStatistics.defaultProps = {
  cwid: null,
  nodeNetworkInterfaces: null,
};

export default connect(mapStateToProps)(NodeStatistics);
