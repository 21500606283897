import React, { useEffect } from "react";
import { Card, Divider, Tabs } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { Redirect } from "react-router";

import LogLevel from "./LogLevel";
import EditNodeName from "./EditNodeName";

import { selectors as CLOUD_CONNECTION_SELECTORS } from "../../../ducks/cloudConnection";
import { actions as NODE_ACTIONS } from "../../../ducks/node";

import { errorNotification } from "../../../lib/utils/notification";
import store from "../../../store";

import NodeLiveStatistics from "./NodeLiveStatistics";

const { TabPane } = Tabs;

const translations = defineMessages({
  wrongCloudNodeName: {
    id: "general.wrongCloudNodeName",
    defaultMessage: "Node does not exist in Quickstream Cloud",
  },
});

const NodeSystem = ({
  nodes,
  match: {
    params: { cwid },
  },
}) => {
  const { formatMessage } = useIntl();
  const nodeData = nodes.find((node) => node.cwid === cwid);

  useEffect(() => {
    if (nodeData?.cwid) {
      store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    }
  }, [nodeData]);

  if (nodes.length > 0 && !nodeData) {
    errorNotification(formatMessage)(translations.wrongCloudNodeName);

    return <Redirect to="/shares" />;
  }

  return (
    <Card>
      <Tabs defaultActiveKey="1">
        <TabPane tab={<FormattedMessage id="ChannelSystem.General" defaultMessage="General" />} key="1">
          <LogLevel />
          <Divider />
          <EditNodeName />
        </TabPane>
        <TabPane tab={<FormattedMessage id="ChannelSystem.statistics" defaultMessage="Statistics" />} key="2">
          <NodeLiveStatistics />
        </TabPane>
      </Tabs>
    </Card>
  );
};

NodeSystem.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ cnn: PropTypes.string.isRequired, cwid: PropTypes.string.isRequired }),
  }).isRequired,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      cnn: PropTypes.string.isRequired,
      cwid: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  nodes: CLOUD_CONNECTION_SELECTORS.getNodes(state),
});

export default connect(mapStateToProps, null)(NodeSystem);
