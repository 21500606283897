import React, { useState, useCallback } from "react";
import { DatabaseOutlined, TeamOutlined, VideoCameraOutlined, BankOutlined } from "@ant-design/icons";
import { Layout, Menu, Row } from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Cookies from "universal-cookie";
import store, { history } from "../../store";

import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";
import { selectors as NAVIGATION_SELECTORS, actions as NAVIGATION_ACTIONS } from "../../ducks/navigation";

import UserMenu from "../UserMenu";
import NavigationLayoutRoutes from "../NavigationLayoutRoutes";
import PageHeader from "../PageHeader";
import SelectLang from "../SelectLang";
import logoUrl from "../../lib/images/logo.png";

import NodesList from "../NodesList";
import About from "../About";
import TutorialsModal from "../TutorialsModal";

const { Header, Content, Sider } = Layout;
const { Item } = Menu;
const cookies = new Cookies();

const Home = ({ activeKey, activeSubmenu, role }) => {
  const isAdmin = role === "admin";

  const [collapsed, setCollapsed] = useState(false);

  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showTutorialsModal, setShowTutorialsModal] = useState(cookies.get("QuickstreamSkipTutorials") !== "true");

  const handleShowAboutModal = useCallback(() => {
    setShowAboutModal(!showAboutModal);
  }, [showAboutModal]);

  const handleShowTutorialsModal = useCallback(() => {
    setShowTutorialsModal(!showTutorialsModal);
  }, [showTutorialsModal]);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleNavClick = useCallback((navElement) => {
    store.dispatch(NAVIGATION_ACTIONS.SET_ACTIVE_KEY({ activeKey: [navElement.key] }));
  }, []);

  const handleChangeSubmenu = useCallback((openKeys) => {
    store.dispatch(NAVIGATION_ACTIONS.SET_ACTIVE_SUBMENU({ activeSubmenu: [...openKeys] }));
  }, []);

  return (
    <Layout>
      <StyledHeader className="header">
        <Row>
          <Logo />
        </Row>
        <StyledOverlay>
          <SelectLang />
          <UserMenu />
        </StyledOverlay>
      </StyledHeader>
      <Layout>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} width={250}>
          <Menu
            mode="inline"
            theme="dark"
            onClick={handleNavClick}
            selectedKeys={activeKey}
            openKeys={activeSubmenu}
            onOpenChange={handleChangeSubmenu}
          >
            <StyledItem key="/shares" onClick={() => history.push("/shares")}>
              <VideoCameraOutlined />
              <span>
                <FormattedMessage id="userMenu.shares" defaultMessage="Shares" />
              </span>
            </StyledItem>
            {isAdmin && (
              <Item key="/admin/accounts" onClick={() => history.push("/admin/accounts")}>
                <TeamOutlined />
                <span>
                  <FormattedMessage id="userMenu.manageAccounts" defaultMessage="Accounts" />
                </span>
              </Item>
            )}
            {isAdmin && (
              <Item key="/admin/license" onClick={() => history.push("/admin/license")}>
                <DatabaseOutlined />
                <span>
                  <FormattedMessage id="userMenu.manageLicense" defaultMessage="All licenses" />
                </span>
              </Item>
            )}
            {!isAdmin && (
              <Item key="/admin/license" onClick={() => history.push("/admin/license")}>
                <DatabaseOutlined />
                <span>
                  <FormattedMessage id="userMenu.licenses" defaultMessage="Licenses" />
                </span>
              </Item>
            )}
            <NodesList key="/cloudNodes" />
            <Item key="about" onClick={handleShowAboutModal}>
              <BankOutlined />
              <span>
                <FormattedMessage id="GuestView.about" defaultMessage="About" />
              </span>
            </Item>
          </Menu>
        </Sider>
        <Layout>
          <StyledContent>
            <PageHeader />
            <NavigationLayoutRoutes />
          </StyledContent>
        </Layout>
      </Layout>
      <About handleShowModal={handleShowAboutModal} showModal={showAboutModal} />
      <TutorialsModal handleShowTutorialsModal={handleShowTutorialsModal} showTutorialsModal={showTutorialsModal} />
    </Layout>
  );
};

const StyledContent = styled(Content)`
  background: #fff;
  padding: 24px;
  margin: 0;
  min-height: 28px;
`;

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;

  &.ant-layout-header {
    padding-right: 0;
  }
`;

const StyledOverlay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
`;

const Logo = styled.div`
  width: 300px;
  height: 64px;
  background: url(${logoUrl});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

const StyledItem = styled(Item)`
  margin-top: 0px !important;
`;

Home.propTypes = {
  activeKey: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  activeSubmenu: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.array]).isRequired,
  role: PropTypes.string,
};

Home.defaultProps = {
  role: "user",
};

const mapStateToProps = (state) => ({
  activeKey: NAVIGATION_SELECTORS.getActiveNavKey(state),
  activeSubmenu: NAVIGATION_SELECTORS.getActiveNavSubmenu(state),
  role: ACCOUNT_SELECTORS.getRole(state),
});

export default connect(mapStateToProps, null)(Home);
