import React from "react";
import { Col, Card } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import themeColor from "../../lib/style/theme";

const DemoConnectionMethod = ({ label }) => (
  <Col span={24}>
    <Card title={label}>
      <span>
        <FormattedMessage
          id="DemoConnectionMethod.betaUserAvailable"
          defaultMessage="This connection method is available only for a limited number of beta testers"
        />
      </span>
      .{" "}
      <span>
        <FormattedMessage id="DemoConnectionMethod.pleas" defaultMessage="Please" />
      </span>
      <StyledLink href="mailto:hello@quickstream.tech">contact us</StyledLink>
      <span>
        <FormattedMessage id="DemoConnectionMethod.toJoin" defaultMessage="to join the testing program" />
      </span>
      .
    </Card>
  </Col>
);

const StyledLink = styled.a`
  color: ${themeColor.yellowLink};
  margin: 0 5px;

  &:hover {
    color: ${themeColor.yellowLinkHover};
  }
`;

DemoConnectionMethod.propTypes = {
  label: PropTypes.string.isRequired,
};

export default DemoConnectionMethod;
