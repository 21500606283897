import React, { useState, useCallback } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Affix } from "antd";
import { FormattedMessage } from "react-intl";

import NewChannelFormModal from "./NewChannelFormModal";

const CreateChannelModal = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  return (
    <>
      <Affix offsetTop={15}>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleShowModal}>
          <span>
            <FormattedMessage id="CreateChannelModal.createNewChannel" defaultMessage="Create new share" />
          </span>
        </Button>
      </Affix>
      <Modal
        title={<FormattedMessage id="CreateChannelModal.title" defaultMessage="New share form" />}
        visible={showModal}
        footer={null}
        onCancel={handleShowModal}
      >
        <NewChannelFormModal handleShowModal={handleShowModal} />
      </Modal>
    </>
  );
};

export default CreateChannelModal;
