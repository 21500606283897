import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Row, Tooltip, Spin, Divider } from "antd";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { ifProp } from "styled-tools";

import { selectors as STATISTIC_NODE_SELECTORS } from "../../ducks/nodeSystemStatistics";
import { selectors as CONNECTION_SELECTORS } from "../../ducks/node";

import themeColor from "../../lib/style/theme";

const NodeHeaderSystemStats = ({ statisticsNode, size, cwid }) => {
  const filteredStatisticsNode = statisticsNode && statisticsNode[cwid];
  const newestStatisticNode = filteredStatisticsNode && filteredStatisticsNode[filteredStatisticsNode.length - 1];

  return (
    <Row align="middle">
      <Tooltip placement="top" title={<FormattedMessage id="NodeSubTitle.cpuUsage" defaultMessage="CPU usage" />}>
        <StyledOrangeConnectLabelSmall size={size}>
          <FormattedMessage id="general.cpu" defaultMessage="CPU" />:{" "}
          {newestStatisticNode && <span>{newestStatisticNode.cpuLoad}%</span>}
          {!newestStatisticNode && (
            <span>
              <Spin size="small" />
            </span>
          )}
        </StyledOrangeConnectLabelSmall>
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip placement="top" title={<FormattedMessage id="NodeSubTitle.ramUsage" defaultMessage="RAM usage" />}>
        <StyledOrangeConnectLabelSmall size={size}>
          <FormattedMessage id="general.ram" defaultMessage="RAM" />:{" "}
          {newestStatisticNode && (
            <span>{Math.round(100 - (newestStatisticNode?.ramAvailable * 100) / newestStatisticNode?.ramTotal)}%</span>
          )}
          {!newestStatisticNode && (
            <span>
              <Spin size="small" />
            </span>
          )}
        </StyledOrangeConnectLabelSmall>
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip
        placement="top"
        title={
          <FormattedMessage id="NodeSubTitle.totalIncomingNetwork" defaultMessage="Total incoming network traffic" />
        }
      >
        <StyledOrangeConnectLabel size={size}>
          <FormattedMessage id="general.in" defaultMessage="In" />:{" "}
          {newestStatisticNode && <span>{newestStatisticNode.totalIncoming} [Mibps]</span>}
          {!newestStatisticNode && (
            <span>
              <Spin size="small" />
            </span>
          )}
        </StyledOrangeConnectLabel>
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip
        placement="top"
        title={
          <FormattedMessage id="NodeSubTitle.totalOutgoingNetwork" defaultMessage="Total outgoing network traffic" />
        }
      >
        <StyledOrangeConnectLabel size={size}>
          <FormattedMessage id="general.out" defaultMessage="Out" />:{" "}
          {newestStatisticNode && <span>{newestStatisticNode.totalOutgoing} [Mibps]</span>}
          {!newestStatisticNode && (
            <span>
              <Spin size="small" />
            </span>
          )}
        </StyledOrangeConnectLabel>
      </Tooltip>
    </Row>
  );
};

NodeHeaderSystemStats.propTypes = {
  cwid: PropTypes.string,
  size: PropTypes.string,
  statisticsNode: PropTypes.object.isRequired,
};

NodeHeaderSystemStats.defaultProps = {
  size: null,
  cwid: null,
};

const StyledOrangeConnectLabel = styled.span`
  color: ${themeColor.orange};
  margin-right: 10px;
  font-size: ${ifProp({ size: "small" }, "12px", "16px")};
  min-width: ${ifProp({ size: "small" }, "90px", "120px")};
`;

const StyledOrangeConnectLabelSmall = styled(StyledOrangeConnectLabel)`
  min-width: ${ifProp({ size: "small" }, "60px", "90px")};
`;

const mapStateToProps = (state) => ({
  statisticsNode: STATISTIC_NODE_SELECTORS.getStatisticNode(state),
  cwid: CONNECTION_SELECTORS.getNodeCwid(state),
});

export default connect(mapStateToProps, null)(NodeHeaderSystemStats);
