import * as React from "react";
import { FormattedMessage } from "react-intl";

export const required = {
  required: true,
  message: <FormattedMessage id="validation.required" defaultMessage="Field is required" />,
};

export const password = {
  pattern: new RegExp(`[a-zA-Z0-9!@#$%^&*\\(\\)=_+\\|\\/.,<>;:'"\\[{\\]}-]{3,}`),
  message: (
    <FormattedMessage
      id="validation.password"
      defaultMessage="Min. 3 characters from range (A-Z, 0-9, special characters or space)."
    />
  ),
};

export const az09 = {
  pattern: new RegExp(`^[a-zA-Z0-9]$|^[a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9]+$`),
  message: <FormattedMessage id="validation.az09" defaultMessage="Please use only (a-z, -, 0-9,) characters" />,
};

export const az09space = {
  pattern: new RegExp(`^[a-zA-Z0-9]$|^[a-zA-Z0-9]+[a-zA-Z0-9- ]*[a-zA-Z0-9]+$`),
  message: (
    <FormattedMessage id="validation.az09space" defaultMessage="Please use only (a-z, -, space, 0-9,) characters" />
  ),
};

export const min10characters = {
  min: 10,
  message: <FormattedMessage id="validation.pasmin10characterssword" defaultMessage="Min. 10 characters" />,
};

export const max79characters = {
  max: 79,
  message: <FormattedMessage id="validation.max79characters" defaultMessage="Max. 79 characters" />,
};

export const max32characters = {
  max: 32,
  message: <FormattedMessage id="validation.max32characters" defaultMessage="Max. 32 characters" />,
};
