import React, { useState } from "react";
import { Tooltip, Badge, Button, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import store, { history } from "../../store";

import { actions as NAVIGATION_ACTIONS } from "../../ducks/navigation";
import { actions as NODE_ACTIONS } from "../../ducks/node";
import { DELETE_LOGS } from "../../ducks/logs";
import { CLEAR_HIST_STATISTIC } from "../../ducks/nodeStatistics";

import { WEBSOCKET_STATUS } from "../../lib/utils/constants";
import checkClientVersion from "../../lib/utils/checkClientVersion";
import redirectClientVersion from "../../lib/utils/redirectClientVersion";

const NodesListTitle = ({ status, cwid, cnn, nodeVersion }) => {
  const [showNodeVersionModal, setShowNodeVersionModal] = useState(false);

  const handleOkRedirect = () => {
    if (cwid && cnn && nodeVersion) {
      redirectClientVersion({
        nodeVersion,
        url: `/cloudNodes/${cwid}/${cnn}/channels`,
      });
    }
  };

  const handleCancelRedirect = () => {
    setShowNodeVersionModal(false);
  };

  const handleChangeNode = () => {
    checkClientVersion({ nodeVersion, url: `/cloudNodes/${cwid}/${cnn}/channels` });

    const changeClientVersion = checkClientVersion({ nodeVersion });

    if (changeClientVersion) {
      setShowNodeVersionModal(true);

      return;
    }

    store.dispatch(NAVIGATION_ACTIONS.SET_CHANNELS_ACTIVE_TAB({ channelsActiveTab: "channelList" }));
    store.dispatch(NODE_ACTIONS.CLEAR_NODE_CONNECTION());
    store.dispatch(DELETE_LOGS());
    store.dispatch(CLEAR_HIST_STATISTIC());

    history.push(`/cloudNodes/${cwid}/${cnn}/channels`);
  };

  return (
    <>
      <Tooltip
        placement="right"
        title={
          status === WEBSOCKET_STATUS.connected ? (
            <FormattedMessage id="general.online" defaultMessage="Online" />
          ) : (
            <FormattedMessage id="general.offline" defaultMessage="Offline" />
          )
        }
      >
        {status === WEBSOCKET_STATUS.connected && (
          <StyledButton type="text" onClick={handleChangeNode}>
            <Badge status="success" /> {cnn}
          </StyledButton>
        )}
        {status !== WEBSOCKET_STATUS.connected && (
          <>
            <StyledBadge status="error" /> {cnn}
          </>
        )}
      </Tooltip>
      <Modal
        title={<FormattedMessage id="NodesListTitle.clientVersion" defaultMessage="Cloud version validation" />}
        visible={showNodeVersionModal}
        onOk={handleOkRedirect}
        onCancel={handleCancelRedirect}
        okText={<FormattedMessage id="NodesListTitle.redirect" defaultMessage="Redirect" />}
      >
        <div>
          <FormattedMessage
            id="NodesListTitle.cloudVersionInfo"
            defaultMessage="Using this node in cloud requires different cloud interface version"
          />
          .
        </div>
      </Modal>
    </>
  );
};

const StyledBadge = styled(Badge)`
  padding-left: 15px;
`;

const StyledButton = styled(Button)`
  color: #fff !important;
`;

NodesListTitle.propTypes = {
  cwid: PropTypes.string.isRequired,
  cnn: PropTypes.string.isRequired,
  nodeVersion: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default NodesListTitle;
