import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { Button, Popconfirm, Divider, Row, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { NODE_CHANNEL_STATE, FORM_VERSION } from "../../lib/utils/constants";
import { errorNotification } from "../../lib/utils/notification";

import NODE_CHANNELS_SERVICES from "../../services/node";
import WebsocketService from "../../services/websocket";

import { history } from "../../store";

import CloudDisableTooltip from "../CloudDisableTooltip";
import StyledButton from "../StyledButton";

const translations = defineMessages({
  wrongConfigurationVersion: {
    id: "ChannelListActionButtons.wrongConfigurationVersion",
    defaultMessage: "Channel configuration version not supported",
  },
});

const NodeChannelsActionButtons = ({
  cnn,
  cwid,
  status,
  channelId,
  cloudData,
  loggedToCloud,
  requestStatus,
  quickstreamMethod,
  version,
}) => {
  const { formatMessage } = useIntl();
  const [waitingButton, setWaitingButton] = useState(null);

  useEffect(() => {
    setWaitingButton(null);
  }, [status]);

  const onActionClick = (actionName) => {
    setWaitingButton(actionName);
    setTimeout(() => {
      setWaitingButton(null);
    }, 3000);
  };

  onActionClick.propTypes = {
    actionName: PropTypes.oneOf(["start", "stop", "delete"]),
  };

  const onConfirmRemove = useCallback(() => {
    NODE_CHANNELS_SERVICES.deleteChannel({ cloudData }, { errorNotification: errorNotification(formatMessage) });
    NODE_CHANNELS_SERVICES.clearStatistic({ channelId, from: cwid });
    onActionClick("delete");
    WebsocketService.sendMessage(
      JSON.stringify({ command: "deleteChannel", channelId, to: cwid, cloudMsgType: "sendMessage" })
    );
  }, [channelId, cloudData, cwid, formatMessage]);

  const handleEditChannel = useCallback(() => {
    if (+version > +FORM_VERSION) {
      errorNotification(formatMessage)(translations.wrongConfigurationVersion);
    } else {
      history.push(`/cloudNodes/${cwid}/${cnn}/channel/edit/${channelId}`);
    }
  }, [channelId, cnn, cwid, formatMessage, version]);

  const handleStartChannel = useCallback(() => {
    WebsocketService.sendMessage(
      JSON.stringify({ command: "streamPlay", channelId, to: cwid, cloudMsgType: "sendMessage" })
    );
    NODE_CHANNELS_SERVICES.clearStatistic({ channelId, from: cwid });
    onActionClick("start");
  }, [channelId, cwid]);

  return (
    <Row type="flex" justify="center" align="middle">
      <StyledButton
        button-color="green"
        onClick={handleStartChannel}
        loading={waitingButton === "start"}
        disabled={waitingButton === "delete" || ![NODE_CHANNEL_STATE.STOPPED].includes(status)}
      >
        {waitingButton !== "start" ? (
          <FormattedMessage id="ChannelList.action.start" defaultMessage="START" />
        ) : (
          <span />
        )}
      </StyledButton>
      <Divider type="vertical" />
      <StyledButton
        onClick={useCallback(() => {
          WebsocketService.sendMessage(
            JSON.stringify({ command: "streamStop", channelId, to: cwid, cloudMsgType: "sendMessage" })
          );
          onActionClick("stop");
        }, [channelId, cwid])}
        loading={waitingButton === "stop"}
        disabled={![NODE_CHANNEL_STATE.CONNECTED].includes(requestStatus)}
      >
        {waitingButton !== "stop" ? <FormattedMessage id="ChannelList.actionStop" defaultMessage="STOP" /> : <span />}
      </StyledButton>
      <Divider type="vertical" />
      <Tooltip
        placement="top"
        title={
          quickstreamMethod && !loggedToCloud ? (
            <CloudDisableTooltip
              title={
                <FormattedMessage
                  id="general.disableRemoveEditQSMode"
                  defaultMessage="to the Quickstream Cloud as channel owner is needed to remove/edit this channel"
                />
              }
            />
          ) : null
        }
      >
        <Button
          onClick={handleEditChannel}
          disabled={
            (quickstreamMethod && !loggedToCloud) ||
            ![NODE_CHANNEL_STATE.STOPPED, NODE_CHANNEL_STATE.UNCONFIGURED].includes(status)
          }
        >
          <FormattedMessage id="ChannelList.action.edit" defaultMessage="EDIT" />
        </Button>
      </Tooltip>
      <Divider type="vertical" />
      <Tooltip
        placement="top"
        title={
          quickstreamMethod && !loggedToCloud ? (
            <CloudDisableTooltip
              title={
                <FormattedMessage
                  id="general.disableRemoveEditQSMode"
                  defaultMessage="to the Quickstream Cloud as channel owner is needed to remove/edit this channel"
                />
              }
            />
          ) : null
        }
      >
        <Popconfirm
          disabled={
            (quickstreamMethod && !loggedToCloud) ||
            waitingButton === "start" ||
            [NODE_CHANNEL_STATE.CONNECTED, NODE_CHANNEL_STATE.CONNECTING, NODE_CHANNEL_STATE.RECONNECTING].includes(
              status
            )
          }
          title={
            <FormattedMessage
              id="NodeChannelsActionButtons.confirmRemove"
              defaultMessage="Are you sure, you want to remove this channel?"
            />
          }
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          onConfirm={onConfirmRemove}
          okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
          cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
        >
          <Button
            type="danger"
            disabled={
              (quickstreamMethod && !loggedToCloud) ||
              waitingButton === "start" ||
              [NODE_CHANNEL_STATE.CONNECTED, NODE_CHANNEL_STATE.CONNECTING, NODE_CHANNEL_STATE.RECONNECTING].includes(
                status
              )
            }
            loading={waitingButton === "delete"}
            onClick={() => null}
          >
            {waitingButton !== "delete" ? (
              <FormattedMessage id="ChannelList.action.delete" defaultMessage="DELETE" />
            ) : (
              <span />
            )}
          </Button>
        </Popconfirm>
      </Tooltip>
    </Row>
  );
};

const StyledActionButton = styled(StyledButton)`
  &.ant-btn-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

// eslint-disable-next-line no-underscore-dangle
StyledActionButton.__ANT_BUTTON = true;

NodeChannelsActionButtons.propTypes = {
  cnn: PropTypes.string,
  cwid: PropTypes.string,
  cloudData: PropTypes.shape({
    hasCloudId: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
    hasPermissionId: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  }).isRequired,
  channelId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  requestStatus: PropTypes.string.isRequired,
  loggedToCloud: PropTypes.bool.isRequired,
  quickstreamMethod: PropTypes.bool.isRequired,
  version: PropTypes.string.isRequired,
};

NodeChannelsActionButtons.defaultProps = {
  cnn: null,
  cwid: null,
};

export default NodeChannelsActionButtons;
