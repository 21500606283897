import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";

export const actions = {
  SAVE_LICENSE: createAction("LICENSE/SAVE_LICENSE"),
  CLEAR_LICENSE: createAction("LICENSE/CLEAR_LICENSE"),
};

const initialState = new Map({
  licenseList: [],
});

const reducer = handleActions(
  {
    [actions.SAVE_LICENSE]: (state, { payload }) => state.merge({ licenseList: payload }),
    [actions.CLEAR_LICENSE]: () => initialState,
  },
  initialState
);

export const selectors = {
  getLicense: (state) => state.getIn(["license", "licenseList"]),
};

export default reducer;
