import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";
import { LOGS_TYPE } from "../lib/utils/constants";

export const SET_LOG_LEVEL = createAction("LOGS/SET_LOG_LEVEL");
export const LOAD_MORE_LOG_DATA = createAction("LOGS/LOAD_MORE_LOG_DATA");
export const NEW_LOG_DATA = createAction("LOGS/NEW_LOG_DATA");
export const DELETE_LOGS = createAction("LOGS/DELETE_LOGS");

const defaultState = new Map({
  logsMetaData: null,
});

const reducer = handleActions(
  {
    [SET_LOG_LEVEL]: (state, { payload }) => {
      return state.merge({ logsMetaData: payload });
    },
    [LOAD_MORE_LOG_DATA]: (state, { payload: { logs, channelId } }) => {
      const stateLogs = state.get("data") || [];
      const oldChannelLogs = stateLogs[channelId] || [];

      return state.merge({ data: { [channelId]: [...logs, ...oldChannelLogs] } });
    },
    [NEW_LOG_DATA]: (state, { payload: { channelId, description, engineStatus, errorCode, id, level, time } }) => {
      const newLog = { channelId, description, engineStatus, errorCode, id, level, time };
      const stateLogs = state.getIn(["data", `${channelId}`]);
      const allLogsIsActive = state.getIn(["data", `${LOGS_TYPE.all}`]);

      if (allLogsIsActive) {
        return state.mergeIn(["data", `${LOGS_TYPE.all}`], [newLog]);
      }
      if (!stateLogs) return state;

      return state.mergeIn(["data", `${channelId}`], [newLog]);
    },
    [DELETE_LOGS]: () => {
      return defaultState;
    },
  },
  defaultState
);

export const selectors = {
  getLogLevel: (state) => state.getIn(["logs", "logsMetaData", "level"]),
  getLogs: (state) => state.getIn(["logs", "data"]),
};

export default reducer;
