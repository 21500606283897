import { bindActionCreators } from "redux";
import { defineMessages } from "react-intl";

import store from "../store";
// import { CHANGE_CHANNELS_STATUS } from "../ducks/channels";
// import { SET_FILE_LIST } from "../ducks/fileList";
import { LOAD_MORE_LOG_DATA, NEW_LOG_DATA, DELETE_LOGS, SET_LOG_LEVEL } from "../ducks/logs";
import { SET_STATISTIC, CLEAR_STATISTIC, CLEAR_ALL_STATISTIC } from "../ducks/nodeStatistics";

import { actions as NODE_CHANNELS_ACTIONS } from "../ducks/nodeChannels";
import { actions as NODE_ACTIONS } from "../ducks/node";
import { actions as LOADING_ACTIONS } from "../ducks/loadingData";

// eslint-disable-next-line import/no-cycle
import WebsocketService from "./websocket";

import { PlatformAPI } from "../lib/api";
import getMessageFromErrorCode from "../lib/utils/getMessageFromErrorCode";

const translations = defineMessages({
  updateSuccess: {
    id: "nodeService.updateSuccess",
    defaultMessage: "Channel was successfully updated",
  },
  addSuccess: {
    id: "nodeService.addSuccess",
    defaultMessage: "Channel was successfully added",
  },
  editNodeHostnameSuccess: {
    id: "nodeService.editNodeHostnameSuccess",
    defaultMessage: "Hostname for node was successfully updated",
  },
});

const actions = bindActionCreators(
  {
    connectFingerprintToChannel: (data, { errorNotification }) => async () => {
      try {
        await PlatformAPI.connectFingerprintToChannel(data, { errorNotification });
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
        throw new Error(`Error while connect fingerprint to cloud channel: ${error}`);
      }
    },
    connectFingerprintToPermission: (data, { errorNotification }) => async () => {
      try {
        await PlatformAPI.connectFingerprintToPermission(data, { errorNotification });
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
        throw new Error(`Error while connect fingerprint to cloud permission: ${error}`);
      }
    },
    updateChannelData: ({ oldCloudId, oldPermissionId }, { errorNotification, successNotification }) => async () => {
      try {
        if (oldCloudId) {
          PlatformAPI.removeFingerprintFromChannel({ oldCloudId }, { errorNotification });
        }
        if (oldPermissionId) {
          PlatformAPI.removeFingerprintFromPermission({ oldPermissionId }, { errorNotification });
        }
        successNotification(translations.updateSuccess);
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
      }
    },
    updateChannel: (data) => (dispatch) => {
      dispatch(NODE_CHANNELS_ACTIONS.CHANGE_NODES_CHANNELS_STATUS(data));
    },
    updateChannelsList: (data) => (dispatch) => {
      const connectedNodeCWID = store.getState().getIn(["node", "cwid"]);
      if (connectedNodeCWID === data.from) {
        dispatch(NODE_CHANNELS_ACTIONS.SET_NODES_CHANNELS(data?.list));
        dispatch(LOADING_ACTIONS.CLEAR_LOADING());
      }
    },
    setNetworkInterfaces: (data) => (dispatch) => {
      dispatch(NODE_ACTIONS.SET_NETWORKINTERFACES(data));
      dispatch(LOADING_ACTIONS.CLEAR_LOADING());
    },
    setNodeLicenseList: (data) => (dispatch) => {
      dispatch(NODE_ACTIONS.SET_NODE_LICENSE_LIST(data));
      dispatch(LOADING_ACTIONS.CLEAR_LOADING());
    },
    setStundAddress: (data) => (dispatch) => {
      dispatch(NODE_ACTIONS.SET_STUND_ADDRESS(data));
      dispatch(LOADING_ACTIONS.CLEAR_LOADING());
    },
    setFingerprint: (data) => (dispatch) => {
      dispatch(NODE_ACTIONS.SET_NODE_FINGERPRINT(data));
      dispatch(LOADING_ACTIONS.CLEAR_LOADING());
    },
    setShortFingerprint: (data) => (dispatch) => {
      dispatch(NODE_ACTIONS.SET_SHORT_FINGERPRINT(data));
      dispatch(LOADING_ACTIONS.CLEAR_LOADING());
    },
    updateChannelStatistic: ({ in: input, out: output, channelId, from }) => (dispatch) => {
      dispatch(SET_STATISTIC({ data: { input, output }, channelId, from }));
    },
    clearStatistic: ({ channelId, from }) => (dispatch) => {
      dispatch(CLEAR_STATISTIC({ channelId, from }));
    },
    loadMoreLogData: ({ logs, channelId }) => (dispatch) => {
      dispatch(LOAD_MORE_LOG_DATA({ logs, channelId }));
    },
    deleteLogs: () => (dispatch) => {
      dispatch(DELETE_LOGS());
    },
    clearAllStatistic: () => (dispatch) => {
      dispatch(CLEAR_ALL_STATISTIC());
    },
    newLogData: (data) => (dispatch) => {
      dispatch(NEW_LOG_DATA(data));
    },
    updateLogLevel: (data) => (dispatch) => {
      dispatch(SET_LOG_LEVEL({ ...data }));
    },
    deleteChannel: ({ cloudData }, { errorNotification }) => async () => {
      try {
        if (cloudData.hasPermissionId) {
          await PlatformAPI.removeFingerprintFromPermission(
            { oldPermissionId: cloudData.hasPermissionId },
            { errorNotification }
          );
        }
        if (cloudData.hasCloudId) {
          await PlatformAPI.removeFingerprintFromChannel({ oldCloudId: cloudData.hasCloudId }, { errorNotification });
        }
      } catch (error) {
        const errorMessage = getMessageFromErrorCode("platformConnectionError");
        errorNotification(errorMessage);
      }
    },
    editNodeHostname: (data, { errorNotification, successNotification }) => async () => {
      try {
        await PlatformAPI.editNodeHostname(data, { errorNotification });
        WebsocketService.sendMessage(
          JSON.stringify({ cloudMsgType: "sendMessage", command: "setHostname", hostname: data?.cnn, to: data?.cwid })
        );
        successNotification(translations.editNodeHostnameSuccess);
      } catch (error) {
        const errorMessage = getMessageFromErrorCode({ status: error.status, code: error.errorCode });
        errorNotification(errorMessage);
      }
    },
  },
  store.dispatch
);

export default actions;
