import { notification } from "antd";

const notificationGenFunction = ({ type, formatMessage }) => (message) => {
  notification[type]({
    message: formatMessage(message),
  });
};

export const successNotification = (formatMessage) => notificationGenFunction({ type: "success", formatMessage });
export const errorNotification = (formatMessage) => notificationGenFunction({ type: "error", formatMessage });
export const warningNotification = (formatMessage) => notificationGenFunction({ type: "warning", formatMessage });
export const infoNotification = (formatMessage) => notificationGenFunction({ type: "info", formatMessage });

export default { successNotification, errorNotification, warningNotification, infoNotification };
