import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Button, Divider, Col, Row, Card, Tooltip, Form, Spin, Skeleton, Affix } from "antd";
import { connect } from "react-redux";

import { selectors as CLOUD_CHANNELS_SELECTORS } from "../../ducks/cloudChannels";
import { selectors as ACCOUNT_SELECTORS } from "../../ducks/account";
import { actions as LOADING_ACTIONS, selectors as LOADING_SELECTOR } from "../../ducks/loadingData";
import { selectors as NODE_SELECTORS, actions as NODE_ACTIONS } from "../../ducks/node";
import { selectors as CLOUD_CONNECTION_SELECTORS } from "../../ducks/cloudConnection";
import { selectors as NODE_CHANNEL_SELECTORS } from "../../ducks/nodeChannels";

import CloudChannelsServices from "../../services/cloudChannels";
import WebsocketService from "../../services/websocket";

import store, { history } from "../../store";

import NodeService from "../../services/node";
import OutputStreamSection from "./OutputStreamSection";
import InputStreamSection from "./InputStreamSection";
import CloudDisableTooltip from "../../components/CloudDisableTooltip";
import LicenseField from "../../components/LicenseField";
import InputTextFiled from "../../components/Fields/InputTextField";
import ChannelName from "./ChannelName";

import { errorNotification, successNotification } from "../../lib/utils/notification";
import {
  AUTO_IP,
  QUICKSTREAM_METHOD,
  CONNECTION_METHOD,
  DEFAULT_VALUES,
  FORM_VERSION,
  WEBSOCKET_STATUS,
  DEFAULT_INPUT_TYPE,
} from "../../lib/utils/constants";

const messages = defineMessages({
  channelName: {
    id: "ChannelForm.channelName",
    defaultMessage: "Channel name",
  },
  channelNameRequired: {
    id: "ChannelForm.channelNameRequired",
    defaultMessage: "Please input channel name",
  },
  auto: {
    id: "general.auto",
    defaultMessage: "Automatic",
  },
});

const ChannelForm = withRouter((props) => {
  const {
    channelConfig = null,
    channels,
    editMode,
    fingerprint,
    loading,
    loggedToCloud,
    match,
    nodes,
    ownChannelList,
    sharedChannelList,
    shortFingerprint,
    stundAddress,
    websocketConnection,
    websocketStatus,
  } = props;

  const { id, cnn, cwid } = match.params;
  const [form] = Form.useForm();
  const { getFieldValue, setFieldsValue } = form;
  const { formatMessage } = useIntl();

  const [loadingCloud, setLoadingCloud] = useState(false);
  const [disableInputCondition, setDisableInputCondition] = useState(false);
  const [disableOutputCondition, setDisableOutputCondition] = useState(false);

  const isQuickstreamMethod =
    channelConfig?.nonMuxedOutputs[0]?.connectionType === QUICKSTREAM_METHOD.value ||
    channelConfig?.input?.connectionType === QUICKSTREAM_METHOD.value;

  const isConnection = websocketConnection && websocketStatus === WEBSOCKET_STATUS.connected && cwid;
  const nodeData = nodes.find((node) => node.cwid === cwid);
  const channelData = channels.find((channel) => channel.channelId === id);

  useEffect(() => {
    if (isConnection) {
      WebsocketService.sendMessage(
        JSON.stringify({ cloudMsgType: "sendMessage", command: "getShortFingerprint", to: cwid })
      );
      WebsocketService.sendMessage(
        JSON.stringify({ cloudMsgType: "sendMessage", command: "getFingerprint", to: cwid })
      );
      WebsocketService.sendMessage(JSON.stringify({ cloudMsgType: "sendMessage", command: "getStunAddr", to: cwid }));
      WebsocketService.sendMessage(JSON.stringify({ cloudMsgType: "sendMessage", command: "getHostname", to: cwid }));
      store.dispatch(LOADING_ACTIONS.SET_LOADING("getInitialDataChannelForm"));
    }
  }, [cwid, isConnection]);

  useEffect(() => {
    if (nodeData?.cwid && !channelData) {
      WebsocketService.sendMessage(
        JSON.stringify({ command: "getAllChannels", cloudMsgType: "sendMessage", to: nodeData?.cwid })
      );
      store.dispatch(LOADING_ACTIONS.SET_LOADING("getAllChannels"));
      store.dispatch(NODE_ACTIONS.SET_NODE_CONNECTION(nodeData));
    }
  }, [nodeData, channelData]);

  useEffect(() => {
    if (loggedToCloud) {
      CloudChannelsServices.getOwnChannels({ errorNotification: errorNotification(formatMessage) });
      CloudChannelsServices.getSharedChannels({ errorNotification: errorNotification(formatMessage) });
    }
  }, [loggedToCloud, formatMessage]);

  const onFinish = async (formData) => {
    const inputSwitchAutoIp = getFieldValue(["input", "switchAutoIp"]);
    const outputSwitchAutoIp = getFieldValue(["nonMuxedOutputs", 0, "switchAutoIp"]);

    const oldCloudId = channelConfig?.nonMuxedOutputs[0]?.cloudId;
    const oldPermissionId = channelConfig?.input?.permissionId;

    const inputSelectedChannelData = sharedChannelList.find(
      (channel) => +formData.input?.cloudId === +channel.permissionId
    );

    const outputSelectedChannelData = sharedChannelList.find(
      (channel) => +formData.nonMuxedOutputs[0]?.cloudId === +channel.permissionId
    );

    const parsedFormData = () => {
      const parsedQsMethodData = {
        ...formData,
        ...(inputSelectedChannelData?.cloudId
          ? {
              input: {
                ...formData.input,
                cloudId: inputSelectedChannelData.cloudId,
                type: DEFAULT_INPUT_TYPE,
              },
            }
          : {}),
        ...(outputSelectedChannelData?.cloudId
          ? {
              input: {
                ...formData.nonMuxedOutputs[0],
                cloudId: outputSelectedChannelData.cloudId,
              },
            }
          : {}),
      };

      const parsedAutoIpAddressData = {
        ...parsedQsMethodData,
        ...(inputSwitchAutoIp
          ? {
              input: {
                ...parsedQsMethodData.input,
                ownIp: AUTO_IP,
                type: DEFAULT_INPUT_TYPE,
              },
            }
          : {}),
        ...(outputSwitchAutoIp
          ? { nonMuxedOutputs: [{ ...parsedQsMethodData.nonMuxedOutputs[0], ownIp: AUTO_IP }] }
          : {}),
      };

      return parsedAutoIpAddressData;
    };

    if (editMode) {
      WebsocketService.sendMessage(
        JSON.stringify({
          cloudMsgType: "sendMessage",
          command: "updateChannel",
          channelId: id,
          channelConfig: JSON.stringify(parsedFormData()),
          to: cwid,
        })
      );
      store.dispatch(LOADING_ACTIONS.SET_LOADING("updateChannel"));
      await NodeService.updateChannelData(
        { oldCloudId, oldPermissionId },
        {
          errorNotification: errorNotification(formatMessage),
          successNotification: successNotification(formatMessage),
        }
      );
    } else {
      WebsocketService.sendMessage(
        JSON.stringify({
          cloudMsgType: "sendMessage",
          command: "addChannel",
          channelConfig: JSON.stringify(parsedFormData()),
          to: cwid,
        })
      );
      store.dispatch(LOADING_ACTIONS.SET_LOADING("addNodeChannel"));
    }

    const outputConnectionMethod = formData.nonMuxedOutputs[0]?.connectionType;
    const isOutputQuickstreamMethod = outputConnectionMethod === QUICKSTREAM_METHOD.value;
    const inputConnectionMethod = formData.input?.connectionType;
    const isInputQuickstreamMethod = inputConnectionMethod === QUICKSTREAM_METHOD.value;

    if (isOutputQuickstreamMethod && shortFingerprint) {
      const nodeChannelData = {
        cwid: shortFingerprint,
        cloudId: formData.nonMuxedOutputs[0].cloudId,
        oldCloudId,
      };

      await NodeService.connectFingerprintToChannel(nodeChannelData, {
        errorNotification: errorNotification(formatMessage),
      });

      await CloudChannelsServices.connectNodeToCloud(
        { cwid: shortFingerprint, fingerprint, cnn, users: [] },
        {
          errorNotification: errorNotification(formatMessage),
        },
        setLoadingCloud
      );
    }

    if (isInputQuickstreamMethod && shortFingerprint) {
      const nodeChannelData = {
        cwid: shortFingerprint,
        oldPermissionId,
        permissionId: formData?.input?.permissionId,
      };

      await NodeService.connectFingerprintToPermission(nodeChannelData, {
        errorNotification: errorNotification(formatMessage),
      });

      await CloudChannelsServices.connectNodeToCloud(
        { cwid: shortFingerprint, fingerprint, cnn, users: [] },
        {
          errorNotification: errorNotification(formatMessage),
        },
        setLoadingCloud
      );
    }

    history.push(`/cloudNodes/${cwid}/${cnn}/channels`);
  };

  const autoInterface = { label: formatMessage(messages.auto), value: "Automatic" };

  const setInitialOwnIp = (ownIp) => {
    if (ownIp === AUTO_IP || !ownIp) {
      return stundAddress;
    }

    return ownIp;
  };

  const parsedCloudId = (cloudId) => {
    if (cloudId) {
      const selectedChannelData = sharedChannelList.find((channel) => +cloudId === +channel.cloudId);

      return selectedChannelData?.permissionId;
    }

    return null;
  };

  const initialFormValues = {
    input: {
      ...channelConfig?.input,
      backup: {
        ...channelConfig?.input?.backup,
        triggerBitrateEnabled: channelConfig?.input?.backup?.triggerBitrateEnabled || DEFAULT_VALUES.TRIGGER_INIT,
        triggerBitrateLevel: channelConfig?.input?.backup?.triggerBitrateLevel || DEFAULT_VALUES.TRIGGER_BITRATE_LEVEL,
        triggerBitrateTimeSecs:
          channelConfig?.input?.backup?.triggerBitrateTimeSecs || DEFAULT_VALUES.TRIGGER_BITRATE_SECS,
        triggerConnectionTimeEnabled:
          channelConfig?.input?.backup?.triggerConnectionTimeEnabled || DEFAULT_VALUES.TRIGGER_INIT,
        triggerConnectionTimeSecs:
          channelConfig?.input?.backup?.triggerConnectionTimeSecs || DEFAULT_VALUES.TRIGGER_BITRATE_TIME_SECS,
        triggerDisconnectEnabled: channelConfig?.input?.backup?.triggerDisconnectEnabled || DEFAULT_VALUES.TRIGGER_INIT,
        backupConnection: {
          ...channelConfig?.input?.backup?.backupConnection,
          connectionType:
            channelConfig?.input?.backup?.backupConnection?.connectionType || CONNECTION_METHOD.udpUnicast.value,
          dstIp: channelConfig?.input?.backup?.backupConnection?.dstIp || DEFAULT_VALUES.IP,
          dstPort: channelConfig?.input?.backup?.backupConnection?.dstPort || DEFAULT_VALUES.PORT,
          ffmpegStreamProperties: {
            ...channelConfig?.input?.backup?.backupConnection?.ffmpegStreamProperties,
            oheadbw: channelConfig?.input?.backup?.backupConnection?.ffmpegStreamProperties?.oheadbw || 25,
            rcvlatency: channelConfig?.input?.backup?.backupConnection?.ffmpegStreamProperties?.rcvlatency || 500,
            passphrase: channelConfig?.input?.backup?.backupConnection?.ffmpegStreamProperties?.passphrase,
            pbkeylen: channelConfig?.input?.backup?.backupConnection?.ffmpegStreamProperties?.pbkeylen || 16,
          },
          interfaceIp: channelConfig?.input?.backup?.backupConnection?.interfaceIp || autoInterface.value,
          switchAutoIp:
            channelConfig?.input?.backup?.backupConnection?.ownIp === AUTO_IP ||
            !channelConfig?.input?.backup?.backupConnection?.ownIp,
          ownIp: setInitialOwnIp(channelConfig?.input?.backup?.backupConnection?.ownIp),
        },
      },
      cloudId: parsedCloudId(channelConfig?.input?.cloudId),
      connectionType: channelConfig?.input?.connectionType || CONNECTION_METHOD.udpUnicast.value,
      dstIp: channelConfig?.input?.dstIp || DEFAULT_VALUES.IP,
      dstPort: channelConfig?.input?.dstPort || DEFAULT_VALUES.PORT,
      ffmpegStreamProperties: {
        ...channelConfig?.input?.ffmpegStreamProperties,
        oheadbw: channelConfig?.input?.ffmpegStreamProperties?.oheadbw || 25,
        rcvlatency: channelConfig?.input?.ffmpegStreamProperties?.rcvlatency || 500,
        passphrase: channelConfig?.input?.ffmpegStreamProperties?.passphrase,
        pbkeylen: channelConfig?.input?.ffmpegStreamProperties?.pbkeylen || 16,
        // mode: channelConfig?.input?.ffmpegStreamProperties?.mode || SRT_MODES[0].value, /// ???
      },
      interfaceIp: channelConfig?.input?.interfaceIp || autoInterface.value,
      switchAutoIp: channelConfig?.input?.ownIp === AUTO_IP || !channelConfig?.input?.ownIp,
      ownIp: setInitialOwnIp(channelConfig?.input?.ownIp),
      type: DEFAULT_INPUT_TYPE,
    },
    licenseId: channelConfig?.licenseId,
    name: channelConfig?.name,
    nonMuxedOutputs: [
      {
        ...channelConfig?.nonMuxedOutputs[0],
        connectionType: channelConfig?.nonMuxedOutputs[0]?.connectionType || CONNECTION_METHOD.udpUnicast.value,
        dstIp: channelConfig?.nonMuxedOutputs[0]?.dstIp || DEFAULT_VALUES.IP,
        dstPort: channelConfig?.nonMuxedOutputs[0]?.dstPort || DEFAULT_VALUES.PORT,
        ffmpegStreamProperties: {
          ...channelConfig?.nonMuxedOutputs[0]?.ffmpegStreamProperties,
          oheadbw: channelConfig?.nonMuxedOutputs[0]?.ffmpegStreamProperties?.oheadbw || 25,
          rcvlatency: channelConfig?.input?.ffmpegStreamProperties?.rcvlatency || 500,
          peerlatency: channelConfig?.nonMuxedOutputs[0]?.ffmpegStreamProperties?.peerlatency || 0,
          passphrase: channelConfig?.nonMuxedOutputs[0]?.ffmpegStreamProperties?.passphrase,
          pbkeylen: channelConfig?.nonMuxedOutputs[0]?.ffmpegStreamProperties?.pbkeylen || 16,
          ttl: channelConfig?.nonMuxedOutputs[0]?.ffmpegStreamProperties?.ttl || DEFAULT_VALUES.TTL,
        },
        interfaceIp: channelConfig?.nonMuxedOutputs[0]?.interfaceIp || autoInterface.value,
        switchAutoIp: channelConfig?.nonMuxedOutputs[0]?.ownIp === AUTO_IP || !channelConfig?.nonMuxedOutputs[0]?.ownIp,
        ownIp: setInitialOwnIp(channelConfig?.nonMuxedOutputs[0]?.ownIp),
      },
    ],
    version: FORM_VERSION,
  };

  const isReady = editMode ? fingerprint && stundAddress && channelData : fingerprint && stundAddress;

  if (!isReady) {
    return (
      <Spin tip={<FormattedMessage id="general.loading" defaultMessage="Loading..." />}>
        <Skeleton active />
      </Spin>
    );
  }

  return (
    <Card title={<FormattedMessage id="ChannelForm.title" defaultMessage="Channel form" />}>
      <Form onFinish={onFinish} form={form} initialValues={initialFormValues}>
        <Row gutter={24}>
          <Col span={12}>
            <ChannelName name="name" label={formatMessage(messages.channelName)} channelId={id} />
          </Col>
          <Col span={12}>
            <LicenseField />
          </Col>
          <StyledHiddenCol span={4}>
            <InputTextFiled name="version" />
          </StyledHiddenCol>
        </Row>
        <Divider />
        <InputStreamSection
          channelList={sharedChannelList}
          getFieldValue={getFieldValue}
          initialValue={channelConfig?.input}
          loggedToCloud={!!loggedToCloud}
          setFieldsValue={setFieldsValue}
          setDisableCondition={setDisableInputCondition}
        />
        <Divider />
        <OutputStreamSection
          channelList={ownChannelList}
          getFieldValue={getFieldValue}
          initialValue={channelConfig?.nonMuxedOutputs[0]}
          loggedToCloud={!!loggedToCloud}
          setFieldsValue={setFieldsValue}
          setDisableCondition={setDisableOutputCondition}
        />
        <Affix offsetBottom={15}>
          <StyledRow>
            <StyledTooltip
              placement="top"
              title={
                isQuickstreamMethod && !loggedToCloud ? (
                  <CloudDisableTooltip
                    title={
                      <FormattedMessage
                        id="general.disableEditQSMethod"
                        defaultMessage="to Quickstream Cloud is needed to edit this channel"
                      />
                    }
                  />
                ) : null
              }
            >
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  (isQuickstreamMethod && !loggedToCloud) ||
                  loading ||
                  loadingCloud ||
                  disableInputCondition ||
                  disableOutputCondition
                }
              >
                {!editMode && <FormattedMessage id="ChannelForm.saveChannel" defaultMessage="Save channel" />}
                {editMode && <FormattedMessage id="ChannelForm.update" defaultMessage="Update channel" />}
              </Button>
            </StyledTooltip>
          </StyledRow>
        </Affix>
      </Form>
    </Card>
  );
});

const StyledRow = styled(Row)`
  margin-top: 15px;
`;

const StyledTooltip = styled(Tooltip)`
  width: 100%;
  button {
    width: 100%;
  }
`;

const StyledHiddenCol = styled(Col)`
  visibility: hidden;
  display: none;
`;

const mapStateToProps = (state) => ({
  channels: NODE_CHANNEL_SELECTORS.getChannels(state),
  fingerprint: NODE_SELECTORS.getFingerprint(state),
  loading: LOADING_SELECTOR.getLoading(state),
  loggedToCloud: ACCOUNT_SELECTORS.getUser(state),
  nodes: CLOUD_CONNECTION_SELECTORS.getNodes(state),
  ownChannelList: CLOUD_CHANNELS_SELECTORS.getOwnChannels(state),
  sharedChannelList: CLOUD_CHANNELS_SELECTORS.getSharedChannels(state),
  shortFingerprint: NODE_SELECTORS.getShortFingerprint(state),
  stundAddress: NODE_SELECTORS.getStundAddress(state),
  websocketConnection: CLOUD_CONNECTION_SELECTORS.getWebsocketConnection(state),
  websocketStatus: CLOUD_CONNECTION_SELECTORS.getStatus(state),
});

export default connect(mapStateToProps, null)(ChannelForm);
