import { defineMessages } from "react-intl";

export default defineMessages({
  // cloud connection/send message errors 0-100
  0: {
    id: "cloudError.0",
    defaultMessage: "Missing translations for this cloud error",
  },
  1: {
    id: "cloudError.1",
    defaultMessage: "Cloud connection error",
  },
  2: {
    id: "cloudError.2",
    defaultMessage: "Invalid authorization",
  },
  3: {
    id: "cloudError.3",
    defaultMessage: "Invalid websocket command sent",
  },
  4: {
    id: "cloudError.4",
    defaultMessage: "Receiver is not connected to cloud",
  },

  // cloud fetch data errors 101-200
  100: {
    id: "cloudError.100",
    defaultMessage: "There is no node connected to cloud for this user",
  },

  // cloud events
  cloudDisconnection: {
    id: "cloudEvent.cloudDisconnection",
    defaultMessage: "Cloud disconnected",
  },
  cloudConnected: {
    id: "cloudEvent.cloudConnected",
    defaultMessage: "Cloud connected",
  },
  nodeHostnameUpdated: {
    id: "cloudEvent.nodeHostnameUpdated",
    defaultMessage: "Node hostname was updated",
  },
});
