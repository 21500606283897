import React from "react";
import { defineMessages, useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip, Button } from "antd";

import { history } from "../../../../store";

import themeColor from "../../../../lib/style/theme";

const translations = defineMessages({
  labelTooltip: {
    id: "ConnectionMethodLabel.labelTooltip",
    defaultMessage: "to the cloud needed for quickstream method to be available",
  },
  label: {
    id: "ConnectionMethodLabel.label",
    defaultMessage: "Connection method",
  },
});

const ConnectionMethodLabel = (loggedToCloud, isBackup) => {
  const { formatMessage } = useIntl();

  return (
    <StyledLabel>
      <span>{formatMessage(translations.label)}</span>
      {!loggedToCloud && !isBackup && (
        <Tooltip
          placement="top"
          title={
            <>
              <StyledButton type="link" onClick={() => history.push("/guest/homepage")}>
                <FormattedMessage id="general.login" defaultMessage="Login" />
              </StyledButton>
              {formatMessage(translations.labelTooltip)}
            </>
          }
        >
          <QuestionCircleFilled />
        </Tooltip>
      )}
    </StyledLabel>
  );
};

const StyledButton = styled(Button)`
  color: ${themeColor.orange};
  padding-right: 4px;
  padding-left: 0px;
`;

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

export default ConnectionMethodLabel;
