import { QUICKSTREAM_METHOD, CONNECTION_METHOD } from "./constants";

const parseConnectionMethodName = (connection) => {
  switch (connection) {
    case QUICKSTREAM_METHOD.value:
      return QUICKSTREAM_METHOD.label;
    case CONNECTION_METHOD.udpMulticast.value:
      return CONNECTION_METHOD.udpMulticast.label;
    case CONNECTION_METHOD.udpUnicast.value:
      return CONNECTION_METHOD.udpUnicast.label;
    case CONNECTION_METHOD.srtCaller.value:
      return CONNECTION_METHOD.srtCaller.label;
    case CONNECTION_METHOD.srtListener.value:
      return CONNECTION_METHOD.srtListener.label;
    case CONNECTION_METHOD.srtRendezvous.value:
      return CONNECTION_METHOD.srtRendezvous.label;
    default:
      return connection.toUpperCase();
  }
};

export default parseConnectionMethodName;
