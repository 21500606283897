import { bindActionCreators } from "redux";
import { defineMessages } from "react-intl";
import store from "../store";

import { PlatformAPI } from "../lib/api";
import {
  SET_OWN_CHANNELS,
  SET_SHARED_CHANNELS,
  ADD_NEW_OWN_CHANNEL,
  REMOVE_OWN_CHANNEL,
  SET_ALL_CHANNELS,
  ADD_NEW_ALL_CHANNEL,
  REMOVE_SHARED_CHANNEL,
  REMOVE_ALL_CHANNEL,
  SET_CHANNEL_PERMISSIONS,
  UPDATE_OWN_CHANNEL,
} from "../ducks/cloudChannels";
import getMessageFromErrorCode from "../lib/utils/getMessageFromErrorCode";

import { SKIP_QS_METHOD_ERROR } from "../lib/utils/constants";

const translations = defineMessages({
  removeSuccess: {
    id: "cloudChannels.removeSuccess",
    defaultMessage: "Channel was successfully removed",
  },
  addSuccess: {
    id: "cloudChannels.addSuccess",
    defaultMessage: "Channel was successfully added",
  },
  shareAddSuccess: {
    id: "cloudChannels.shareAddSuccess",
    defaultMessage: "Shares were successfully added",
  },
  shareRemoveSuccess: {
    id: "cloudChannels.shareRemoveSuccess",
    defaultMessage: "Share was successfully removed",
  },
  updateSuccess: {
    id: "cloudChannels.updateSuccess",
    defaultMessage: "Channel was successfully updated",
  },
});

const actions = bindActionCreators(
  {
    createChannel: (data, { errorNotification, successNotification, setLoading }) => async (dispatch) => {
      try {
        const newChannelData = await PlatformAPI.createChannel(data, { errorNotification });
        dispatch(ADD_NEW_OWN_CHANNEL(newChannelData.data));
        dispatch(ADD_NEW_ALL_CHANNEL(newChannelData.data));
        successNotification(translations.addSuccess);
        setLoading(false);
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
      }
    },
    editCloudChannel: (data, { errorNotification, successNotification, setLoading }) => async (dispatch) => {
      try {
        await PlatformAPI.editCloudChannel(data, { errorNotification });
        dispatch(UPDATE_OWN_CHANNEL(data));
        successNotification(translations.updateSuccess);
        setLoading(false);
      } catch (error) {
        const errorMessage = getMessageFromErrorCode({ status: error.status, code: error.errorCode });
        errorNotification(errorMessage);
      }
    },
    removeChannel: (cloudId, { errorNotification, successNotification }) => async (dispatch) => {
      try {
        await PlatformAPI.removeChannel(cloudId, { errorNotification });
        dispatch(REMOVE_OWN_CHANNEL(cloudId));
        dispatch(REMOVE_ALL_CHANNEL(cloudId));
        dispatch(REMOVE_SHARED_CHANNEL(cloudId));
        successNotification(translations.removeSuccess);
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
      }
    },
    getAllChannels: ({ errorNotification }) => async (dispatch) => {
      try {
        const allChannels = await PlatformAPI.getAllChannelsList({ errorNotification });
        dispatch(SET_ALL_CHANNELS(allChannels));
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
      }
    },
    getOwnChannels: ({ errorNotification }) => async (dispatch) => {
      try {
        const ownChannelsList = await PlatformAPI.getOwnChannelsList({ errorNotification });
        dispatch(SET_OWN_CHANNELS(ownChannelsList));
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
      }
    },
    getSharedChannels: ({ errorNotification }) => async (dispatch) => {
      try {
        const sharedChannelsList = await PlatformAPI.getSharedChannelsList({ errorNotification });
        dispatch(SET_SHARED_CHANNELS(sharedChannelsList));
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
      }
    },
    getChannelPermissions: (id, { errorNotification }) => async () => {
      try {
        const channelPermissions = await PlatformAPI.getChannelPermissions(id, { errorNotification });

        return channelPermissions;
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
      }
    },
    getChannelPermissionsDescription: ({ cloudId, channelId }, { errorNotification }) => async (dispatch) => {
      try {
        const channelPermissionsDescription = await PlatformAPI.getChannelPermissionsDescription(cloudId, {
          errorNotification,
        });
        dispatch(SET_CHANNEL_PERMISSIONS({ [channelId]: channelPermissionsDescription }));

        return channelPermissionsDescription;
      } catch (error) {
        if (+error.status === SKIP_QS_METHOD_ERROR) {
          return null;
        }
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
      }
    },
    removeChannelPermission: (permissionId, { errorNotification, successNotification }) => async () => {
      try {
        await PlatformAPI.removeChannelPermission(permissionId, { errorNotification });
        successNotification(translations.shareRemoveSuccess);

        return true;
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
      }
    },
    addChannelPermission: (data, { errorNotification, successNotification, setLoading }) => async () => {
      try {
        await PlatformAPI.addChannelPermission(data, { errorNotification });
        successNotification(translations.shareAddSuccess);
        setLoading(false);
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
      }
    },
    invitationsPermission: (data, { errorNotification, setLoadingInvitations }) => async () => {
      try {
        await PlatformAPI.invitationsPermission(data, { errorNotification });
        setLoadingInvitations(false);
      } catch (error) {
        const errorMessage = getMessageFromErrorCode(error.status);
        errorNotification(errorMessage);
      }
    },
    connectNodeToCloud: (data, { errorNotification }, setLoading) => async () => {
      try {
        setLoading(true);
        await PlatformAPI.addNewCWID(data, { errorNotification });
        setLoading(false);
      } catch (error) {
        const errorMessage = getMessageFromErrorCode({ status: error.status, code: error.errorCode });
        errorNotification(errorMessage);
        setLoading(false);
      }
    },
  },
  store.dispatch
);

export default actions;
