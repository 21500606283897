import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Table, Tag, Button, Tooltip, Card, Popconfirm } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import { DeleteOutlined, QuestionCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import store from "../../../store";
import WebsocketManager from "../../../services/websocket";
import { selectors as NODE_SELECTORS } from "../../../ducks/node";
import { actions as LOADING_ACTIONS } from "../../../ducks/loadingData";
import { selectors as CLOUD_CONNECTION_SELECTORS } from "../../../ducks/cloudConnection";

import parseJwt from "../../../lib/utils/parseJwt";
import themeColor from "../../../lib/style/theme";
import { LOADING_DATA_TYPE, PERMANENT_LICENSE, WEBSOCKET_STATUS } from "../../../lib/utils/constants";

import NodeLicenseListCardTitle from "./NodeLicenseListCardTitle";

const setLicenseTagColor = (licenseType) => {
  switch (licenseType) {
    case "professional":
      return "geekblue";
    case "enterprise":
      return "cyan";
    default:
      return "gold";
  }
};

const NodeLicenseList = ({
  connection,
  licenseList,
  fingerprint,
  match: {
    params: { cwid },
  },
  websocketConnection,
  websocketStatus,
}) => {
  const isConnection = websocketConnection && websocketStatus === WEBSOCKET_STATUS.connected;

  useEffect(() => {
    if (isConnection) {
      WebsocketManager.sendMessage(
        JSON.stringify({ cloudMsgType: "sendMessage", to: cwid, command: "getFingerprint" })
      );
      WebsocketManager.sendMessage(JSON.stringify({ cloudMsgType: "sendMessage", to: cwid, command: "getLicenses" }));
      store.dispatch(LOADING_ACTIONS.SET_LOADING(LOADING_DATA_TYPE.wrapperLicenseList));
    }
  }, [connection, isConnection, cwid]);

  const nodeLicensesList = licenseList[cwid] || [];

  const parsedNodeLicenseList = nodeLicensesList.map((license) => {
    return { ...parseJwt(license.licenseToken), id: license.id, channelsUsed: license.channelsUsed };
  });

  const removeLicense = useCallback(
    (licenseId) => {
      WebsocketManager.sendMessage(
        JSON.stringify({ cloudMsgType: "sendMessage", to: cwid, command: "deleteLicense", licenseId })
      );
    },
    [cwid]
  );

  const setClassName = (record) => {
    if (record.licenseExpire === PERMANENT_LICENSE) {
      return "null";
    }

    if (moment().isAfter(record.licenseExpire)) {
      return "licenseExpired";
    }

    return "";
  };

  const columns = [
    {
      title: <FormattedMessage id="LicenseList.licenseId" defaultMessage="License ID" />,
      dataIndex: "licenseId",
      key: "licenseId",
      sorter: (currentValue, nextValue) => currentValue.licenseId.localeCompare(nextValue.licenseId),
    },
    {
      title: <FormattedMessage id="LicenseList.username" defaultMessage="Username" />,
      dataIndex: "username",
      key: "username",
      sorter: (currentValue, nextValue) => currentValue.username.localeCompare(nextValue.username),
    },
    {
      title: <FormattedMessage id="LicenseList.licenseType" defaultMessage="License type" />,
      dataIndex: "licenseType",
      key: "licenseType",
      sorter: (currentValue, nextValue) => currentValue.licenseType.localeCompare(nextValue.licenseType),
      render: (licenseType, record) => (
        <Tag color={setLicenseTagColor(licenseType)} key={record.id}>
          {licenseType && licenseType.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: <FormattedMessage id="LicenseList.channels" defaultMessage="Channels" />,
      dataIndex: "engines",
      key: "engines",
      sorter: (currentValue, nextValue) => currentValue.engines - nextValue.engines,
      render: (engines, record) => {
        return (
          <>
            <Tooltip title={<FormattedMessage id="AccountsList.channelsInUse" defaultMessage="Channels used" />}>
              {record.channelsUsed}
            </Tooltip>
            /
            <Tooltip title={<FormattedMessage id="AccountsList.allChannels" defaultMessage="All channels" />}>
              {engines}
            </Tooltip>
          </>
        );
      },
    },
    {
      title: <FormattedMessage id="LicenseList.expiration" defaultMessage="Expiration" />,
      dataIndex: "licenseExpire",
      key: "licenseExpire",
      sorter: (currentValue, nextValue) => currentValue.licenseExpire.localeCompare(nextValue.licenseExpire),
      render: (licenseExpire) => {
        if (licenseExpire === PERMANENT_LICENSE) {
          return <FormattedMessage id="general.never" defaultMessage="Never" />;
        }

        if (moment().isAfter(licenseExpire)) {
          return (
            <span>
              {licenseExpire}{" "}
              <Tooltip title={<FormattedMessage id="AccountsList.licenseExpired" defaultMessage="License expired" />}>
                <ExclamationCircleOutlined style={{ color: `${themeColor.darkRed}` }} />
              </Tooltip>
            </span>
          );
        }

        return <span>{licenseExpire}</span>;
      },
    },
    {
      key: "actions",
      width: 100,
      title: <FormattedMessage id="general.action" defaultMessage="Action" />,
      render: (text, { licenseId }) => (
        <Popconfirm
          title={
            <FormattedMessage
              id="AccountsList.confirmRemove"
              defaultMessage="Are you sure, you want to remove this license?"
            />
          }
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          onConfirm={() => removeLicense(licenseId)}
          okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
          cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
        >
          <Button type="danger" icon={<DeleteOutlined />}>
            <span>
              <FormattedMessage id="general.remove" defaultMessage="Remove" />
            </span>
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Card title={<NodeLicenseListCardTitle fingerprint={fingerprint} cwid={cwid} />}>
      <StyledTable
        columns={columns}
        rowClassName={setClassName}
        dataSource={parsedNodeLicenseList}
        pagination={false}
        bordered
        role="table"
        rowKey={(record) => record.licenseId}
      />
      {/* <Divider />
      <Affix offsetBottom={15}>
        <StyledButton type="primary" onClick={handleAddLicense}>
          <FormattedMessage id="NodeLicenseList.addLicense" defaultMessage="Add license" />
        </StyledButton>
      </Affix> */}
    </Card>
  );
};

NodeLicenseList.propTypes = {
  connection: PropTypes.string,
  fingerprint: PropTypes.string.isRequired,
  licenseList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.exact({
        channels: PropTypes.number.isRequired,
        channelsUsed: PropTypes.number.isRequired,
        licenseId: PropTypes.string.isRequired,
        licenseTimeExpiring: PropTypes.bool.isRequired,
        licenseToken: PropTypes.string.isRequired,
        licenseType: PropTypes.string.isRequired,
      }),
      PropTypes.array
    ),
  ]).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ cwid: PropTypes.string.isRequired }),
  }).isRequired,
  websocketConnection: PropTypes.bool,
  websocketStatus: PropTypes.string,
};

NodeLicenseList.defaultProps = {
  connection: null,
  websocketStatus: null,
  websocketConnection: null,
};

// const StyledButton = styled(Button)`
//   display: block;
//   width: 100%;
//   justify-content: center;
// `;

const StyledTable = styled(Table)`
  .licenseExpired {
    background-color: ${themeColor.logCritical};
  }
`;

const mapStateToProps = (state) => ({
  licenseList: NODE_SELECTORS.getNodeLicenses(state),
  fingerprint: NODE_SELECTORS.getFingerprint(state),
  connection: NODE_SELECTORS.getNodeCwid(state),
  websocketStatus: CLOUD_CONNECTION_SELECTORS.getStatus(state),
  websocketConnection: CLOUD_CONNECTION_SELECTORS.getWebsocketConnection(state),
});

export default connect(mapStateToProps, null)(NodeLicenseList);
