import React from "react";
import { Row, Button, Popconfirm } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { QuestionCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import WebsocketManager from "../../services/websocket";

import { successNotification } from "../../lib/utils/notification";

const translations = defineMessages({
  sendRestartMessageSuccess: {
    id: "NodeChannelTitle.sendRestartMessageSuccess",
    defaultMessage: "Channel restart message has been sent",
  },
});

const NodeChannelTitle = ({ channelId, nodeData }) => {
  const { formatMessage } = useIntl();

  const handleRestart = () => {
    WebsocketManager.sendMessage(
      JSON.stringify({ command: "killChannel", channelId, cloudMsgType: "sendMessage", to: nodeData?.cwid })
    );
    successNotification(formatMessage)(translations.sendRestartMessageSuccess);
  };

  return (
    <Row justify="space-between">
      <FormattedMessage id="NodeChannelTitle.title" defaultMessage="Channel details" />
      <Popconfirm
        title={
          <FormattedMessage
            id="NodeChannelTitle.confirmRestart"
            defaultMessage="Are you sure, you want to restart this channel"
          />
        }
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        onConfirm={handleRestart}
        okText={<FormattedMessage id="general.yes" defaultMessage="Yes" />}
        cancelText={<FormattedMessage id="general.no" defaultMessage="No" />}
      >
        <Button type="danger">
          <FormattedMessage id="NodeChannelTitle.killEngine" defaultMessage="Restart channel" />
        </Button>
      </Popconfirm>
    </Row>
  );
};

NodeChannelTitle.propTypes = {
  channelId: PropTypes.string.isRequired,
  nodeData: PropTypes.shape({
    cnn: PropTypes.string.isRequired,
    cwid: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default NodeChannelTitle;
