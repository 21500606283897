import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { KeyOutlined } from "@ant-design/icons";
import { Input, Button, Select, InputNumber, Radio, Form } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import debounce from "debounce-promise";

import { selectors as ADMIN_SELECTORS } from "../../../../ducks/admin";
import LicenseServices from "../../../../services/license";
import AdminService from "../../../../services/admin";

import { errorNotification, successNotification } from "../../../../lib/utils/notification";

const { Item } = Form;
const { Option } = Select;
const { Button: RadioButton, Group } = Radio;

const translations = defineMessages({
  fingerprint: {
    id: "NewLicenseForm.fingerprint",
    defaultMessage: "Hardware ID",
  },
  expiryDaysPlaceholder: {
    id: "NewLicenseForm.expiryDaysPlaceholder",
    defaultMessage: "How many days license should be valid",
  },
  enginesNumberPlaceholder: {
    id: "NewLicenseForm.enginesNumberPlaceholder",
    defaultMessage: "How many channels license can use",
  },
  maintenanceNumberPlaceholder: {
    id: "NewLicenseForm.maintenanceNumberPlaceholder",
    defaultMessage: "How many days of maintenance",
  },
  required: {
    id: "NewLicenseForm.required",
    defaultMessage: "Required field",
  },
  licenseId: {
    id: "NewLicenseForm.licenseId",
    defaultMessage: "Name of the license",
  },
  uniqLicense: {
    id: "NewLicenseForm.uniqLicense",
    defaultMessage: "This license ID is already taken",
  },
  regexError: {
    id: "NewLicenseForm.regexError",
    defaultMessage: "Please use only (a-z, -, 0-9,) characters ",
  },
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
    md: { span: 20 },
  },
};

const NewLicenseForm = (props) => {
  const { accountList } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    AdminService.getAccountList({ errorNotification });
  }, []);

  const [loading, setLoading] = useState(false);
  const [licenseTimeExpiring, setLicenseTimeExpiring] = useState(0);

  const { resetFields } = form;

  const { formatMessage } = useIntl();
  const [username, setUserName] = useState(null);

  const handleFinish = useCallback(
    async (data) => {
      setLoading(true);
      const { licenseId } = data;
      const prefixedData = { ...data, licenseId: `${username}-${licenseId}` };
      await LicenseServices.createNewLicense(prefixedData, {
        errorNotification: errorNotification(formatMessage),
        successNotification: successNotification(formatMessage),
      });
      setLoading(false);
    },
    [formatMessage, username]
  );

  const validateIsUnique = debounce(async (rule, value) => {
    const prefixedLicenseId = `${username}-${value}`;

    const regex = /^[a-zA-Z0-9]$|^[a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9]+$/g;
    const isCorrect = value.match(regex);

    if (isCorrect) {
      const { uniqLicense } = await LicenseServices.uniqLicense(prefixedLicenseId, {
        errorNotification: errorNotification(formatMessage),
      });

      if (!uniqLicense) {
        return Promise.reject(formatMessage(translations.uniqLicense));
      }
    }

    return Promise.resolve();
  }, 500);

  const handleOnChange = (value) => {
    setUserName(value);
    resetFields(["licenseId"]);
  };

  const handleChangeTimeLimited = (value) => {
    setLicenseTimeExpiring(!!+value);
  };

  return (
    <Form form={form} {...formItemLayout} onFinish={handleFinish} initialValues={{ licenseTimeExpiring }}>
      <Item
        name="username"
        label={<FormattedMessage id="NewLicenseForm.username" defaultMessage="License for user" />}
        rules={[{ required: true, message: formatMessage(translations.required) }]}
      >
        <Select onChange={handleOnChange}>
          {accountList.map((account) => (
            <Option value={account.username} key={account.id}>
              {account.username}
            </Option>
          ))}
        </Select>
      </Item>
      <Item
        name="fingerprint"
        label={<FormattedMessage id="NewLicenseForm.fingerprintLable" defaultMessage="Hardware ID" />}
        rules={[{ required: true, message: formatMessage(translations.required) }]}
      >
        <Input
          prefix={<KeyOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder={formatMessage(translations.fingerprint)}
        />
      </Item>
      <Item
        name="licenseId"
        label={<FormattedMessage id="NewLicenseForm.licenseIdLabel" defaultMessage="License ID" />}
        rules={[
          { required: true, message: formatMessage(translations.required) },
          {
            pattern: new RegExp("^[a-zA-Z0-9]$|^[a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9]+$"),
            message: formatMessage(translations.regexError),
          },
          { validator: validateIsUnique },
        ]}
      >
        <Input
          disabled={!username}
          addonBefore={username ? `${username}-` : ""}
          placeholder={formatMessage(translations.licenseId)}
        />
      </Item>
      <Item
        name="licenseType"
        label={<FormattedMessage id="NewLicenseForm.licenseType" defaultMessage="License type" />}
        rules={[{ required: true, message: formatMessage(translations.required) }]}
      >
        <Select>
          <Option value="standard">
            <FormattedMessage id="NewLicenseForm.basic" defaultMessage="Standard" />
          </Option>
          <Option value="professional">
            <FormattedMessage id="NewLicenseForm.pro" defaultMessage="Professional" />
          </Option>
          <Option value="enterprise">
            <FormattedMessage id="NewLicenseForm.enterprise" defaultMessage="Enterprise" />
          </Option>
        </Select>
      </Item>
      <Item
        name="licenseTimeExpiring"
        label={<FormattedMessage id="NewLicenseForm.timeExpiring" defaultMessage="License time expiring" />}
        rules={[{ required: true, message: formatMessage(translations.required) }]}
        onChange={(event) => handleChangeTimeLimited(event.target.value)}
      >
        <Group>
          <RadioButton value={0}>
            <FormattedMessage id="NewLicenseForm.permanent" defaultMessage="Permanent" />
          </RadioButton>
          <RadioButton value={1}>
            <FormattedMessage id="NewLicenseForm.timeLimited" defaultMessage="Time-limited" />
          </RadioButton>
        </Group>
      </Item>
      {!!licenseTimeExpiring && (
        <Item
          name="expiryDays"
          label={<FormattedMessage id="NewLicenseForm.expiryDays" defaultMessage="Expiry days" />}
          rules={[{ required: true, message: formatMessage(translations.required) }]}
        >
          <StyledInputNumber min={1} placeholder={formatMessage(translations.expiryDaysPlaceholder)} />
        </Item>
      )}
      <Item
        name="engines"
        label={<FormattedMessage id="NewLicenseForm.engines" defaultMessage="Channels" />}
        rules={[{ required: true, message: formatMessage(translations.required) }]}
      >
        <StyledInputNumber min={1} placeholder={formatMessage(translations.enginesNumberPlaceholder)} />
      </Item>
      <Item
        name="maintenance"
        label={<FormattedMessage id="NewLicenseForm.maintenance" defaultMessage="Maintenance" />}
        rules={[{ required: true, message: formatMessage(translations.required) }]}
      >
        <StyledInputNumber min={1} placeholder={formatMessage(translations.maintenanceNumberPlaceholder)} />
      </Item>
      <StyledButton type="primary" htmlType="submit" loading={loading} disabled={loading}>
        <span>
          <FormattedMessage id="NewLicenseForm.submit" defaultMessage="Create new license" />
        </span>
      </StyledButton>
    </Form>
  );
};

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  justify-content: center;
`;

const StyledInputNumber = styled(InputNumber)`
  &.ant-input-number {
    width: 100%;
  }
`;

NewLicenseForm.propTypes = {
  accountList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
};

const mapStateToProps = (state) => ({
  accountList: ADMIN_SELECTORS.getAccountList(state),
});

export default connect(mapStateToProps, null)(NewLicenseForm);
