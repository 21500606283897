import React, { useEffect } from "react";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Radio, Card, Tooltip, Spin, Skeleton } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import store from "../../../../store";

import { selectors as LOG_SELECTORS } from "../../../../ducks/logs";
import { selectors as LOADING_SELECTORS, actions as LOADING_ACTIONS } from "../../../../ducks/loadingData";
import { selectors as CONNECTION_SELECTORS } from "../../../../ducks/node";
import { selectors as CLOUD_CONNECTION_SELECTORS } from "../../../../ducks/cloudConnection";

import WebsocketManager from "../../../../services/websocket";
import themeColor from "../../../../lib/style/theme";
import { LOADING_DATA_TYPE, WEBSOCKET_STATUS } from "../../../../lib/utils/constants";

const { Button: RadioButton, Group } = Radio;

const LogLevel = ({ connectionStatus, logLevel, loading, loadingDataType, cwid, websocketConnection }) => {
  useEffect(() => {
    const isConnection = websocketConnection && connectionStatus === WEBSOCKET_STATUS.connected;

    if (isConnection) {
      WebsocketManager.sendMessage(JSON.stringify({ cloudMsgType: "sendMessage", command: "getLogLevel", to: cwid }));
      store.dispatch(LOADING_ACTIONS.SET_LOADING(LOADING_DATA_TYPE.logLevel));
    }
  }, [cwid, connectionStatus, websocketConnection]);

  const onChange = (event) => {
    const selectedLogLevel = event.target.value;
    WebsocketManager.sendMessage(
      JSON.stringify({ cloudMsgType: "sendMessage", command: "setLogLevel", to: cwid, level: selectedLogLevel })
    );
  };

  const isLoadingLogLevel = loading && loadingDataType === LOADING_DATA_TYPE.logLevel;

  if (isLoadingLogLevel) {
    return (
      <Spin tip={<FormattedMessage id="LogLevel.loadingLogLevel" defaultMessage="Loading log level" />}>
        <Skeleton active />
      </Spin>
    );
  }

  const LogLevelLabelTooltip = (
    <>
      <div>
        <FormattedMessage
          id="LogLevelLabel.label"
          // eslint-disable-next-line max-len
          defaultMessage="Select the log level. If you select for example “WARNING” only WARNING. ERROR, CRITICAL logs will be recorded. Find out more about logging here:"
        />
      </div>{" "}
      <StyledLink
        href="https://help.quickstream.tech/portal/kb/articles/logging"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://help.quickstream.tech/portal/kb/articles/logging
      </StyledLink>
    </>
  );

  const LogLevelLabel = (
    <StyledLabel>
      <span>
        <FormattedMessage id="LogLevelLabel.logLevel" defaultMessage="Log level" />
      </span>
      <Tooltip placement="top" title={LogLevelLabelTooltip}>
        <QuestionCircleFilled />
      </Tooltip>
    </StyledLabel>
  );

  return (
    <Card title={LogLevelLabel}>
      <Group onChange={onChange} value={logLevel}>
        <RadioButton value={0}>
          <FormattedMessage id="LogLevel.critical" defaultMessage="CRITICAL" />
        </RadioButton>
        <RadioButton value={1}>
          <FormattedMessage id="LogLevel.error" defaultMessage="ERROR" />
        </RadioButton>
        <RadioButton value={2}>
          <FormattedMessage id="LogLevel.warning" defaultMessage="WARNING" />
        </RadioButton>
        <RadioButton value={3}>
          <FormattedMessage id="LogLevel.info" defaultMessage="INFO" />
        </RadioButton>
        <RadioButton value={4}>
          <FormattedMessage id="LogLevel.debug" defaultMessage="DEBUG" />
        </RadioButton>
      </Group>
    </Card>
  );
};

const StyledLabel = styled.span`
  span {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

const StyledLink = styled.a`
  color: ${themeColor.yellowLink};

  &:hover {
    color: ${themeColor.yellowLinkHover};
  }
`;

const mapStateToProps = (state) => ({
  cwid: CONNECTION_SELECTORS.getNodeCwid(state),
  logLevel: LOG_SELECTORS.getLogLevel(state),
  loading: LOADING_SELECTORS.getLoading(state),
  loadingDataType: LOADING_SELECTORS.getLoadingDataType(state),
  connectionStatus: CLOUD_CONNECTION_SELECTORS.getStatus(state),
  websocketConnection: CLOUD_CONNECTION_SELECTORS.getWebsocketConnection(state),
});

LogLevel.propTypes = {
  cwid: PropTypes.string,
  connectionStatus: PropTypes.string,
  logLevel: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  loadingDataType: PropTypes.string.isRequired,
  websocketConnection: PropTypes.bool,
};

LogLevel.defaultProps = {
  cwid: null,
  connectionStatus: null,
  logLevel: null,
  websocketConnection: null,
};

export default connect(mapStateToProps, null)(LogLevel);
